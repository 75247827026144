import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';
import isNumber from 'lodash/isNumber';

const routePolylineOptions = {
    strokeOpacity: 1.0,
    strokeWeight: 4,
};

const routeShadowPolylineOptions = {
    strokeColor: StyleGuideColorsEnum.white,
    strokeOpacity: 1.0,
    strokeWeight: 6,
};

export enum MapRouteThemeEnum {
    assignmentRoute = 'assignment-route',
    assignmentAccent = 'assignment-accent',
    assignmentAttention = 'assignment-attention',
    assignmentWarning = 'assignment-warning',
    assignmentPositive = 'assignment-positive',
    assignmentSelected = 'assignment-selected',
    trackFuture = 'track-future',
    trackPast = 'track-past',
    trackCurrent = 'track-current',
    orderCreation = 'order-creation',
    laneCreation = 'lane-creation',
    editOrderOld = 'edit-order-old',
    editOrderNew = 'edit-order-new',
}

const COLOR_MAP: Record<MapRouteThemeEnum, StyleGuideColorsEnum> = {
    [MapRouteThemeEnum.assignmentRoute]: StyleGuideColorsEnum.gray,
    [MapRouteThemeEnum.assignmentAccent]: StyleGuideColorsEnum.charcoal,
    [MapRouteThemeEnum.assignmentAttention]: StyleGuideColorsEnum.orange,
    [MapRouteThemeEnum.assignmentWarning]: StyleGuideColorsEnum.tomatoRed,
    [MapRouteThemeEnum.assignmentPositive]: StyleGuideColorsEnum.brandAccent,
    [MapRouteThemeEnum.assignmentSelected]: StyleGuideColorsEnum.charcoal,
    [MapRouteThemeEnum.trackCurrent]: StyleGuideColorsEnum.brandAccent,
    [MapRouteThemeEnum.trackPast]: StyleGuideColorsEnum.brandAccent,
    [MapRouteThemeEnum.trackFuture]: StyleGuideColorsEnum.gray,
    [MapRouteThemeEnum.orderCreation]: StyleGuideColorsEnum.brandDark,
    [MapRouteThemeEnum.laneCreation]: StyleGuideColorsEnum.charcoal,
    [MapRouteThemeEnum.editOrderOld]: StyleGuideColorsEnum.gray,
    [MapRouteThemeEnum.editOrderNew]: StyleGuideColorsEnum.orange,
};

const HOVER_COLOR_MAP: Record<MapRouteThemeEnum, StyleGuideColorsEnum> = {
    ...COLOR_MAP,
};

type PropsT = {
    map: MapT | null | undefined;
    maps: MapsT | null | undefined;
    theme: MapRouteThemeEnum;

    geometryLibrary?: GeometryLibraryT | null | undefined;

    polylines?: Array<GooglePolylineT> | null | undefined;
    route?: Array<GeoPointT> | null | undefined;

    zIndex?: number;
};

const MapRoute: React.FC<PropsT> = React.memo((props) => {
    const { map, maps, theme, polylines, route, geometryLibrary, zIndex } = props;

    const routePolylinesRef = React.useRef<Array<google.maps.Polyline>>([]);

    const [isHover, setHover] = React.useState<boolean>(false);

    const strokeColor = isHover ? HOVER_COLOR_MAP[theme] : COLOR_MAP[theme];

    React.useEffect(() => {
        if (!maps || !map) {
            return;
        }

        // reset
        routePolylinesRef.current.forEach((routePolyline) => {
            routePolyline?.setMap(null);
        });

        routePolylinesRef.current = [];

        const zIndexOptions = isNumber(zIndex)
            ? {
                  zIndex,
              }
            : {};

        // draw route
        const routePath: Array<GeoPointT> | Array<LatLngT> | null = route || null;
        if (routePath) {
            const routeShadowPolyline = new maps.Polyline({
                path: routePath,
                geodesic: true,
                ...zIndexOptions,
                ...routeShadowPolylineOptions,
            });
            routeShadowPolyline.setMap(map);

            routePolylinesRef.current.push(routeShadowPolyline);

            // fix google maps bug, old polyline delete all polylines
            const routePolyline = new maps.Polyline({
                path: routePath,
                geodesic: true,
                ...zIndexOptions,
                strokeColor,
                ...routePolylineOptions,
            });
            routePolyline.setMap(map);
            routePolyline?.addListener('mouseover', () => {
                setHover(true);
            });
            routePolyline?.addListener('mouseout', () => {
                setHover(false);
            });

            routePolylinesRef.current.push(routePolyline);
        }

        // draw polylines
        polylines?.forEach((polyline) => {
            const decodedPath = geometryLibrary?.encoding?.decodePath(polyline) || [];

            const routeShadowPolyline = new maps.Polyline({
                path: decodedPath,
                geodesic: true,
                ...zIndexOptions,
                ...routeShadowPolylineOptions,
            });
            routeShadowPolyline.setMap(map);

            routePolylinesRef.current.push(routeShadowPolyline);

            // fix google maps bug, old polyline delete all polylines
            const routePolyline = new maps.Polyline({
                path: decodedPath,
                geodesic: true,
                ...zIndexOptions,
                strokeColor,
                ...routePolylineOptions,
            });
            routePolyline.setMap(map);
            routePolyline?.addListener('mouseover', () => {
                setHover(true);
            });
            routePolyline?.addListener('mouseout', () => {
                setHover(false);
            });

            routePolylinesRef.current.push(routePolyline);
        });
    }, [map, maps, route, polylines, strokeColor, geometryLibrary]);

    React.useEffect(() => {
        return () => {
            routePolylinesRef.current.forEach((routePolyline) => {
                routePolyline?.setMap(null);
            });

            routePolylinesRef.current = [];
        };
    }, []);

    return null;
});

export default MapRoute;
