import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchVehicleSchedule,
    fetchVehicleScheduleBegin,
    fetchVehicleScheduleError,
    fetchVehicleScheduleSuccess,
    setUnavailableVehicleRequestBegin,
    setUnavailableVehicleRequestError,
    setUnavailableVehicleRequestSuccess,
} from './actions';
import {
    FETCH_VEHICLE_SCHEDULE_REQUEST,
    FetchVehicleScheduleActionT,
    SetUnavailableVehicleRequestActionT,
    SET_UNAVAILABLE_VEHICLE_REQUEST,
} from './types';
import { CompanyTypeEnum } from 'common/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';

import { checkIsDefaultCompanyId } from 'common/utils';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { selectTrucksDetailsByIds } from 'common/store/trucks/selectors';
import { selectTrailersDetailsByIds } from '../trailers/selectors';
import { selectFetchScheduleRequest } from 'common/store/vehicle-schedules/selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { VEHICLE_SCHEDULE_TTL } from 'common/store/vehicle-schedules/constants';
import { trailerDetailsRefreshChannel, trailersRefreshChannel } from 'common/store/trailers/channels';
import { truckDetailsRefreshChannel, trucksRefreshChannel } from 'common/store/trucks/channels';

function getFetchVehicleScheduleSaga(companyType: CompanyTypeEnum) {
    return function* fetchVehicleScheduleSaga(action: FetchVehicleScheduleActionT): WrapGeneratorT<void> {
        const { vehicleId, options } = action;

        const fetchScheduleRequest: ReReturnT<typeof selectFetchScheduleRequest> = yield select(
            selectFetchScheduleRequest(vehicleId),
        );
        if (!checkNeedRequest(fetchScheduleRequest, options, VEHICLE_SCHEDULE_TTL)) {
            return;
        }

        yield put(fetchVehicleScheduleBegin(vehicleId));

        let result: ReturnApiT<
            typeof brokerTranziitApi.fetchVehicleSchedule | typeof carrierTranziitApi.fetchVehicleSchedule
        >;
        if (companyType === CompanyTypeEnum.broker) {
            result = yield brokerTranziitApi.fetchVehicleSchedule(vehicleId);
        } else {
            result = yield carrierTranziitApi.fetchVehicleSchedule(vehicleId);
        }

        const [error, schedule] = result;

        if (error) {
            yield put(fetchVehicleScheduleError(vehicleId, error));
            return;
        }

        yield put(fetchVehicleScheduleSuccess(vehicleId, schedule || []));
    };
}

function* setUnavailableVehiclesSaga(action: SetUnavailableVehicleRequestActionT): WrapGeneratorT<void> {
    const { query, companyId } = action;

    yield put(setUnavailableVehicleRequestBegin());

    let result: ReturnApiT<
        typeof brokerTranziitApi.addVehicleScheduleEvent | typeof carrierTranziitApi.addVehicleScheduleEvent
    > | null = null;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.addVehicleScheduleEvent(query);
    } else {
        result = yield brokerTranziitApi.addVehicleScheduleEvent(query);
    }

    const error = result?.[0];

    if (error) {
        yield put(setUnavailableVehicleRequestError(error));
    } else {
        yield put(setUnavailableVehicleRequestSuccess());
    }

    if (!error && query.truckId) {
        const detailsByIds: ReReturnT<typeof selectTrucksDetailsByIds> = yield select(
            selectTrucksDetailsByIds(companyId),
        );
        const updateTruck = detailsByIds[query.truckId];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.truckSuccessfullySetUnavailable,
                    data: {
                        fromDate: query.truckDateFrom || '',
                        toDate: query.truckDateTo || '',
                        plateNumber: updateTruck?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (query.truckId) {
        yield put(fetchVehicleSchedule(query.truckId, { isForceUpdate: true }));
    }

    if (!error && query.trailerId) {
        const detailsByIds: ReReturnT<typeof selectTrailersDetailsByIds> = yield select(
            selectTrailersDetailsByIds(companyId),
        );
        const updateTrailer = detailsByIds[query.trailerId];

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.trailerSuccessfullySetUnavailable,
                    data: {
                        fromDate: query.trailerDateFrom || '',
                        toDate: query.trailerDateTo || '',
                        plateNumber: updateTrailer?.plateNumber || '',
                    },
                }),
            ),
        );
    }

    if (query.trailerId) {
        yield put(fetchVehicleSchedule(query.trailerId, { isForceUpdate: true }));
    }

    trailersRefreshChannel.emit({});
    trailerDetailsRefreshChannel.emit({});
    trucksRefreshChannel.emit({});
    truckDetailsRefreshChannel.emit({});
}

function* vehicleSchedulesSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(FETCH_VEHICLE_SCHEDULE_REQUEST, getFetchVehicleScheduleSaga(companyType));
    yield takeEvery(SET_UNAVAILABLE_VEHICLE_REQUEST, setUnavailableVehiclesSaga);
}

export default vehicleSchedulesSaga;
