import { FieldsEnum, FormValuesT, UpdateWayEnum } from './constants';
import { AssetDataT } from '../models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { ApiChangeContractVehiclesRequestT, ApiChangeRateVehiclesRequestT } from 'common/utils/api/models';
import { isNonNil } from 'common/utils';
import isNumber from 'lodash/isNumber';

export const prepareChangeRateRequest = (
    assets: Array<AssetDataT>,
    values: FormValuesT,
): ApiChangeRateVehiclesRequestT | null => {
    const assetIds = assets.map((asset) => asset?.id).filter(isNonNil) || [];

    const rate = parseSimpleNumber(values[FieldsEnum.rate]);
    if (!isNumber(rate)) {
        return null;
    }

    return {
        ids: assetIds,
        rate,
    };
};

export const prepareChangeContractRequest = (
    assets: Array<AssetDataT>,
    values: FormValuesT,
): ApiChangeContractVehiclesRequestT | null => {
    const assetIds = assets.map((asset) => asset?.id).filter(isNonNil) || [];

    if (values[FieldsEnum.updateWay] === UpdateWayEnum.unassignContract) {
        return {
            ids: assetIds,
            contractId: null,
        };
    }

    return {
        ids: assetIds,
        contractId: values[FieldsEnum.contractId],
    };
};
