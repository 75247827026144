import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './RemoteFormActions.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import noop from 'lodash/noop';

type PropsT = {
    className?: string;
    saveLabel?: React.ReactNode;
    cancelLabel?: React.ReactNode;
    closeLabel?: React.ReactNode;
    onClose?: () => void;
};

const cx = classNames.bind(styles);

const RemoteFormActions: React.FC<PropsT> = React.memo((props) => {
    const { className, saveLabel, cancelLabel, closeLabel, onClose } = props;

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const { t } = useTranslation();

    if (!remoteFormActionsContext?.remoteFormState?.hasChanges) {
        if (onClose) {
            return (
                <Button className={cx('action')} testSelector="form-cancel" onClick={onClose || noop}>
                    {closeLabel || t('common:form-actions.cancel')}
                </Button>
            );
        }

        return null;
    }

    const isLoading = remoteFormActionsContext?.remoteFormRequest?.isLoading;

    return (
        <div className={cs(cx('actions'), className)}>
            <Button
                className={cx('action')}
                isLoading={isLoading}
                testSelector="form-submit"
                theme={ButtonThemeEnum.primary}
                onClick={remoteFormActionsContext?.remoteFormCallbacks?.submit}
            >
                {saveLabel || t('common:form-actions.save-changes')}
            </Button>
            <Button
                className={cx('action')}
                testSelector="form-reset"
                onClick={isLoading ? noop : remoteFormActionsContext?.remoteFormCallbacks?.reset}
            >
                {cancelLabel || t('common:form-actions.reset-changes')}
            </Button>
        </div>
    );
});

export default RemoteFormActions;
