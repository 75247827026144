import {
    ACTIVATION_TRUCKS_REQUEST,
    ActivationTrucksRequestActionT,
    ADD_TRUCK_REQUEST,
    ADD_TRUCK_REQUEST_BEGIN,
    ADD_TRUCK_REQUEST_ERROR,
    ADD_TRUCK_REQUEST_SUCCESS,
    AddTruckRequestActionT,
    AddTruckRequestBeginActionT,
    AddTruckRequestErrorActionT,
    AddTruckRequestSuccessActionT,
    ASSIGN_TO_CONTRACT_TRUCKS_REQUEST,
    ArchiveTrucksRequestActionT,
    DELETE_TRUCKS_REQUEST,
    DELETE_TRUCKS_REQUEST_BEGIN,
    DELETE_TRUCKS_REQUEST_ERROR,
    DELETE_TRUCKS_REQUEST_SUCCESS,
    DeleteTrucksRequestActionT,
    DeleteTrucksRequestBeginActionT,
    DeleteTrucksRequestErrorActionT,
    DeleteTrucksRequestSuccessActionT,
    FETCH_TRUCK_DETAILS_REQUEST,
    FETCH_TRUCK_DETAILS_REQUEST_BEGIN,
    FETCH_TRUCK_DETAILS_REQUEST_ERROR,
    FETCH_TRUCK_DETAILS_REQUEST_SUCCESS,
    FETCH_TRUCKS_PAGE_REQUEST,
    FETCH_TRUCKS_PAGE_REQUEST_BEGIN,
    FETCH_TRUCKS_PAGE_REQUEST_ERROR,
    FETCH_TRUCKS_PAGE_REQUEST_SUCCESS,
    FetchTruckDetailsActionT,
    FetchTruckDetailsBeginActionT,
    FetchTruckDetailsErrorActionT,
    FetchTruckDetailsSuccessActionT,
    FetchTrucksPageActionT,
    FetchTrucksPageBeginActionT,
    FetchTrucksPageErrorActionT,
    FetchTrucksPageSuccessActionT,
    RESET_TRUCKS,
    ResetTrucksActionT,
    UPDATE_TRUCK_REQUEST,
    UPDATE_TRUCKS_REQUEST_BEGIN,
    UPDATE_TRUCKS_REQUEST_ERROR,
    UPDATE_TRUCKS_REQUEST_SUCCESS,
    UpdateTruckActionT,
    UpdateTrucksRequestBeginActionT,
    UpdateTrucksRequestErrorActionT,
    UpdateTrucksRequestSuccessActionT,
    ARCHIVE_TRUCKS_REQUEST,
    FetchTrucksStatsActionT,
    FETCH_TRUCKS_STATS_REQUEST,
    FetchTrucksStatsBeginActionT,
    FETCH_TRUCKS_STATS_REQUEST_BEGIN,
    FetchTrucksStatsErrorActionT,
    FETCH_TRUCKS_STATS_REQUEST_ERROR,
    FetchTrucksStatsSuccessActionT,
    FETCH_TRUCKS_STATS_REQUEST_SUCCESS,
    ImportXLSTrucksActionT,
    IMPORT_XLS_TRUCKS_REQUEST,
    ImportXLSTrucksBeginActionT,
    IMPORT_XLS_TRUCKS_REQUEST_BEGIN,
    IMPORT_XLS_TRUCKS_REQUEST_ERROR,
    IMPORT_XLS_TRUCKS_REQUEST_SUCCESS,
    EXPORT_XLS_TRUCKS_REQUEST,
    EXPORT_XLS_TRUCKS_REQUEST_BEGIN,
    EXPORT_XLS_TRUCKS_REQUEST_ERROR,
    EXPORT_XLS_TRUCKS_REQUEST_SUCCESS,
    ExportXLSTrucksSuccessActionT,
    ExportXLSTrucksErrorActionT,
    ExportXLSTrucksBeginActionT,
    ExportXLSTrucksActionT,
    ImportXLSTrucksSuccessActionT,
    ImportXLSTrucksErrorActionT,
    CHANGE_RATE_TRUCKS_REQUEST,
    ChangeRateTrucksRequestActionT,
} from './types';
import {
    CompanyTrucksStatsT,
    FetchTrucksPageQueryT,
    ApiTruckDetailsT,
    ApiTruckT,
    ActivateTrucksRequestT,
} from './models';

import {
    ApiChangeContractVehiclesRequestT,
    ApiChangeRateVehiclesRequestT,
    ApiTruckAddRequestT,
    ApiTruckUpdateRequestT,
} from 'common/utils/api/models';
import { AssignToContractRequestActionT } from 'common/store/trucks/types';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchTrucksPage = (
    pageNumber: PageNumberT,
    query: FetchTrucksPageQueryT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTrucksPageActionT => {
    return {
        type: FETCH_TRUCKS_PAGE_REQUEST,
        query,
        pageNumber,
        options,
        companyId,
    };
};

export const fetchTrucksPageBegin = (
    query: FetchTrucksPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
): FetchTrucksPageBeginActionT => ({
    type: FETCH_TRUCKS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
    companyId,
});

export const fetchTrucksPageSuccess = (
    query: FetchTrucksPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<ApiTruckT> | null,
    companyId: CompanyIdT,
): FetchTrucksPageSuccessActionT => ({
    type: FETCH_TRUCKS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
    companyId,
});

export const fetchTrucksPageError = (
    query: FetchTrucksPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
    companyId: CompanyIdT,
): FetchTrucksPageErrorActionT => ({
    type: FETCH_TRUCKS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
    companyId,
});

export const resetTrucks = (companyId: CompanyIdT, mutationListOptions: MutationListOptionsT): ResetTrucksActionT => ({
    type: RESET_TRUCKS,
    companyId,
    mutationListOptions,
});

export const addTruckRequest = (data: ApiTruckAddRequestT, companyId: CompanyIdT): AddTruckRequestActionT => ({
    type: ADD_TRUCK_REQUEST,
    data,
    companyId,
});

export const addTruckRequestBegin = (companyId: CompanyIdT): AddTruckRequestBeginActionT => ({
    type: ADD_TRUCK_REQUEST_BEGIN,
    companyId,
});

export const addTruckRequestError = (error: Error, companyId: CompanyIdT): AddTruckRequestErrorActionT => ({
    type: ADD_TRUCK_REQUEST_ERROR,
    error,
    companyId,
});

export const addTruckRequestSuccess = (companyId: CompanyIdT): AddTruckRequestSuccessActionT => ({
    type: ADD_TRUCK_REQUEST_SUCCESS,
    companyId,
});

export const deleteTrucksRequest = (truckIds: TruckIdT[], companyId: CompanyIdT): DeleteTrucksRequestActionT => ({
    type: DELETE_TRUCKS_REQUEST,
    truckIds,
    companyId,
});

export const deleteTrucksRequestBegin = (companyId: CompanyIdT): DeleteTrucksRequestBeginActionT => ({
    type: DELETE_TRUCKS_REQUEST_BEGIN,
    companyId,
});

export const deleteTrucksRequestError = (error: Error, companyId: CompanyIdT): DeleteTrucksRequestErrorActionT => ({
    type: DELETE_TRUCKS_REQUEST_ERROR,
    error,
    companyId,
});

export const deleteTrucksRequestSuccess = (companyId: CompanyIdT): DeleteTrucksRequestSuccessActionT => ({
    type: DELETE_TRUCKS_REQUEST_SUCCESS,
    companyId,
});

export const activationTrucksRequest = (
    companyId: CompanyIdT,
    activateTrucksRequest: ActivateTrucksRequestT,
): ActivationTrucksRequestActionT => ({
    type: ACTIVATION_TRUCKS_REQUEST,
    companyId,
    activateTrucksRequest,
});

export const updateTruck = (
    truckId: TruckIdT,
    updateTruck: ApiTruckUpdateRequestT,
    companyId: CompanyIdT,
): UpdateTruckActionT => ({
    type: UPDATE_TRUCK_REQUEST,
    truckId,
    updateTruck,
    companyId,
});

export const archiveTrucksRequest = (
    truckIds: TruckIdT[],
    companyId: CompanyIdT,
    reason: string,
): ArchiveTrucksRequestActionT => ({
    type: ARCHIVE_TRUCKS_REQUEST,
    truckIds,
    companyId,
    reason,
});

export const assignToContractRequest = ({
    companyId,
    changeContractRequest,
    contractName,
}: {
    companyId: CompanyIdT;
    changeContractRequest: ApiChangeContractVehiclesRequestT;
    contractName: string | null;
}): AssignToContractRequestActionT => ({
    type: ASSIGN_TO_CONTRACT_TRUCKS_REQUEST,
    companyId,
    changeContractRequest,
    contractName,
});

export const changeRateTrucksRequest = ({
    changeRateVehiclesRequest,
    companyId,
}: {
    companyId: CompanyIdT;
    changeRateVehiclesRequest: ApiChangeRateVehiclesRequestT;
}): ChangeRateTrucksRequestActionT => ({
    type: CHANGE_RATE_TRUCKS_REQUEST,
    changeRateVehiclesRequest,
    companyId,
});

export const updateTrucksRequestBegin = (companyId: CompanyIdT): UpdateTrucksRequestBeginActionT => ({
    type: UPDATE_TRUCKS_REQUEST_BEGIN,
    companyId,
});

export const updateTrucksRequestError = (error: Error, companyId: CompanyIdT): UpdateTrucksRequestErrorActionT => ({
    type: UPDATE_TRUCKS_REQUEST_ERROR,
    error,
    companyId,
});

export const updateTrucksRequestSuccess = (companyId: CompanyIdT): UpdateTrucksRequestSuccessActionT => ({
    type: UPDATE_TRUCKS_REQUEST_SUCCESS,
    companyId,
});

export const fetchTruckDetails = (
    truckId: TruckIdT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTruckDetailsActionT => ({
    type: FETCH_TRUCK_DETAILS_REQUEST,
    truckId,
    companyId,
    options,
});

export const fetchTruckDetailsRequestBegin = (companyId: CompanyIdT): FetchTruckDetailsBeginActionT => ({
    type: FETCH_TRUCK_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const fetchTruckDetailsRequestError = (error: Error, companyId: CompanyIdT): FetchTruckDetailsErrorActionT => ({
    type: FETCH_TRUCK_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchTruckDetailsRequestSuccess = (
    truckId: TruckIdT,
    details: ApiTruckDetailsT | null,
    companyId: CompanyIdT,
): FetchTruckDetailsSuccessActionT => ({
    type: FETCH_TRUCK_DETAILS_REQUEST_SUCCESS,
    truckId,
    details,
    companyId,
});

export const fetchTrucksStats = (companyId: CompanyIdT, options?: FetchOptionsT): FetchTrucksStatsActionT => ({
    type: FETCH_TRUCKS_STATS_REQUEST,
    companyId,
    options,
});

export const fetchTrucksStatsRequestBegin = (companyId: CompanyIdT): FetchTrucksStatsBeginActionT => ({
    type: FETCH_TRUCKS_STATS_REQUEST_BEGIN,
    companyId,
});

export const fetchTrucksStatsRequestError = (error: Error, companyId: CompanyIdT): FetchTrucksStatsErrorActionT => ({
    type: FETCH_TRUCKS_STATS_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchTrucksStatsRequestSuccess = (
    stats: CompanyTrucksStatsT | null,
    companyId: CompanyIdT,
): FetchTrucksStatsSuccessActionT => ({
    type: FETCH_TRUCKS_STATS_REQUEST_SUCCESS,
    stats,
    companyId,
});

export const importXLSTrucks = (companyId: CompanyIdT, file: File): ImportXLSTrucksActionT => ({
    type: IMPORT_XLS_TRUCKS_REQUEST,
    companyId,
    file,
});

export const importXLSTrucksRequestBegin = (companyId: CompanyIdT): ImportXLSTrucksBeginActionT => ({
    type: IMPORT_XLS_TRUCKS_REQUEST_BEGIN,
    companyId,
});

export const importXLSTrucksRequestError = (error: Error, companyId: CompanyIdT): ImportXLSTrucksErrorActionT => ({
    type: IMPORT_XLS_TRUCKS_REQUEST_ERROR,
    error,
    companyId,
});

export const importXLSTrucksRequestSuccess = (companyId: CompanyIdT): ImportXLSTrucksSuccessActionT => ({
    type: IMPORT_XLS_TRUCKS_REQUEST_SUCCESS,
    companyId,
});

export const exportXLSTrucks = (companyId: CompanyIdT, query: FetchTrucksPageQueryT): ExportXLSTrucksActionT => ({
    type: EXPORT_XLS_TRUCKS_REQUEST,
    companyId,
    query,
});

export const exportXLSTrucksRequestBegin = (companyId: CompanyIdT): ExportXLSTrucksBeginActionT => ({
    type: EXPORT_XLS_TRUCKS_REQUEST_BEGIN,
    companyId,
});

export const exportXLSTrucksRequestError = (error: Error, companyId: CompanyIdT): ExportXLSTrucksErrorActionT => ({
    type: EXPORT_XLS_TRUCKS_REQUEST_ERROR,
    error,
    companyId,
});

export const exportXLSTrucksRequestSuccess = (companyId: CompanyIdT): ExportXLSTrucksSuccessActionT => ({
    type: EXPORT_XLS_TRUCKS_REQUEST_SUCCESS,
    companyId,
});
