import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssetOptionLabel.scss';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DetailsIcon from 'common/icons/DetailsIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';

const cx = classNames.bind(styles);

type PropsT = {
    model?: string;
    plateNumber?: string;
    onOpenDetails?: () => void;
};

const AssetOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const { model, plateNumber, onOpenDetails } = props;

    return (
        <div className={cx('option')}>
            <div>
                {plateNumber && <span className={cx('plate-number')}>{plateNumber}</span>}
                {' / '}
                {model && <span className={cx('model')}>{model}</span>}
            </div>
            {onOpenDetails && (
                <ClickInterceptorLabel className={cx('details-trigger')}>
                    <TransparentTrigger
                        spaces="xs"
                        onClick={onOpenDetails}
                        leftIcon={<DetailsIcon strokeColor={StyleGuideColorsEnum.gray} />}
                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                    />
                </ClickInterceptorLabel>
            )}
        </div>
    );
});

export default AssetOptionLabel;
