import * as React from 'react';

import { NotificationToastRendererT } from 'common/components/toasts/NotificationToastsManager/models';
import {
    NotificationLinkFactoryT,
    NotificationRendererT,
} from 'common/components/notifications/NotificationsBarContent/models';
import {
    NotificationActionEnum,
    NotificationEmotionEnum,
    NotificationIconEnum,
} from 'common/store/notifications/models';
import {
    ALERT_ICON_LOAD_STATUS_MAP,
    ALERT_ICON_ORDER_STATUS_MAP,
    ALERT_ICON_TOUR_STATUS_MAP,
    ALERT_ICON_TRANSPORTATION_STATUS_MAP,
    EMOTION_LOAD_STATUS_MAP,
    EMOTION_ORDER_STATUS_MAP,
    EMOTION_TOUR_STATUS_MAP,
    EMOTION_TRANSPORTATION_STATUS_MAP,
} from 'common/components/toasts/utils';
import StatusChangedCard from 'common/components/notification-cards/StatusChangedCard/StatusChangedCard';
import { SubjectTypeEnum } from 'common/utils/api/models';
import ShipmentColoredStatus from 'common/components/status-colored/ShipmentColoredStatus/ShipmentColoredStatus';
import OrderColoredStatus from 'common/components/status-colored/OrderColoredStatus/OrderColoredStatus';
import TourColoredStatus from 'common/components/status-colored/TourColoredStatus/TourColoredStatus';
import TransportOrderColoredStatus from 'common/components/status-colored/TransportOrderColoredStatus/TransportOrderColoredStatus';
import { urlFactory } from 'carrier/utils/urls';
import { AlertToastRendererT } from 'common/components/toasts/AlertToastsManager/models';
import { AnyAlert } from 'common/store/alerts/models';
import { CarrierAnyAlert } from './models';
import SpotRequestStatusCard from 'common/components/notification-cards/SpotRequestStatusCard/SpotRequestStatusCard';
import SpotBidStatusCard from 'common/components/notification-cards/SpotBidStatusCard/SpotBidStatusCard';
import ExpiredDocumentCard from 'common/components/notification-cards/ExpiredDocumentCard/ExpiredDocumentCard';
import ExpireSoonDocumentCard from 'common/components/notification-cards/ExpireSoonDocumentCard/ExpireSoonDocumentCard';
import RevokedDocumentCard from 'common/components/notification-cards/RevokedDocumentCard/RevokedDocumentCard';
import UpdatedDocumentCard from 'common/components/notification-cards/UpdatedDocumentCard/UpdatedDocumentCard';
import ExpiredContractCard from 'common/components/notification-cards/ExpiredContractCard/ExpiredContractCard';
import ExpireSoonContractCard from 'common/components/notification-cards/ExpireSoonContractCard/ExpireSoonContractCard';
import UpdatedContractCard from 'common/components/notification-cards/UpdatedContractCard/UpdatedContractCard';
import RevokedContractCard from 'common/components/notification-cards/RevokedContractCard/RevokedContractCard';
import RunningOutOfTimeAssignAssetsCard from 'common/components/notification-cards/RunningOutOfTimeAssignAssetsCard/RunningOutOfTimeAssignAssetsCard';
import RunOutOfTimeAssignAssetsCard from 'common/components/notification-cards/RunOutOfTimeAssignAssetsCard/RunOutOfTimeAssignAssetsCard';
import RunOutOfTimeAssignDriversCard from 'common/components/notification-cards/RunOutOfTimeAssignDriversCard/RunOutOfTimeAssignDriversCard';
import RunningOutOfTimeAssignDriversCard from 'common/components/notification-cards/RunningOutOfTimeAssignDriversCard/RunningOutOfTimeAssignDriversCard';
import CompletedDocumentCard from 'common/components/notification-cards/CompletedDocumentCard/CompletedDocumentCard';
import CompletedContractCard from 'common/components/notification-cards/CompletedContractCard/CompletedContractCard';
import TransportationOrderMileagAndPaymentChangedCard from '../notification-cards/TransportationOrderMileagAndPaymentChangedCard/TransportationOrderMileagAndPaymentChangedCard';

export const renderNotificationToast: NotificationToastRendererT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_LOAD_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={data.transportationOrderNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.shipment}
                        actionStatus={<ShipmentColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_ORDER_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.order}
                        actionStatus={<OrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.tour}
                        actionStatus={<TourColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunningOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunningOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TRANSPORTATION_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.transportationOrder}
                        actionStatus={<TransportOrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: (
                    <TransportationOrderMileagAndPaymentChangedCard
                        number={data.transportationOrderNumber || ''}
                        cityA={data.cityFrom}
                        cityB={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_PLACED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.new,
                text: (
                    <SpotRequestStatusCard
                        type="new"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: (
                    <SpotBidStatusCard
                        type="expired"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_CANCELLED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.fail,
                text: (
                    <SpotRequestStatusCard
                        type="cancelled"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_ASSIGNED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: (
                    <SpotRequestStatusCard
                        type="won"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_MISSED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.fail,
                text: (
                    <SpotBidStatusCard
                        type="missed"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <RevokedDocumentCard documentType={data.documentType} />,
            };
        }

        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <RevokedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedContractCard contractName={data.contractName || ''} />,
            };
        }

        default: {
            return null;
        }
    }
};

export const renderNotification: NotificationRendererT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_LOAD_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={data.transportationOrderNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.shipment}
                        actionStatus={<ShipmentColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_ORDER_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.order}
                        actionStatus={<OrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.tour}
                        actionStatus={<TourColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunningOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunningOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TRANSPORTATION_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.transportationOrder}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.transportationOrder}
                        actionStatus={<TransportOrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <TransportationOrderMileagAndPaymentChangedCard
                        number={data.transportationOrderNumber || ''}
                        cityA={data.cityFrom}
                        cityB={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_PLACED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.neutral,
                text: (
                    <SpotRequestStatusCard
                        type="new"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <SpotBidStatusCard
                        type="expired"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_CANCELLED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <SpotRequestStatusCard
                        type="cancelled"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_ASSIGNED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: (
                    <SpotRequestStatusCard
                        type="won"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_MISSED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <SpotBidStatusCard
                        type="missed"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <RevokedDocumentCard documentType={data.documentType} />,
            };
        }

        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <RevokedContractCard contractName={data.contractName || ''} />,
            };
        }

        default: {
            return null;
        }
    }
};

export const getNotificationLink: NotificationLinkFactoryT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER:
        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS:
        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS:
        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER:
        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED:
        case NotificationActionEnum.ORDER_STATUS_CHANGED:
        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            // @ts-expect-error wait backend, update all notifications
            return urlFactory.transportOrdersDetails(notification.data.transportationOrderId || 'null');
        }
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            return urlFactory.transportOrdersDetails(notification.data.transportationOrderId || 'null');
        }
        case NotificationActionEnum.SPOT_BID_MISSED:
        case NotificationActionEnum.SPOT_ASSIGNED:
        case NotificationActionEnum.SPOT_CANCELLED:
        case NotificationActionEnum.SPOT_BID_EXPIRED:
        case NotificationActionEnum.SPOT_PLACED: {
            return urlFactory.spotRequestDetails(notification.data.spotId || 'null');
        }
        case NotificationActionEnum.DOCUMENT_UPLOAD:
        case NotificationActionEnum.DOCUMENT_EXPIRED:
        case NotificationActionEnum.DOCUMENT_REVOKED:
        case NotificationActionEnum.DOCUMENT_UPDATED:
        case NotificationActionEnum.DOCUMENT_COMPLETED:
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            return urlFactory.documentDetails(notification.data.documentType);
        }
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRED:
        case NotificationActionEnum.CARRIER_CONTRACT_REVOKED:
        case NotificationActionEnum.CARRIER_CONTRACT_UPDATED:
        case NotificationActionEnum.CARRIER_CONTRACT_COMPLETED:
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON: {
            return urlFactory.carrierContractDetails(notification.subjectId || 'null');
        }
        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            return urlFactory.transportOrdersDetails(notification.data.transportationOrderId || 'null');
        }
        default: {
            return null;
        }
    }
};

const checkIsCarrierAnyAlert = (anyAlert: AnyAlert | null | undefined): anyAlert is CarrierAnyAlert => {
    return anyAlert instanceof CarrierAnyAlert;
};

export const renderAlertToast: AlertToastRendererT = (t, anyAlert) => {
    if (!checkIsCarrierAnyAlert(anyAlert)) {
        return null;
    }

    // TODO
    return null;
};
