import React from 'react';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';

type PropsT = {
    count: number | null | undefined;
};

const DropdownMultipleInputCountLabel: React.FC<PropsT> = React.memo((props) => {
    const { count } = props;

    const { t } = useTranslation();

    if (!isNumber(count)) {
        return null;
    }

    return (
        <span>
            {count}{' '}
            {t('common:common-components.dropdown-multiple-input.selected', {
                postProcess: 'interval',
                count,
            })}
        </span>
    );
});

export default DropdownMultipleInputCountLabel;
