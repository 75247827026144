import React from 'react';
import classNames from 'classnames/bind';

import styles from './TrailerDetails.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import {
    AssetTypeEnum,
    CurrencyEnum,
    DEFAULT_ICON_SIZE,
    StyleGuideColorsEnum,
    TrailerTypeEnum,
} from 'common/constants';
import FieldValue from 'common/components/FieldValue/FieldValue';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import TrailerIcon from 'common/icons/TrailerIcon';
import { ApiTrailerDetailsT } from 'common/store/trailers/models';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import Money from 'common/components/Money/Money';
import LastGPSPositionAlert from 'common/components/LastGPSPositionAlert/LastGPSPositionAlert';
import { fetchCarrierContracts } from 'common/store/carrier-contracts/actions';
import {
    selectCarrierContractById,
    selectFetchCarrierContractsRequest,
} from 'common/store/carrier-contracts/selectors';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TrailerType from 'common/layouts/CommonEditableTrailerDetailsLayout/TrailerType/TrailerType';
import LinkedTruckAlert from 'common/layouts/CommonEditableTrailerDetailsLayout/LinkedTruckAlert/LinkedTruckAlert';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DetailsIcon from 'common/icons/DetailsIcon';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { TrailerStatusEnum } from 'common/utils/api/models';
import ArchiveReasonAlert from 'common/components/ArchiveReasonAlert/ArchiveReasonAlert';
import { AlertGroup } from 'common/layouts/AlertGroup/AlertGroup';
import TrailerActionHistory from '../../TrailerActionHistory/TrailerActionHistory';
import { checkIsApiCarrierTrailerDetails } from 'common/store/trailers/type-guards';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    partnerId: PartnerIdT;
    isLoading: boolean;
    trailerDetails: ApiTrailerDetailsT | null;
    onOpenTruckDetails: (truckId: TruckIdT) => void;
    onOpenContactDetails: (contractId: CarrierContractIdT) => void;
    onOpenUserDetails: (userId: UserIdT, isBrokerUser?: boolean) => void;
};

const TrailerDetails: React.FC<PropsT> = (props) => {
    const { partnerId, trailerDetails, isLoading, onOpenTruckDetails, onOpenContactDetails, onOpenUserDetails } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);
    const trailersDictById = useSelector(selectTrailersDictById) || {};

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
        dispatch(fetchTrailersDict());
    }, []);

    const trailerDictModel = trailerDetails?.dictTrailerInfo?.id
        ? trailersDictById[trailerDetails?.dictTrailerInfo?.id]
        : null;

    const trailerTypeLabel = trailerDictModel
        ? t(`common:trailers-page.add-trailer.fields.trailer.${trailerDictModel?.trailerType}.label`)
        : '';

    React.useEffect(() => {
        dispatch(fetchCarrierContracts(partnerId));
    }, [partnerId]);

    const fetchContractsRequests = useSelector(selectFetchCarrierContractsRequest(partnerId));
    const contractById = useSelector(selectCarrierContractById(partnerId));
    const contract = contractById[trailerDetails?.contractId as string] || null;

    const contractRatePerKm =
        trailerDictModel?.trailerType === TrailerTypeEnum.box ? contract?.boxRateKm : contract?.tiltRateKm;

    const ratePerKm = trailerDetails?.contractId ? contractRatePerKm : trailerDetails?.ratePerKm;

    let contractNode: React.ReactNode = null;

    if (fetchContractsRequests?.loading) {
        contractNode = <ControlLoaderIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.brandAccent} />;
    }

    if (!fetchContractsRequests?.loading && trailerDetails && trailerDetails?.contractId) {
        contractNode = trailerDetails?.contractName;
    }

    if (!fetchContractsRequests?.loading && trailerDetails && !trailerDetails?.contractId) {
        contractNode = t('common:dedicated.none');
    }

    let contractRightNode: React.ReactNode = null;
    if (contract?.id) {
        contractRightNode = (
            <TransparentTrigger
                spaces="xs"
                onClick={() => {
                    onOpenContactDetails(contract.id);
                }}
                leftIcon={<DetailsIcon strokeColor={StyleGuideColorsEnum.gray} />}
                reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
            />
        );
    }

    return (
        <div className={cx('wrap')}>
            <AlertGroup className={cx('alerts')}>
                {trailerDetails?.linkedTruckId && (
                    <LinkedTruckAlert
                        plateNumber={trailerDetails?.linkedTruckPlateNumber}
                        truckId={trailerDetails.linkedTruckId}
                        onOpenTruckDetails={onOpenTruckDetails}
                    />
                )}
                {trailerDetails?.status !== TrailerStatusEnum.archived && (
                    <LastGPSPositionAlert
                        companyId={partnerId}
                        assetType={AssetTypeEnum.trailer}
                        plateNumber={trailerDetails?.plateNumber || ''}
                        isDedicated={!!trailerDetails?.contractId}
                    />
                )}
                {trailerDetails?.status === TrailerStatusEnum.archived && !!trailerDetails?.archivingReason && (
                    <ArchiveReasonAlert reason={trailerDetails.archivingReason} />
                )}
            </AlertGroup>
            {isLoading && <LoaderOverlay />}
            <div className={cx('fields')}>
                <TrailerType trailerType={trailerDictModel?.trailerType} className={cx('icon')} />
                <FieldValue
                    label={trailerTypeLabel}
                    icon={
                        <TrailerIcon
                            size={20}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    }
                    value={
                        trailerDictModel
                            ? t('common:trailer-subtype-template', {
                                  length: trailerDictModel.length,
                                  model: trailerDictModel.model,
                                  eur1Pallets: trailerDictModel.eur1Pallets,
                                  eur2Pallets: trailerDictModel.eur2Pallets,
                              })
                            : ''
                    }
                />
                <FieldGroup>
                    <FieldValue
                        className={cx('field', 'field--plate-number')}
                        label={t('common:trailers-page.add-trailer.fields.plate-number.label')}
                        value={trailerDetails?.plateNumber || ''}
                    />
                    <FieldValue
                        className={cx('field', 'field--vin')}
                        label={t('common:trailers-page.add-trailer.fields.trailer-vin.label')}
                        value={trailerDetails?.vin || ''}
                    />
                </FieldGroup>

                <FieldValue
                    className={cx('field', 'field--country')}
                    icon={<FlagIcon countryCode={trailerDetails?.countryCode} />}
                    label={t('common:trailers-page.add-trailer.fields.country.label')}
                    value={
                        (trailerDetails?.countryCode
                            ? countryByCode?.[trailerDetails?.countryCode]?.userLangDisplayName
                            : null) || ''
                    }
                />
                <FieldGroup>
                    <FieldValue
                        className={cx('field', 'field--contract')}
                        label={t('common:trailers-page.add-trailer.fields.contract.label')}
                        value={contractNode}
                        tooltipPosition={TooltipPositionEnum.centerRight}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                {t('common:trailers-page.add-trailer.fields.contract.tooltip')}
                            </TooltipContent>
                        }
                        rightNode={contractRightNode}
                    />
                    <FieldValue
                        className={cx('field', 'field--rate')}
                        label={t('common:trailers-page.add-trailer.fields.rate.label')}
                        value={isNumber(ratePerKm) ? <Money amount={ratePerKm} currency={CurrencyEnum.EUR} /> : ''}
                    />
                </FieldGroup>
            </div>
            <TrailerActionHistory
                onOpenUserDetails={onOpenUserDetails}
                createdDate={trailerDetails?.createdDate}
                createdByBroker={checkIsApiCarrierTrailerDetails(trailerDetails) && trailerDetails?.addedByBroker}
                createdByName={trailerDetails?.createdBy?.fullName}
                createdById={trailerDetails?.createdBy?.id}
                modifiedDate={trailerDetails?.lastModifiedDate}
                modifiedByBroker={
                    checkIsApiCarrierTrailerDetails(trailerDetails) && trailerDetails?.lastModifiedByBroker
                }
                modifiedByName={trailerDetails?.lastModifiedBy?.fullName}
                modifiedById={trailerDetails?.lastModifiedBy?.id}
            />
        </div>
    );
};

export default TrailerDetails;
