import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import {
    selectCountriesAllCodes,
    selectCountriesByCode,
    selectCountriesDictRequest,
} from 'common/store/countries-dict/selectors';
import { CountryCodeT } from 'common/store/countries-dict/models';
import DropdownSearchMultipleInput, {
    IconMetaT,
} from 'design-system/components/dropdowns/DropdownSearchMultipleInput/DropdownSearchMultipleInput';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import FewCountriesIcon from 'common/icons/FewCountriesIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import classNames from 'classnames/bind';

import styles from './CountryMultipleDropdown.scss';
import DropdownMultipleInputCountLabel from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInputCountLabel/DropdownMultipleInputCountLabel';

const cx = classNames.bind(styles);

type ValueT = CountryCodeT;
type OptionT = CountryCodeT;

type PropsT = {
    onChange: (values: Array<ValueT>) => void;
    values: Array<ValueT>;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    onReset?: () => void;
    placeholder?: string;
    inputPlaceholder?: string;
    testSelector?: string;
    renderTrigger?: (options: Array<OptionT>, placeholder: string | undefined) => React.ReactNode;
    renderOption?: (option: OptionT) => React.ReactNode;
    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (meta: IconMetaT) => React.ReactNode;
    hasClearControl?: boolean;
};

const CountryMultipleDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        onChange,
        values,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        hasChanges,
        onBlur,
        onFocus,
        testSelector,
        renderTrigger,
        renderLeftIcon,
        renderRightIcon,
        renderOption,
        hasClearControl,
    } = props;

    const { t } = useTranslation();

    const [query, setQuery] = React.useState<string>('');

    const fetchRequest = useSelector(selectCountriesDictRequest);
    const allCodes = useSelector(selectCountriesAllCodes);
    const byCode = useSelector(selectCountriesByCode);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const defaultRenderTrigger = (countryCodes: Array<CountryCodeT>, placeholder: string | undefined) => {
        if (!countryCodes?.length) {
            return placeholder;
        }

        if (countryCodes.length === 1) {
            const firstCountryCode = countryCodes[0];
            return byCode[firstCountryCode]?.userLangDisplayName || null;
        }

        return <DropdownMultipleInputCountLabel count={countryCodes?.length} />;
    };

    const defaultRenderLeftIcon = () => {
        if (!values?.length) {
            return <FlagIcon />;
        }

        if (values.length === 1) {
            return <FlagIcon countryCode={values[0]} />;
        }

        return <FewCountriesIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.brandDark} />;
    };

    const defaultRenderOption = (countryCode: OptionT) => {
        return <CountyFlagLabel className={cx('option')} country={byCode[countryCode]} />;
    };

    const getOptionValue = (option: OptionT): CountryCodeT => option;

    const handleOptionClick = (countryCodes: Array<ValueT>) => {
        onChange(countryCodes);
    };

    const filteredCodes = React.useMemo(() => {
        const clearedQuery = query.toLowerCase().replace(/ /g, '').trim();
        if (!clearedQuery) {
            return allCodes;
        }

        return allCodes.filter((countryCode) => {
            const country = countryCode ? byCode[countryCode] : null;

            return [country?.userLangDisplayName, country?.language, country?.langCode, country?.code].some((text) =>
                text?.toLowerCase().replace(/ /g, '').includes(clearedQuery),
            );
        });
    }, [allCodes, query, byCode]);

    const handleReset = () => {
        onChange([]);
    };

    const placeholder = props.placeholder || t('common:common-components.countries-selector.placeholder');
    const inputPlaceholder =
        props.inputPlaceholder || t('common:common-components.countries-selector.input-placeholder');

    return (
        <DropdownSearchMultipleInput<OptionT, ValueT>
            className={className}
            selectedValues={values || null}
            placeholder={placeholder}
            inputPlaceholder={inputPlaceholder}
            options={filteredCodes}
            onSelect={handleOptionClick}
            hasError={hasError}
            hasWarning={hasWarning}
            hasChanges={hasChanges}
            renderOption={renderOption || defaultRenderOption}
            renderRightIcon={renderRightIcon}
            renderLeftIcon={renderLeftIcon || defaultRenderLeftIcon}
            renderTrigger={renderTrigger || defaultRenderTrigger}
            getOptionValue={getOptionValue}
            onBlur={onBlur}
            onFocus={onFocus}
            isLoading={fetchRequest.loading}
            testSelector={`${testSelector}_countries`}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onReset={hasClearControl ? handleReset : undefined}
            onChangeQuery={setQuery}
        />
    );
});

export default CountryMultipleDropdown;
