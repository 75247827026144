import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StatusFooterContent.scss';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import ChangeStatusDropdown from 'common/layouts/TransportOrderDetailsPageLayout/footers/StatusFooterContent/ChangeStatusDropdown/ChangeStatusDropdown';
import ManagementControl from 'common/layouts/TransportOrderDetailsPageLayout/footers/StatusFooterContent/ManagementControl/ManagementControl';
import { useTimeMark } from 'common/utils/hooks/useTimeMark';
import { TransportOrderStatusEnum } from 'common/utils/api/models';
import { checkIsCarrierTransportOrderDetails } from 'common/store/transport-order-details/type-guards';
import TimeLeft from 'common/layouts/TimeLeft/TimeLeft';
import isNumber from 'lodash/isNumber';
import { useTranslation } from 'react-i18next';
import AdditionalManagementControl from 'common/layouts/TransportOrderDetailsPageLayout/footers/StatusFooterContent/AdditionalManagementControl/AdditionalManagementControl';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    descriptor: StateMachineEntityDescriptorT;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onUnassignedAssets: () => void;
    onStartAssignAssets: () => void;
    isAvailableToUnassignDriver: boolean;
    onUnassignedDrivers: () => void;
    onStartAssignDrivers: () => void;
};

const StatusFooterContent: React.FC<PropsT> = React.memo((props) => {
    const {
        descriptor,
        transportOrderDetails,
        transportOrderId,
        onUnassignedAssets,
        onStartAssignAssets,
        isAvailableToUnassignDriver,
        onUnassignedDrivers,
        onStartAssignDrivers,
    } = props;

    const { t } = useTranslation();

    const isAllowAssignAssets =
        !!transportOrderDetails &&
        checkIsCarrierTransportOrderDetails(transportOrderDetails) &&
        transportOrderDetails.availableToAssignAssets;
    const isAllowAssignDrivers =
        !!transportOrderDetails &&
        checkIsCarrierTransportOrderDetails(transportOrderDetails) &&
        transportOrderDetails.availableToAssignDriver;

    const deadline = transportOrderDetails?.waypoints[0]?.dateTimeFrom;
    const { diff, formattedDiff } = useTimeMark({
        timeMark: deadline,
    });

    const isFailed = isNumber(diff) && diff <= 0;
    const isWarning = !!(
        transportOrderDetails &&
        checkIsCarrierTransportOrderDetails(transportOrderDetails) &&
        (transportOrderDetails?.runningOutOfTimeToAssignAsset || transportOrderDetails?.runningOutOfTimeToAssignDriver)
    );

    const isAssetsUnassigned = transportOrderDetails?.status === TransportOrderStatusEnum.assetsUnassigned;
    if (isAssetsUnassigned) {
        return (
            <div className={cx('actions')}>
                <TimeLeft
                    className={cx('time-left')}
                    isWarning={isWarning}
                    isFailed={isFailed}
                    failedMessage={t('common:transport-order-details.footer.tour-already-started')}
                    time={formattedDiff}
                    timeDescription={t('common:transport-order-details.footer.to-assign-assets')}
                />
                <div className={cx('action', 'action--assign')}>
                    <Button className={cx('button')} theme={ButtonThemeEnum.primary} onClick={onStartAssignAssets}>
                        {t('common:transport-order-details.actions.assign-assets')}
                    </Button>
                </div>
                <AdditionalManagementControl
                    transportOrderDetails={transportOrderDetails}
                    isShowAssignDrivers
                    isAllowAssignDrivers={isAllowAssignDrivers}
                    onStartAssignDrivers={onStartAssignDrivers}
                    isAllowToUnassignDriver={isAvailableToUnassignDriver}
                    onUnassignedDrivers={onUnassignedDrivers}
                />
            </div>
        );
    }

    const isDriverUnassigned = transportOrderDetails?.status === TransportOrderStatusEnum.driverUnassigned;
    if (isDriverUnassigned) {
        return (
            <div className={cx('actions')}>
                <TimeLeft
                    className={cx('time-left')}
                    isWarning={isWarning}
                    isFailed={isFailed}
                    failedMessage={t('common:transport-order-details.footer.tour-already-started')}
                    time={formattedDiff}
                    timeDescription={t('common:transport-order-details.footer.to-assign-driver', {
                        postProcess: 'interval',
                        count: transportOrderDetails?.drivers?.length || 0,
                    })}
                />
                <ManagementControl
                    transportOrderDetails={transportOrderDetails}
                    isShowAssignAssets={isAllowAssignAssets}
                    isAllowAssignAssets={isAllowAssignAssets}
                    onUnassignedAssets={onUnassignedAssets}
                    onStartAssignAssets={onStartAssignAssets}
                    isShowAssignDrivers={false}
                    isAllowAssignDrivers={false}
                    onUnassignedDrivers={onUnassignedDrivers}
                    onStartAssignDrivers={onStartAssignDrivers}
                />
                <div className={cx('action', 'action--assign')}>
                    <Button className={cx('button')} theme={ButtonThemeEnum.primary} onClick={onStartAssignDrivers}>
                        {t('common:transport-order-details.actions.assign-drivers', {
                            postProcess: 'interval',
                            count: transportOrderDetails?.drivers?.length || 0,
                        })}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={cx('actions')}>
            {transportOrderDetails?.status === TransportOrderStatusEnum.assigned && (
                <TimeLeft
                    className={cx('time-left')}
                    isFailed={isFailed}
                    isWarning={isWarning}
                    failedMessage={t('common:transport-order-details.footer.tour-already-started')}
                    time={formattedDiff}
                    timeDescription={t('common:transport-order-details.footer.to-start-tour')}
                />
            )}
            <div className={cx('spacer')} />
            <ManagementControl
                transportOrderDetails={transportOrderDetails}
                isShowAssignAssets
                isAllowAssignAssets={isAllowAssignAssets}
                onUnassignedAssets={onUnassignedAssets}
                onStartAssignAssets={onStartAssignAssets}
                isShowAssignDrivers
                isAllowAssignDrivers={isAllowAssignDrivers}
                onUnassignedDrivers={onUnassignedDrivers}
                onStartAssignDrivers={onStartAssignDrivers}
            />
            <ChangeStatusDropdown
                className={cx('action', 'action--status-dropdown')}
                descriptor={descriptor}
                transportOrderId={transportOrderId}
            />
        </div>
    );
});

export default StatusFooterContent;
