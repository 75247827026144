import { FieldsEnum, FormValuesT } from './constants';
import { simpleStringFormatter } from 'common/utils/form-formatters';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'common/layouts/TrailersPageLayout/query-models';
import { getStartDayFromDateZero, getEndDayFromDateZero, getDateFromDate } from 'common/utils/time';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {
        [QueryFiltersKeysEnum.dictTrailerTypeId]: values[FieldsEnum.dictTrailerTypeId] || undefined,
        [QueryFiltersKeysEnum.contractId]: values[FieldsEnum.contractId] || undefined,
        [QueryFiltersKeysEnum.contractName]: values[FieldsEnum.contractName] || undefined,
        [QueryFiltersKeysEnum.country]: values[FieldsEnum.country] || undefined,
        [QueryFiltersKeysEnum.status]: values[FieldsEnum.status] || undefined,
        [QueryFiltersKeysEnum.createdByUserName]: values[FieldsEnum.createdByUserName] || undefined,
        [QueryFiltersKeysEnum.createdByUserId]: values[FieldsEnum.createdByUserId] || undefined,
        [QueryFiltersKeysEnum.lastModifyByUserId]: values[FieldsEnum.lastModifyByUserId] || undefined,
        [QueryFiltersKeysEnum.lastModifyByUserName]: values[FieldsEnum.lastModifyByUserName] || undefined,
    };

    if (values[FieldsEnum.plateNumber]) {
        queryFilters[QueryFiltersKeysEnum.plateNumber] = simpleStringFormatter(values[FieldsEnum.plateNumber]);
    }

    const createDates = values[FieldsEnum.createDates];
    if (createDates?.from) {
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = getStartDayFromDateZero(getDateFromDate(createDates.from));
    }
    if (createDates?.to) {
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = getEndDayFromDateZero(getDateFromDate(createDates.to));
    }

    const lastModifyDates = values[FieldsEnum.lastModifyDates];
    if (lastModifyDates?.from) {
        queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] = getStartDayFromDateZero(
            getDateFromDate(lastModifyDates.from),
        );
    }
    if (lastModifyDates?.to) {
        queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] = getEndDayFromDateZero(
            getDateFromDate(lastModifyDates.to),
        );
    }

    const lastTelematicUpdateDates = values[FieldsEnum.lastTelematicUpdateDates];
    if (lastTelematicUpdateDates?.from) {
        queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateFrom] = getStartDayFromDateZero(
            getDateFromDate(lastTelematicUpdateDates.from),
        );
    }
    if (lastTelematicUpdateDates?.to) {
        queryFilters[QueryFiltersKeysEnum.lastTelematicUpdateDateTo] = getEndDayFromDateZero(
            getDateFromDate(lastTelematicUpdateDates.to),
        );
    }

    return queryFilters;
};

export default getQueryFilters;
