import React, { RefObject } from 'react';

import classNames from 'classnames/bind';
import styles from './Trigger.scss';
import { RangeSelectorZoneThemeEnum } from '../constants';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

export type PropsT = {
    isLeft?: boolean;
    isRight?: boolean;
    isDisabled?: boolean;
    theme: RangeSelectorZoneThemeEnum;
    divRef: RefObject<HTMLDivElement>;
};

const COLOR_SCHEMAS = {
    [RangeSelectorZoneThemeEnum.orange]: {
        strokeColor: StyleGuideColorsEnum.orange,
    },
    [RangeSelectorZoneThemeEnum.green]: {
        strokeColor: StyleGuideColorsEnum.brandAccent,
    },
    [RangeSelectorZoneThemeEnum.darkGreen]: {
        strokeColor: StyleGuideColorsEnum.brandDark,
    },
    [RangeSelectorZoneThemeEnum.light]: {
        strokeColor: StyleGuideColorsEnum.light,
    },
    [RangeSelectorZoneThemeEnum.charcoal]: {
        strokeColor: StyleGuideColorsEnum.charcoal,
    },
};

const HOVERED_COLOR_SCHEMAS = {
    [RangeSelectorZoneThemeEnum.orange]: {
        strokeColor: '#ae6100',
    },
    [RangeSelectorZoneThemeEnum.green]: {
        strokeColor: '#578200',
    },
    [RangeSelectorZoneThemeEnum.darkGreen]: {
        strokeColor: '#578200',
    },
    [RangeSelectorZoneThemeEnum.light]: {
        strokeColor: StyleGuideColorsEnum.light,
    },
    [RangeSelectorZoneThemeEnum.charcoal]: {
        strokeColor: StyleGuideColorsEnum.light,
    },
};

const Trigger: React.FC<PropsT> = React.memo((props) => {
    const { isLeft, isRight, isDisabled, theme, divRef } = props;

    const [isHovered, setHovered] = React.useState(false);

    const testSelector = React.useMemo(() => {
        if (isLeft) {
            return 'left-trigger';
        }
        if (isRight) {
            return 'right-trigger';
        }

        return 'trigger';
    }, [isLeft, isRight]);

    const handleTriggerMouseLeave = (): void => {
        if (isHovered) {
            setHovered(false);
        }
    };

    const handleTriggerMouseEnter = (): void => {
        if (isDisabled) {
            return;
        }

        if (!isHovered) {
            setHovered(true);
        }
    };

    const colorSchemas = isHovered ? HOVERED_COLOR_SCHEMAS[theme] : COLOR_SCHEMAS[theme];

    return (
        <div
            className={cx('trigger', {
                'trigger--isLeft': isLeft,
                'trigger--isRight': isRight,
                'trigger--isDisabled': isDisabled,
            })}
            ref={divRef}
            data-test-selector={testSelector}
            onMouseEnter={handleTriggerMouseEnter}
            onMouseLeave={handleTriggerMouseLeave}
        >
            <div
                className={cx('line')}
                style={{
                    backgroundColor: colorSchemas.strokeColor,
                }}
            />
        </div>
    );
});

export default Trigger;
