import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { RevokeContractActionEnum } from 'common/utils/api/models';
import { checkIsPositiveNumberOrZeroValue, checkNotEmpty } from 'common/utils/form-validators';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    let errors: FormErrorsT = {
        ...checkNotEmpty(t, [FieldsEnum.action], values),
    };

    const action = values[FieldsEnum.action];

    if (action === RevokeContractActionEnum.setNewContract) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.nextContractId], values),
        };
    }

    if (action === RevokeContractActionEnum.setNewRate) {
        errors = {
            ...errors,
            ...checkNotEmpty(
                t,
                [FieldsEnum.trucksRatePerKm, FieldsEnum.boxesRatePerKm, FieldsEnum.tiltsRatePerKm],
                values,
            ),
            ...checkIsPositiveNumberOrZeroValue(
                t,
                [FieldsEnum.trucksRatePerKm, FieldsEnum.boxesRatePerKm, FieldsEnum.tiltsRatePerKm],
                values,
            ),
        };
    }

    return errors;
};

export default validate;
