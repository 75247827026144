import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import { useFormik } from 'formik';
import { prepareData } from './prepare-reject-data';
import { logWarning } from 'common/utils/logger';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import BigQuestionReversedIcon from 'common/icons/BigQuestionReversedIcon';

export type ArchiveAssetConfirmationDataT = {
    companyId: CompanyIdT;
    assets: Array<{
        plateNumber: string;
        id: AssetIdT;
    }>;
};

type PropsT = {
    assetType: AssetTypeEnum;
    data: ArchiveAssetConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: ArchiveAssetConfirmationDataT, reason: string) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'archive-assets';

const T_MAP: Record<
    AssetTypeEnum,
    {
        oneAssetTitle: string;
        manyAssetsTitle: string;
        oneAssetMessage: string;
        manyAssetsMessage: string;
        reasonFieldTitle: string;
        reasonFieldPlaceholder: string;
    }
> = {
    [AssetTypeEnum.truck]: {
        oneAssetTitle: 'common:trucks-page.archive-confirmation.one-asset-title',
        manyAssetsTitle: 'common:trucks-page.archive-confirmation.many-assets-title',
        oneAssetMessage: 'common:trucks-page.archive-confirmation.one-asset-message',
        manyAssetsMessage: 'common:trucks-page.archive-confirmation.many-assets-message',
        reasonFieldTitle: 'common:trucks-page.archive-confirmation.fields.reason.label',
        reasonFieldPlaceholder: 'common:trucks-page.archive-confirmation.fields.reason.placeholder',
    },
    [AssetTypeEnum.trailer]: {
        oneAssetTitle: 'common:trailers-page.archive-confirmation.one-asset-title',
        manyAssetsTitle: 'common:trailers-page.archive-confirmation.many-assets-title',
        oneAssetMessage: 'common:trailers-page.archive-confirmation.one-asset-message',
        manyAssetsMessage: 'common:trailers-page.archive-confirmation.many-assets-message',
        reasonFieldTitle: 'common:trailers-page.archive-confirmation.fields.reason.label',
        reasonFieldPlaceholder: 'common:trailers-page.archive-confirmation.fields.reason.placeholder',
    },
};

const ArchiveAssetConfirmationModal: React.FC<PropsT> = React.memo((props) => {
    const { data, onCancel, onClose, requestStatus, onConfirm, assetType } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues();
        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const preparedData = prepareData(values);

            if (!data) {
                logWarning('failed to reject contract, empty data');
                return;
            }

            onConfirm(data, preparedData.reason);

            formikHelpers.setTouched({});
        },
    });

    useEffect(() => {
        formik.resetForm();
    }, [data]);

    if (!data) {
        return null;
    }

    const { assets } = data;

    const tSet = T_MAP[assetType];

    let message = null;
    let title = null;
    if (assets.length === 1) {
        message = t(tSet.oneAssetMessage, {
            plateNumber: assets?.[0]?.plateNumber || '',
        });
        title = t(tSet.oneAssetTitle);
    } else {
        message = t(tSet.manyAssetsMessage, {
            count: assets.length,
        });
        title = t(tSet.manyAssetsTitle);
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <form onSubmit={formik.handleSubmit}>
                    <ModalDialog
                        width={DEFAULT_MODAL_DIALOG_WIDTH}
                        testSelector={TEST_SELECTOR}
                        actions={[
                            {
                                children: t('common:error-modal.actions.cancel'),
                                theme: ButtonThemeEnum.secondary,
                                isDisabled: requestStatus.loading,
                                testSelector: `${TEST_SELECTOR}_cancel`,
                                onClick: () => {
                                    onCancel();
                                },
                            },
                            {
                                children: t('common:error-modal.actions.archive'),
                                theme: ButtonThemeEnum.danger,
                                isDisabled: requestStatus.loading,
                                isLoading: requestStatus.loading,
                                testSelector: `${TEST_SELECTOR}_ok`,
                                type: 'submit',
                            },
                        ]}
                        icon={<BigQuestionReversedIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                        title={title}
                        message={message}
                        body={
                            <>
                                <FormikField
                                    name={FieldsEnum.reason}
                                    error={formik.errors[FieldsEnum.reason]}
                                    meta={formik.getFieldMeta(FieldsEnum.reason)}
                                    label={t(tSet.reasonFieldTitle)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.reason}
                                            value={formik.values[FieldsEnum.reason]}
                                            placeholder={t(tSet.reasonFieldPlaceholder)}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                        />
                                    )}
                                </FormikField>
                            </>
                        }
                    />
                </form>
            </ModalContent>
        </Modal>
    );
});

export default ArchiveAssetConfirmationModal;
