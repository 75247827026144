import * as React from 'react';
import { memo } from 'react';
import classNames from 'classnames/bind';
import styles from './RateSourceOption.scss';
import cs from 'classnames';
import { Trans } from 'react-i18next';

const cx = classNames.bind(styles);

const ContractName = ({
    className,
    label,
    labelFallback,
}: {
    label?: React.ReactNode;
    labelFallback?: React.ReactNode;
    className?: string;
}) => {
    return (
        <span
            className={cs(
                cx('contract-name', {
                    'contract-name--is-fallback': !label,
                }),
                className,
            )}
        >
            {label || labelFallback || '-'}
        </span>
    );
};

type PropsT = {
    isContract: boolean;
    isCurrentContract: boolean;
    label?: React.ReactNode;
    labelFallback?: React.ReactNode;
};

const RateSourceOption: React.FC<PropsT> = memo((props) => {
    const { isContract, isCurrentContract, label, labelFallback } = props;

    if (isContract) {
        return (
            <Trans
                i18nKey={
                    isCurrentContract
                        ? 'common:assets.activation.fields.source.current-contract-option-template'
                        : 'common:assets.activation.fields.source.contract-option-template'
                }
                components={{
                    contractName: <ContractName label={label} labelFallback={labelFallback} />,
                }}
            />
        );
    }

    return <span>{label || labelFallback || null}</span>;
});

export default RateSourceOption;
