import * as React from 'react';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum } from 'common/constants';
import InfoIcon from 'common/icons/InfoIcon';
import classNames from 'classnames/bind';
import styles from './ChangeRateOrContractWarning.scss';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    className?: string;
};

const ChangeRateOrContractWarning: React.FC<PropsT> = React.memo((props) => {
    const { className, onClose } = props;

    const { t } = useTranslation();

    return (
        <Alert
            className={className}
            icon={<InfoIcon fillColor={StyleGuideColorsEnum.white} />}
            size={AlertSizeEnum.small}
            theme={AlertThemeEnum.orange}
            onClose={onClose}
        >
            <div className={cx('inner')}>{t('common:trucks-page.warnings.change-rate-or-contract')}</div>
        </Alert>
    );
});

export default ChangeRateOrContractWarning;
