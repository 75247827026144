import { FieldsEnum, FormValuesT, RateSourceEnum } from './constants';
import { AssetDataT } from 'common/layouts/dialogs/ActivateAssetDialogModal/models';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import { ApiActivateVehiclesRequestT } from 'common/utils/api/models';
import { isNonNil } from 'common/utils';

export const prepareActivateVehiclesRequest = (
    assets: Array<AssetDataT>,
    values: FormValuesT,
): ApiActivateVehiclesRequestT | null => {
    const assetIds = assets.map((asset) => asset?.id).filter(isNonNil) || [];

    if (values[FieldsEnum.source] === RateSourceEnum.current) {
        return {
            ids: assetIds,
        };
    }

    if (values[FieldsEnum.source] === RateSourceEnum.new) {
        return {
            ids: assetIds,
            ratePerKm: parseSimpleNumber(values[FieldsEnum.rate]),
        };
    }

    const contractId = values[FieldsEnum.source];
    if (contractId) {
        return {
            ids: assetIds,
            contractId,
        };
    }

    return null;
};
