import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    baseColor: StyleGuideColorsEnum;
};

const BigQuestionReversedIcon: React.FC<PropsT> = (props) => {
    const { baseColor, ...rest } = props;

    return (
        <svg width="200" height="130" viewBox="0 0 200 130" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h200v130H0z" />
                <path
                    d="M103 27a7 7 0 0 1 0 14h64a7 7 0 0 1 0 14h22a7 7 0 0 1 0 14h-19a7 7 0 0 0 0 14h6a7 7 0 0 1 0 14h-52a7.026 7.026 0 0 1-1.5-.161A7.026 7.026 0 0 1 121 97H40a7 7 0 0 1 0-14H7a7 7 0 0 1 0-14h34a7 7 0 0 0 0-14H22a7 7 0 0 1 0-14h34a7 7 0 0 1 0-14h47zm90 56a7 7 0 1 1 0 14 7 7 0 0 1 0-14z"
                    fill={baseColor}
                    opacity=".1"
                />
                <path
                    d="M138.577 93.075a54.587 54.587 0 0 1-7.933 5.299l.165 14.083a2 2 0 0 1-3.22 1.609l-12.663-9.743A66.553 66.553 0 0 1 100 106c-30.376 0-55-20.147-55-45s24.624-45 55-45 55 20.147 55 45c0 6.879-1.886 13.397-5.259 19.227a42.703 42.703 0 0 1-2.015 3.154m-2.533 3.273a47.462 47.462 0 0 1-2.906 3.122"
                    stroke={baseColor}
                    strokeWidth="2.5"
                    fill="#FFF"
                    strokeLinecap="round"
                />
                <path
                    d="M98.142 70.135c0-2.149.239-3.94.717-5.376.479-1.436 1.524-3.096 3.135-4.98 1.611-1.885 2.661-3.248 3.15-4.088.488-.84.864-1.728 1.127-2.666.264-.937.396-2.03.396-3.28 0-2.442-.537-4.351-1.611-5.728-1.075-1.377-2.54-2.066-4.395-2.066-1.875 0-3.408.67-4.6 2.007-1.19 1.338-1.806 3.13-1.845 5.376h-3.223c.04-3.203.962-5.767 2.769-7.69 1.806-1.924 4.106-2.886 6.9-2.886 2.89 0 5.155.991 6.796 2.973 1.64 1.983 2.46 4.644 2.46 7.984 0 2.07-.404 4.009-1.215 5.815-.81 1.807-2.358 3.99-4.643 6.548-1.797 1.856-2.696 4.541-2.696 8.057h-3.222zm-.352 9.785c0-.684.186-1.255.557-1.714.37-.459.898-.688 1.582-.688.683 0 1.216.23 1.596.688.381.459.572 1.03.572 1.714 0 .664-.19 1.216-.572 1.655-.38.44-.913.66-1.596.66-.684 0-1.211-.22-1.582-.66-.371-.44-.557-.991-.557-1.655z"
                    fill={baseColor}
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
};

BigQuestionReversedIcon.displayName = 'BigQuestionReversedIcon';

const storyProps: PropsT = {
    baseColor: StyleGuideColorsEnum.tomatoRed,
};

export { storyProps };
export default BigQuestionReversedIcon;
