import React from 'react';

import classNames from 'classnames/bind';
import styles from './Zone.scss';
import { RangeSelectorZoneThemeEnum } from '../constants';

const cx = classNames.bind(styles);

export type PropsT = {
    isSelected?: boolean;
    isHovered?: boolean;
    hasLeftBorder?: boolean;
    hasRightBorder?: boolean;
    isDisabled?: boolean;
    left: number;
    width: number;
    theme?: RangeSelectorZoneThemeEnum | null;
    testSelector?: string;
    children?: React.ReactNode;
};

const Zone: React.FC<PropsT> = React.memo((props) => {
    const {
        isSelected,
        isHovered,
        hasLeftBorder,
        hasRightBorder,
        theme,
        isDisabled,
        left,
        width,
        testSelector,
        children,
    } = props;

    return (
        <div
            className={cx('zone', {
                'zone--isDisabled': isDisabled,
                'zone--isSelected': isSelected,
                'zone--isHovered': isHovered,
                'zone--hasLeftBorder': hasLeftBorder,
                'zone--hasRightBorder': hasRightBorder,
                [`zone--theme-${theme}`]: !!theme,
            })}
            style={{
                left: `${left}%`,
                width: `${width}%`,
            }}
            data-test-selector="range-selector-zone"
        >
            {children}
        </div>
    );
});

export default Zone;
