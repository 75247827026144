import * as React from 'react';
import { memo } from 'react';
import { ApiLocationT } from 'common/utils/api/models';
import AsyncDictCountryNameLabel from 'common/components/AsyncDictCountryNameLabel/AsyncDictCountryNameLabel';
import reduce from 'lodash/reduce';
import { Trans } from 'react-i18next';
import { createMapLink } from 'common/utils/links';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import classNames from 'classnames/bind';
import styles from './LocationLabel.scss';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

export type LocationLabelAddressFormatT =
    | 'pickup_asset_s1_s2_zip_city_country'
    | 'dropoff_asset_s1_s2_zip_city_country'
    | 's1_s2_zip_city_country'
    | 'zip_city_country';

export type PropsT = {
    className?: string;
    format: LocationLabelAddressFormatT;
    location: ApiLocationT | null | undefined;
    allowShowCountryCode?: boolean; // wait experiment TZT-6541
    separator?: React.ReactNode;
};

const concatNodes = (nodes: Array<React.ReactNode>, separator: React.ReactNode): React.ReactNode => {
    const filteredNodes = nodes.filter((value) => !!value);

    return reduce(
        filteredNodes,
        (acc: Array<React.ReactNode>, value, index) => {
            acc.push(
                <>
                    {value}
                    {value && filteredNodes.length - 1 !== index ? separator : null}
                </>,
            );
            return acc;
        },
        [],
    );
};

const renderCountry = (allowShowCountryCode: boolean | undefined, location: ApiLocationT | null | undefined) => {
    if (!location || !location?.country) {
        return null;
    }

    if (allowShowCountryCode) {
        return location?.country;
    }

    return <AsyncDictCountryNameLabel countryCode={location?.country} />;
};

const checkShouldRenderLocationPlaceholder = (location: ApiLocationT | null | undefined) => {
    const restAddress = omit(location, ['latitude', 'longitude', 'country']);
    const isEmptyRestAddress = isEmpty(restAddress);
    return isEmptyRestAddress && isNumber(location?.latitude) && isNumber(location?.longitude);
};

const LocationLabel: React.FC<PropsT> = memo((props) => {
    const { className, location, allowShowCountryCode, format } = props;

    if (!location) {
        return null;
    }

    const separator = props.separator || ', ';

    let locationNode: React.ReactNode;
    if (format === 's1_s2_zip_city_country') {
        locationNode = concatNodes(
            [
                location.street1,
                location.street2,
                location.zipCode,
                location.city,
                renderCountry(allowShowCountryCode, location),
            ],
            separator,
        );
    } else if (format === 'zip_city_country') {
        locationNode = concatNodes(
            [location.zipCode, location.city, renderCountry(allowShowCountryCode, location)],
            separator,
        );
    } else if (format === 'pickup_asset_s1_s2_zip_city_country') {
        if (checkShouldRenderLocationPlaceholder(location)) {
            locationNode = (
                <>
                    <Trans i18nKey="common:info-table.label.empty-address-for-pickup-assets-0" />{' '}
                    <Link
                        className={cx('link')}
                        to={createMapLink(`${location.latitude},${location.longitude}`)}
                        theme={LinkThemeEnum.boldBrandDark}
                        target="_blank"
                        isExternal
                    >
                        ({location?.latitude}, {location?.longitude})
                    </Link>{' '}
                    <Trans i18nKey="common:info-table.label.empty-address-for-pickup-assets-1" />
                </>
            );
        } else {
            locationNode = concatNodes(
                [
                    location.street1,
                    location.street2,
                    location.zipCode,
                    location.city,
                    renderCountry(allowShowCountryCode, location),
                ],
                separator,
            );
        }
    } else if (format === 'dropoff_asset_s1_s2_zip_city_country') {
        if (checkShouldRenderLocationPlaceholder(location)) {
            locationNode = (
                <>
                    <Trans i18nKey="common:info-table.label.empty-address-0" />{' '}
                    <Link
                        className={cx('link')}
                        to={createMapLink(`${location.latitude},${location.longitude}`)}
                        theme={LinkThemeEnum.boldBrandDark}
                        target="_blank"
                        isExternal
                    >
                        ({location?.latitude}, {location?.longitude})
                    </Link>{' '}
                    <Trans i18nKey="common:info-table.label.empty-address-1" />
                </>
            );
        } else {
            locationNode = concatNodes(
                [
                    location.street1,
                    location.street2,
                    location.zipCode,
                    location.city,
                    renderCountry(allowShowCountryCode, location),
                ],
                separator,
            );
        }
    }

    return <span className={className}>{locationNode}</span>;
});

export default LocationLabel;
