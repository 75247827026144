import * as React from 'react';

import styles from './Alert.scss';
import cs from 'classnames';
import classNames from 'classnames/bind';
import { StyleGuideColorsEnum } from 'common/constants';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentButton from '../TransparentButton/TransparentButton';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';

import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';

export enum AlertThemeEnum {
    brandAccent = 'brand-accent',
    tomatoRed = 'tomato-red',
    charcoal = 'charcoal',
    orange = 'orange',
    gray = 'gray',
}

export enum AlertSizeEnum {
    small = 'small',
    medium = 'medium',
    mediumLarge = 'medium-large',
}

export type PropsT = {
    className?: string;
    icon: React.ReactNode;
    onClose?: () => void;
    size: AlertSizeEnum;
    theme: AlertThemeEnum;
    actionText?: React.ReactNode;
    onClickAction?: () => void;
    isAlignItemsCenter?: boolean;
    tooltipNode?: React.ReactNode;
    footerNode?: React.ReactNode;
};

const cx = classNames.bind(styles);

const closeIconFillColorMap: Record<AlertThemeEnum, StyleGuideColorsEnum> = {
    [AlertThemeEnum.brandAccent]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.tomatoRed]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.charcoal]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.orange]: StyleGuideColorsEnum.white,
    [AlertThemeEnum.gray]: StyleGuideColorsEnum.white,
};

const Alert: React.FC<PropsT> = React.memo((props) => {
    const {
        children,
        className,
        icon,
        theme,
        size,
        onClose,
        onClickAction,
        actionText,
        isAlignItemsCenter,
        tooltipNode,
        footerNode,
    } = props;

    const closeIconFillColor = closeIconFillColorMap[theme];

    return (
        <div
            className={cs(
                cx('inner', {
                    [`inner--theme-${theme}`]: true,
                    [`inner__size-${size}`]: true,
                }),
                className,
            )}
        >
            <div
                className={cx('row', {
                    'row--is-align-items-center': !!isAlignItemsCenter,
                })}
            >
                <div
                    className={cx('icon', {
                        [`icon__size-${size}`]: true,
                    })}
                >
                    {icon}
                </div>
                <div
                    className={cx('text', {
                        [`text__size-${size}`]: true,
                    })}
                >
                    {children}
                </div>
                {actionText && onClickAction ? (
                    <TransparentButton onClick={onClickAction} className={cx('action')}>
                        <span className={cx('action-text')}>{actionText}</span>
                    </TransparentButton>
                ) : null}
                {tooltipNode && (
                    <Tooltip
                        position={TooltipPositionEnum.centerLeft}
                        theme={TooltipThemeEnum.black}
                        tooltipNode={tooltipNode}
                    >
                        {(isShow) => <TooltipIconTrigger className={cx('tooltip')} isShow={isShow} />}
                    </Tooltip>
                )}
                {onClose && (
                    <TransparentButton onClick={onClose} className={cx('close')}>
                        <CloseIcon fillColor={closeIconFillColor} />
                    </TransparentButton>
                )}
            </div>
            {footerNode}
        </div>
    );
});

export default Alert;
