export enum UpdateWayEnum {
    newRate = 'new-rate',
    assignContract = 'assign-contract',
    unassignContract = 'unnasignContract',
}

export enum FieldsEnum {
    updateWay = 'update-way',
    rate = 'rate',
    contractId = 'contract-id',
}

export type FormValuesT = {
    [FieldsEnum.updateWay]: UpdateWayEnum | null;
    [FieldsEnum.rate]: string;
    [FieldsEnum.contractId]: ShipperContractIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
