import * as React from 'react';
import { useState } from 'react';

import ContactsTable from './ContactsTable/ContactsTable';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames/bind';
import styles from './Members.scss';
import ExpandIcon from 'common/icons/ExpandIcon';
import DropdownControl from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import { CompanyContactT } from 'common/store/members/models';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAddMemberRequest,
    selectCompanyContacts,
    selectDeleteMemberRequest,
    selectFetchContactsRequest,
    selectUpdateMemberRequest,
} from 'common/store/members/selectors';
import {
    cancelInvite,
    changeAdminRoleWithSuccessors,
    changeUserRole,
    changeUserRoleWithSuccessor,
    deleteContact,
    deleteUser,
    deleteUserWithSuccessor,
    fetchContacts,
    inviteUser,
    makeMainContact,
    restoreUser,
} from 'common/store/members/actions';
import { StyleGuideColorsEnum } from 'common/constants';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { ApiAddCompanyUserRequestT, ApiUserStatusT, RoleT, UserStatusEnum } from 'common/utils/api/models';
import { TabEnum } from 'common/layouts/Members/constants';
import TabLabel, { TabLabelSizeEnum } from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import AddUserIcon from 'common/icons/AddUserIcon';
import ContactIcon from 'common/icons/ContactIcon';
import { logWarning } from 'common/utils/logger';
import CancelInviteConfirmation, {
    CancelInviteConfirmationDataT,
} from 'common/layouts/Members/dialogs/CancelInviteConfirmation/CancelInviteConfirmation';
import RestoreUserConfirmation, {
    RestoreUserConfirmationDataT,
    RestoreUserConfirmationPropsT,
} from 'common/layouts/Members/dialogs/RestoreUserConfirmation/RestoreUserConfirmation';
import DeleteContactConfirmation, {
    DeleteContactConfirmationDataT,
    DeleteContactConfirmationPropsT,
} from 'common/layouts/Members/dialogs/DeleteContactConfirmation/DeleteContactConfirmation';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ChangeUserRoleModal, {
    ChangeUserRoleModalDataT,
    ChangeUserRoleModalPropsT,
} from 'common/layouts/Members/dialogs/ChangeUserRoleModal/ChangeUserRoleModal';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { selectPermissions } from 'common/store/auth/selectors';
import DeactivateUserModal, {
    DeactivateUserModalDataT,
    DeactivateUserModalPropsT,
} from 'common/layouts/Members/dialogs/DeactivateUserModal/DeactivateUserModal';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useOpenedSidebar, useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { ContactDetailsSidebarDataT } from 'common/layouts/SideBars/contents/ContactDetailsSidebarContent/models';
import InviteUserModal, { InviteUserModalDataT } from 'common/layouts/Members/dialogs/InviteUserModal/InviteUserModal';
import ChangeUserRoleConfirmation, {
    ChangeUserRoleConfirmationDataT,
    ChangeUserRoleConfirmationPropsT,
} from 'common/layouts/Members/dialogs/ChangeUserRoleConfirmation/ChangeUserRoleConfirmation';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import { isNonNil } from 'common/utils';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { membersRefreshChannel } from 'common/store/members/channels';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import TableError from 'common/components/Table/TableError/TableError';
import { checkFilters } from 'common/components/Table/utils/check-filters';

const cx = classNames.bind(styles);

const getTabByUserStatus = (userStatus?: ApiUserStatusT) => {
    if (userStatus === UserStatusEnum.archived) {
        return TabEnum.archive;
    }

    return TabEnum.active;
};

const TAB_LABEL_T: Record<TabEnum, string> = {
    [TabEnum.archive]: 'common:team-members.tabs.archive',
    [TabEnum.active]: 'common:team-members.tabs.active',
};

const EMPTY_CONTACTS: CompanyContactT[] = [];

type PropsT = {
    isLockedProfile?: boolean;
};

const Members: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType } = usePartnerContext();

    const isDisableInviteUsersForLockedProfile = !!props.isLockedProfile;

    const permissions = useSelector(selectPermissions);

    const deleteContactConfirmation = useModalDialog<DeleteContactConfirmationDataT>();
    const handleDeleteContactModalDialog = (contact: CompanyContactT) => {
        if (!contact?.id || !partnerId) {
            logWarning('failed to confirm delete contact, empty contact?.id or partnerId');
            return;
        }

        deleteContactConfirmation.setData({
            contactId: contact.id,
            partnerId,
        });
    };

    const handleConfirmDeleteContact: DeleteContactConfirmationPropsT['onConfirm'] = React.useCallback(
        (partnerId, contactId) => {
            if (!contactId) {
                logWarning('failed to delete contact, empty contactId');
                return;
            }

            dispatch(deleteContact(contactId, partnerId));
        },
        [],
    );

    const cancelInviteConfirmationDialog = useModalDialog<CancelInviteConfirmationDataT>();
    const restoreUserConfirmationDialog = useModalDialog<RestoreUserConfirmationDataT>();
    const changeUserRoleModalDialog = useModalDialog<ChangeUserRoleModalDataT>();
    const changeUserRoleConfirmationDialog = useModalDialog<ChangeUserRoleConfirmationDataT>();

    const handleShowChangeUserRoleModalDialog = React.useCallback(
        (contact: CompanyContactT): void => {
            changeUserRoleModalDialog.setData({
                partnerType,
                partnerId,
                contact: {
                    userId: contact.userId,
                    roles: contact.roles,
                    fullName: contact.fullName,
                },
            });
        },
        [partnerType, partnerId],
    );

    const handleChangeUserRole: ChangeUserRoleModalPropsT['onChangeUserRole'] = React.useCallback(
        ({ data, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            changeUserRoleModalDialog.onClose();
            changeUserRoleConfirmationDialog.setData({
                ...data,
                isNeedSuccessor: false,
                isDemotionAdmin: false,
                changes,
            });
        },
        [],
    );

    const onChangeUserRoleWithSuccessor: ChangeUserRoleModalPropsT['onChangeUserRoleWithSuccessor'] = React.useCallback(
        ({ data, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            changeUserRoleModalDialog.onClose();
            changeUserRoleConfirmationDialog.setData({
                ...data,
                isNeedSuccessor: true,
                isDemotionAdmin: false,
                changes,
            });
        },
        [],
    );

    const onChangeAdminRole: ChangeUserRoleModalPropsT['onChangeAdminRole'] = React.useCallback(
        ({ data, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            changeUserRoleModalDialog.onClose();
            changeUserRoleConfirmationDialog.setData({
                ...data,
                isNeedSuccessor: true,
                isDemotionAdmin: true,
                changes,
            });
        },
        [],
    );

    const handleConfirmChangeUserRole: ChangeUserRoleConfirmationPropsT['onConfirm'] = React.useCallback(
        (data): void => {
            if (!data.partnerId) {
                return;
            }

            if (data.isDemotionAdmin) {
                dispatch(
                    changeAdminRoleWithSuccessors(
                        data.changes.role,
                        data.changes.userId,
                        data.changes.newDispatcherUserId,
                        data.changes.newKeyAccountManagerUserId,
                        partnerId,
                    ),
                );
                return;
            }

            if (data.isNeedSuccessor) {
                if (!data.changes.successorUserId || !data.changes.successorRole) {
                    return;
                }

                dispatch(
                    changeUserRoleWithSuccessor(
                        data.changes.role,
                        data.changes.userId,
                        data.changes.successorUserId,
                        data.changes.successorRole,
                        partnerId,
                    ),
                );
            } else {
                dispatch(changeUserRole(data.changes.role, data.changes.userId, partnerId));
            }
        },
        [],
    );

    const deactivateUserModalDialog = useModalDialog<DeactivateUserModalDataT>();
    const handleShowDeactivateUserModalDialog = React.useCallback(
        (contact: CompanyContactT): void => {
            if (!partnerId || !partnerType) {
                logWarning('failed to handleShowDeactivateUserModalDialog, empty partnerType or partnerId');
                return;
            }

            deactivateUserModalDialog.setData({
                partnerType,
                partnerId,
                contact: {
                    userId: contact.userId,
                    roles: contact.roles,
                    fullName: contact.fullName,
                },
            });
        },
        [partnerId, partnerType],
    );

    const handleDeactivateUser: DeactivateUserModalPropsT['onDeactivateUser'] = React.useCallback(
        ({ data, isNeedSuccessor, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            if (isNeedSuccessor) {
                if (!changes.successorUserId || !changes.successorRole) {
                    return;
                }

                dispatch(
                    deleteUserWithSuccessor(
                        changes.userId,
                        changes.successorUserId,
                        changes.successorRole,
                        data.partnerId,
                    ),
                );
            } else {
                dispatch(deleteUser(changes.userId, data.partnerId));
            }
        },
        [],
    );

    const [activeTab, setActiveTab] = React.useState<TabEnum>(TabEnum.active);
    const [searchText, setSearchText] = React.useState<string>('');

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const contacts = useSelector(selectCompanyContacts(partnerId));
    const fetchContactsRequestStatus = useSelector(selectFetchContactsRequest(partnerId));

    React.useEffect(() => {
        if (!partnerId) {
            logWarning('failed fetchContacts, empty partnerId');
            return;
        }

        dispatch(fetchContacts(partnerId));
    }, [partnerId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchContacts(partnerId, { isForceUpdate: false }));
    }, [partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchContacts(partnerId, { isForceUpdate: true }));
    }, [partnerId]);
    useChannelSubscribe(membersRefreshChannel, refreshPageHandler);

    const openLeftSidebar = useOpenLeftSidebar();

    const openedContactSidebar = useOpenedSidebar<ContactDetailsSidebarDataT>(CommonSidebarsTypeEnum.contact);
    const selectedContactId =
        openedContactSidebar && 'contactId' in openedContactSidebar ? openedContactSidebar.contactId : null;

    const handleConfirmRestoreUser: RestoreUserConfirmationPropsT['onConfirm'] = React.useCallback(
        (userId, partnerId): void => {
            dispatch(restoreUser(userId, partnerId));
        },
        [partnerId],
    );

    const inviteUserModalDialog = useModalDialog<InviteUserModalDataT>();

    const addMemberRequestStatus = useSelector(selectAddMemberRequest(partnerId));
    const handleSubmitInviteUser = (newUser: ApiAddCompanyUserRequestT): void => {
        dispatch(inviteUser(newUser, partnerId));
    };

    const deleteMemberRequestStatus = useSelector(selectDeleteMemberRequest(partnerId));

    const handleConfirmCancelInviteUser = React.useCallback((partnerId: PartnerIdT, userId: UserIdT): void => {
        dispatch(cancelInvite(userId, partnerId));
    }, []);

    const updateMemberRequestStatus = useSelector(selectUpdateMemberRequest(partnerId));

    const openInviteModal = (): void => {
        inviteUserModalDialog.setData({
            email: '',
            partnerId,
            partnerType,
        });
    };

    const openAddContactSidebar = (): void => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.editContact,
            partnerId,
            isNewContact: true,
        });
    };

    const options = [
        !isDisableInviteUsersForLockedProfile
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          label={t('common:team-members.actions.add-account')}
                          icon={
                              <AddUserIcon
                                  fillColor={StyleGuideColorsEnum.light}
                                  strokeColor={StyleGuideColorsEnum.slate}
                              />
                          }
                      />
                  ),
                  onSelect: openInviteModal,
              }
            : null,
        {
            label: (
                <DropdownControlOptionLabel
                    label={t('common:team-members.actions.add-contact')}
                    icon={
                        <ContactIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.slate} />
                    }
                />
            ),
            onSelect: openAddContactSidebar,
        },
    ].filter(isNonNil);

    const handleMakeMainContact = (contact: CompanyContactT): void => {
        if (!contact?.id) {
            return;
        }

        dispatch(makeMainContact(contact.id, partnerId));
    };

    const handleResendInviteUser = (email: string, role: RoleT): void => {
        dispatch(
            inviteUser(
                {
                    email,
                    role,
                },
                partnerId,
            ),
        );
    };
    const handleInviteUser = (email: string): void => {
        inviteUserModalDialog.setData({
            email,
            partnerId,
            partnerType,
        });
    };

    const handleSelectRow = (event: React.MouseEvent, contact: CompanyContactT): void => {
        if (!contact.id) {
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            contactId: contact.id,
        });
    };

    const groupedContacts = React.useMemo(() => {
        return groupBy(contacts, (contact) => getTabByUserStatus(contact.userStatus));
    }, [contacts]);

    const activeTabContacts = groupedContacts[activeTab] || EMPTY_CONTACTS;

    const filteredActiveTabContacts = React.useMemo(() => {
        return activeTabContacts.filter((contact) => {
            const prepareText = (text?: string) => (text || '').trim().toLocaleLowerCase();
            const preparedSearchText = prepareText(searchText);

            const preparedPhone = prepareText(contact.phone);
            if (preparedPhone.includes(preparedSearchText)) {
                return true;
            }

            const preparedFullName = prepareText(contact.fullName);
            if (preparedFullName.includes(preparedSearchText)) {
                return true;
            }

            const preparedEmail = prepareText(contact.email);
            if (preparedEmail.includes(preparedSearchText)) {
                return true;
            }

            return false;
        });
    }, [activeTabContacts, searchText]);

    const handleShowRestoreUserModalDialog = (contact: CompanyContactT) => {
        if (!contact.id) {
            return;
        }

        restoreUserConfirmationDialog.setData({
            contactId: contact.id,
            partnerId,
        });
    };

    const handleCancelInviteUserModalDialog = (contact: CompanyContactT) => {
        if (!contact.id) {
            return;
        }

        cancelInviteConfirmationDialog.setData({
            contactId: contact.id,
            partnerId,
        });
    };

    const isLoading =
        fetchContactsRequestStatus.loading ||
        updateMemberRequestStatus.loading ||
        deleteMemberRequestStatus.loading ||
        addMemberRequestStatus.loading;

    useCloseModalDialogAfterRequest(updateMemberRequestStatus, [
        cancelInviteConfirmationDialog,
        changeUserRoleModalDialog,
        changeUserRoleConfirmationDialog,
        deactivateUserModalDialog,
        restoreUserConfirmationDialog,
    ]);

    useCloseModalDialogAfterRequest(deleteMemberRequestStatus, [deleteContactConfirmation]);

    useCloseModalDialogAfterRequest(addMemberRequestStatus, [inviteUserModalDialog]);

    const [allowShowBlockInviteAlert, setAllowShowBlockInviteAlert] = useState<boolean>(true);

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(filteredActiveTabContacts, fetchContactsRequestStatus)) {
            return null;
        }

        if (fetchContactsRequestStatus?.error) {
            return <TableError />;
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters([activeTab], [searchText]);
        if (fetchContactsRequestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('common:team-members.messages.not-found.title')}
                    description={t('common:team-members.messages.not-found.description')}
                    isShowAction={false}
                    testSelector="empty-state-has-secondary-filters"
                />
            );
        }

        if (fetchContactsRequestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('common:team-members.messages.empty-quick-filters.title')}
                    description=""
                    testSelector="empty-state-has-primary-filters"
                    isShowAction={false}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('common:team-members.messages.empty.title')}
                testSelector="empty"
                isShowAction={false}
            />
        );
    };

    return (
        <>
            <ListPageLayout>
                <ListPageHeaderLayout
                    withTopPadding
                    leftToolsNode={
                        <>
                            <div className={cx('tabs')}>
                                {map(TabEnum, (tab, tabIndex) => (
                                    <TabLabel
                                        isCompact
                                        className={cx('tabs__tab')}
                                        key={tabIndex}
                                        size={TabLabelSizeEnum.small}
                                        theme={TabsThemesEnum.light}
                                        onClick={() => {
                                            setActiveTab(tab);
                                        }}
                                        isActive={tab === activeTab}
                                    >
                                        {t(TAB_LABEL_T[tab])}
                                    </TabLabel>
                                ))}
                            </div>
                            <SearchControl
                                placeholder={t('common:team-members.search-placeholder')}
                                searchText={searchText}
                                onChangeSearchText={setSearchText}
                            />
                        </>
                    }
                    rightToolsNode={
                        <>
                            {permissions.canEditTeamMembers && (
                                <DropdownControl
                                    options={options}
                                    renderTrigger={(isActive, onClick) => (
                                        <Button
                                            theme={ButtonThemeEnum.primary}
                                            onClick={onClick}
                                            rightIcon={<ExpandIcon fillColor={StyleGuideColorsEnum.white} />}
                                            isPressed={isActive}
                                        >
                                            {t('common:team-members.actions.add-new-member')}
                                        </Button>
                                    )}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                />
                            )}
                        </>
                    }
                />
                {isDisableInviteUsersForLockedProfile && allowShowBlockInviteAlert && (
                    <Alert
                        icon={
                            <AddUserIcon
                                fillColor={StyleGuideColorsEnum.white}
                                strokeColor={StyleGuideColorsEnum.white}
                            />
                        }
                        size={AlertSizeEnum.small}
                        theme={AlertThemeEnum.orange}
                        onClose={() => {
                            setAllowShowBlockInviteAlert(false);
                        }}
                        className={cx('alert')}
                    >
                        {t('common:team-members.alerts.block-invite-for-locked-profile')}
                    </Alert>
                )}
                {isLoading && <LoaderOverlay />}
                {renderTableMessage()}
                {!!filteredActiveTabContacts?.length && (
                    <ContactsTable
                        contacts={filteredActiveTabContacts}
                        onDeleteUser={handleShowDeactivateUserModalDialog}
                        onDeleteContact={handleDeleteContactModalDialog}
                        onChangeUserRole={handleShowChangeUserRoleModalDialog}
                        onRestoreUser={handleShowRestoreUserModalDialog}
                        onCancelInvite={handleCancelInviteUserModalDialog}
                        onMakeMainContact={handleMakeMainContact}
                        onInviteUser={handleInviteUser}
                        onResendInviteUser={handleResendInviteUser}
                        selectedContactId={selectedContactId}
                        onSelectRow={handleSelectRow}
                        isLoading={false}
                        canEditTeamMembers={permissions.canEditTeamMembers}
                        canInviteUsers={!isDisableInviteUsersForLockedProfile}
                    />
                )}
                <InviteUserModal
                    data={inviteUserModalDialog.data}
                    onClose={inviteUserModalDialog.onClose}
                    onCancel={inviteUserModalDialog.onCancel}
                    onInviteUser={handleSubmitInviteUser}
                />
                <CancelInviteConfirmation
                    data={cancelInviteConfirmationDialog.data}
                    onConfirm={handleConfirmCancelInviteUser}
                    onClose={cancelInviteConfirmationDialog.onClose}
                    onCancel={cancelInviteConfirmationDialog.onCancel}
                />
                <ChangeUserRoleModal
                    data={changeUserRoleModalDialog.data}
                    onClose={changeUserRoleModalDialog.onClose}
                    onChangeUserRole={handleChangeUserRole}
                    onChangeUserRoleWithSuccessor={onChangeUserRoleWithSuccessor}
                    onChangeAdminRole={onChangeAdminRole}
                />
                <ChangeUserRoleConfirmation
                    data={changeUserRoleConfirmationDialog.data}
                    onClose={changeUserRoleConfirmationDialog.onClose}
                    onConfirm={handleConfirmChangeUserRole}
                />
                <DeactivateUserModal
                    data={deactivateUserModalDialog.data}
                    onClose={deactivateUserModalDialog.onClose}
                    onCancel={deactivateUserModalDialog.onCancel}
                    onDeactivateUser={handleDeactivateUser}
                />
                <RestoreUserConfirmation
                    data={restoreUserConfirmationDialog.data}
                    onClose={restoreUserConfirmationDialog.onClose}
                    onConfirm={handleConfirmRestoreUser}
                />
                <DeleteContactConfirmation
                    data={deleteContactConfirmation.data}
                    onClose={deleteContactConfirmation.onClose}
                    onCancel={deleteContactConfirmation.onCancel}
                    onConfirm={handleConfirmDeleteContact}
                />
            </ListPageLayout>
        </>
    );
});

export default Members;
