import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';

import styles from './SideBar.scss';
import ContentRootContext from 'common/layouts/LeftMenuLayout/contexts/content-root-context';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import SideBarLayoutContextWrap from '../SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import { KeyEnum } from 'common/constants';

const cx = classNames.bind(styles);

export enum SideBarPositionEnum {
    left = 'left',
    right = 'right',
}

type PropsT = {
    isShow: boolean;
    isNarrow?: boolean;
    onClose: () => void;
    children: (closeSidebar: () => void) => React.ReactNode;
    position?: SideBarPositionEnum;
};

const SideBar: React.FC<PropsT> = React.memo((props) => {
    const { children, isShow, isNarrow, onClose } = props;

    const position = props.position || SideBarPositionEnum.left;

    const contentRootNode = React.useContext(ContentRootContext);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent): void => {
            if (event.key === KeyEnum.escape) {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClose = React.useCallback(
        (event: any) => {
            if (position === SideBarPositionEnum.left) {
                const navigationNode = document.querySelector('div[data-dismiss="navigation"]');
                if (navigationNode?.contains(event.target)) {
                    return;
                }

                const alertToastsManagerNode = document.querySelector('div[data-dismiss="alert-toasts-manager"]');
                if (alertToastsManagerNode?.contains(event.target)) {
                    return;
                }
            }

            onClose();
        },
        [position, onClose],
    );

    if (!contentRootNode) {
        return null;
    }

    return createPortal(
        <OutsideClickHandler onOutsideClick={handleClose} isDisabled={!isShow}>
            <div
                className={cx('side-bar', {
                    'side-bar--isShow': isShow,
                    'side-bar--isNarrow': isNarrow,
                    [`side-bar--position-${position}`]: true,
                })}
            >
                <div className={cx('content')}>
                    <SideBarLayoutContextWrap>{isShow ? children(onClose) : null}</SideBarLayoutContextWrap>
                </div>
            </div>
        </OutsideClickHandler>,
        contentRootNode,
    );
});

export default SideBar;
