import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './ColoredDiff.scss';
import { CSSProperties } from 'react';
import { StyleGuideColorsEnum } from 'common/constants';
import isNumber from 'lodash/isNumber';

type PropsT = {
    className?: string;
    diff: number | null;
    isInverted?: boolean;
    isNotActual?: boolean;
    customNotNumberColor?: StyleGuideColorsEnum;
    customPositiveColor?: StyleGuideColorsEnum;
    customNegativeColor?: StyleGuideColorsEnum;
};

const cx = classNames.bind(styles);

const ColoredDiff: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        diff,
        isInverted,
        isNotActual,
        children,
        customNotNumberColor,
        customPositiveColor,
        customNegativeColor,
    } = props;

    const style: CSSProperties = {};

    if (!isNumber(diff)) {
        if (customNotNumberColor) {
            style.color = customNotNumberColor;
        }

        return (
            <span className={cs(cx('not-number'), className)} style={style}>
                {children}
            </span>
        );
    }

    const isPositive = isInverted ? diff < 0 : diff > 0;
    const isNegative = isInverted ? diff > 0 : diff < 0;

    if (isPositive && customPositiveColor) {
        style.color = customPositiveColor;
    }

    if (isNegative && customNegativeColor) {
        style.color = customNegativeColor;
    }

    if (isNotActual && customNotNumberColor) {
        style.color = customNotNumberColor;
    }

    return (
        <span
            className={cs(
                cx('margin', {
                    'margin--positive': isPositive,
                    'margin--negative': isNegative,
                    'margin--not-actual': isNotActual,
                }),
                className,
            )}
            style={style}
        >
            {children}
        </span>
    );
});

export default ColoredDiff;
