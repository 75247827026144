import React from 'react';
import classNames from 'classnames/bind';

import styles from './ActivateAssetForm.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, RateSourceEnum, rateSourceSet } from './constants';
import validateForm from './validate-form';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import FormikField from 'common/components/forms/FormikField/FormikField';
import ModalDialogActions from 'common/components/Modal/ModalDialogActions/ModalDialogActions';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { CurrencyEnum, UnitTypeEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { prepareActivateVehiclesRequest } from './prepare-activate-vehicles-request';
import { AssetDataT } from 'common/layouts/dialogs/ActivateAssetDialogModal/models';
import Money from 'common/components/Money/Money';
import { checkIsDefaultCompanyId } from 'common/utils';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';

import RateSourceOption from 'common/layouts/dialogs/ActivateAssetDialogModal/ActivateAssetForm/RateSourceOption/RateSourceOption';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import { logWarning } from 'common/utils/logger';
import { ApiActivateVehiclesRequestT } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type PropsT = {
    assets: Array<AssetDataT>;
    companyId: CompanyIdT;
    onCancel: () => void;
    onSubmit: (activateVehiclesRequest: ApiActivateVehiclesRequestT) => void;
    requestStatus: RequestStatusT;
};

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.source]: RateSourceEnum.current,
    [FieldsEnum.rate]: '',
};

const TEST_SELECTOR = 'activate-asset-form';

type RateSourceOptionT = {
    text: string;
    label: React.ReactNode;
    labelFallback?: React.ReactNode;
    value: RateSourceEnum | CarrierContractIdT;
};

const T_MAP = {
    oneRatesOptionLabel: 'common:assets.activation.rates.one-current-rate',
    manyRatesOptionLabel: 'common:assets.activation.rates.many-current-rates',
    newRateOptionLabel: 'common:assets.activation.rates.new-rate',
};

const ActivateAssetForm: React.FC<PropsT> = (props) => {
    const { onCancel, onSubmit, requestStatus, assets, companyId } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [isLoadingContract, setIsLoadingContract] = React.useState(false);
    const [contractOptions, setContractOptions] = React.useState<Array<RateSourceOptionT>>([]);

    React.useEffect(() => {
        (async () => {
            if (!companyId) {
                return;
            }

            setIsLoadingContract(true);
            const isPartner = !checkIsDefaultCompanyId(companyId);
            if (isPartner) {
                const brokerResponse = await brokerTranziitApi.fetchCarrierApprovedContracts(companyId);

                setContractOptions(
                    brokerResponse?.[1]?.map((apiContract) => {
                        return {
                            label: apiContract.name,
                            labelFallback: apiContract.id,
                            value: apiContract.id,
                            text: apiContract.name || apiContract.id,
                        };
                    }) || [],
                );
            } else {
                const carrierResponse = await carrierTranziitApi.fetchCarrierApprovedContracts();

                setContractOptions(
                    carrierResponse?.[1]?.map((apiContract) => {
                        return {
                            label: apiContract.name,
                            labelFallback: apiContract.id,
                            value: apiContract.id,
                            text: apiContract.name || apiContract.id,
                        };
                    }) || [],
                );
            }

            setIsLoadingContract(false);
        })();
    }, [companyId]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            ...INITIAL_VALUES,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [assets]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const activateVehiclesRequest = prepareActivateVehiclesRequest(assets, values);
            if (activateVehiclesRequest) {
                onSubmit(activateVehiclesRequest);
            } else {
                logWarning('Empty activateVehiclesRequest!');
            }

            formikHelpers.setTouched({});
        },
    });

    const rateSourceOptions = React.useMemo(() => {
        let currentRatesOptionLabel;
        if (assets.length === 1) {
            currentRatesOptionLabel = (
                <Trans
                    i18nKey={T_MAP.oneRatesOptionLabel}
                    components={{
                        rate: <Money amount={assets[0]?.ratePerKm} currency={CurrencyEnum.EUR} />,
                    }}
                />
            );
        } else {
            currentRatesOptionLabel = t(T_MAP.manyRatesOptionLabel);
        }

        return [
            {
                label: currentRatesOptionLabel,
                text: '*',
                value: RateSourceEnum.current,
            },
            {
                label: t(T_MAP.newRateOptionLabel),
                text: '*',
                value: RateSourceEnum.new,
            },
            ...contractOptions,
        ];
    }, [assets, contractOptions, t]);

    const selectedSource = formik.values[FieldsEnum.source];

    const [query, setQuery] = React.useState<string>('');

    const renderOption = (option: RateSourceOptionT | undefined, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return (
            <RateSourceOption
                isCurrentContract={initialValues[FieldsEnum.source] === formik.values[FieldsEnum.source]}
                isContract={!!option.value && !rateSourceSet.has(option.value)}
                label={option.label}
                labelFallback={option.labelFallback}
            />
        );
    };

    const filteredOptions = React.useMemo(() => {
        const preparedQuery = query.toLowerCase().trim();
        if (!preparedQuery) {
            return rateSourceOptions;
        }

        return rateSourceOptions.filter((option) => {
            if (option.text === '*') {
                return true;
            }

            return option.text.toLowerCase().includes(preparedQuery);
        });
    }, [rateSourceOptions, query]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FieldGroup spaces={1}>
                    <FormikField
                        name={FieldsEnum.source}
                        className={cx('field', 'field--source')}
                        label={t('common:assets.activation.fields.source.label')}
                        error={formik.errors[FieldsEnum.source]}
                        meta={formik.getFieldMeta(FieldsEnum.source)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownSearchInput<RateSourceOptionT, RateSourceOptionT['value']>
                                selectedValue={formik.values[FieldsEnum.source]}
                                options={filteredOptions}
                                onSelect={props.onChange}
                                renderOption={renderOption}
                                renderTrigger={renderOption}
                                getOptionValue={(option) => option.value}
                                overlayClassName={cx('overlay')}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                onChangeQuery={setQuery}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                isDisabled={isLoadingContract}
                                isLoading={isLoadingContract}
                                renderRightIcon={({ isLoading }) => {
                                    return isLoading ? (
                                        <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} />
                                    ) : null;
                                }}
                            />
                        )}
                    </FormikField>
                    {selectedSource === RateSourceEnum.new && (
                        <FormikField
                            className={cx('field', 'field--rate')}
                            name={FieldsEnum.rate}
                            error={formik.errors[FieldsEnum.rate]}
                            meta={formik.getFieldMeta(FieldsEnum.rate)}
                            label={t('common:assets.activation.fields.rate.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <NumberInput
                                    name={FieldsEnum.rate}
                                    unitType={UnitTypeEnum.euroAbbreviation}
                                    value={formik.values[FieldsEnum.rate]}
                                    placeholder={t('common:assets.activation.fields.rate.placeholder')}
                                    step={1}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                />
                            )}
                        </FormikField>
                    )}
                </FieldGroup>
            </div>
            <ModalDialogActions
                actions={[
                    {
                        children: t('common:error-modal.actions.cancel'),
                        theme: ButtonThemeEnum.secondary,
                        isDisabled: requestStatus.loading,
                        testSelector: `${TEST_SELECTOR}_cancel`,
                        onClick: () => {
                            onCancel();
                        },
                    },
                    {
                        children: t('common:error-modal.actions.activate'),
                        theme: ButtonThemeEnum.primary,
                        isDisabled: requestStatus.loading,
                        isLoading: requestStatus.loading,
                        testSelector: `${TEST_SELECTOR}_ok`,
                        type: 'submit',
                    },
                ]}
            />
        </form>
    );
};

export default ActivateAssetForm;
