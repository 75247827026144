import React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import { TruckStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import { TRUCK_STATUS_T_MAP } from 'common/components/status/TruckStatus/TruckStatus';
import DropdownMultipleInput, {
    RenderTriggerPropsT,
} from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownBaseButtonTrigger from 'design-system/components/dropdowns/base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import TruckStatusPill from 'common/components/status-pill/TruckStatusPill/TruckStatusPill';
import DropdownMultipleInputCountLabel from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInputCountLabel/DropdownMultipleInputCountLabel';

type OptionValueT = TruckStatusEnum | null;

type PropsT = {
    placeholder?: string;
    value: Array<OptionValueT>;
    isDisabled?: boolean;
    onChange: (value: Array<OptionValueT>) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
    overlayPosition?: DropdownOverlayPositionEnum;
    excludeStatuses?: Array<TruckStatusEnum>;
};

type TruckStatusDropdownOptionT = {
    value: OptionValueT;
    label: React.ReactNode;
};

const TruckStatusMultipleDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
        overlayPosition,
        excludeStatuses,
    } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): TruckStatusDropdownOptionT[] => {
        return [TruckStatusEnum.free, TruckStatusEnum.inTransit, TruckStatusEnum.archived, TruckStatusEnum.unavailable]
            .filter((status) => {
                if (excludeStatuses) {
                    return !excludeStatuses.includes(status);
                }

                return true;
            })
            .map((status: TruckStatusEnum): TruckStatusDropdownOptionT => {
                return {
                    label: t(TRUCK_STATUS_T_MAP[status]),
                    value: status,
                };
            });
    }, [t]);

    const renderTrigger = (
        props: RenderTriggerPropsT,
        options: Array<TruckStatusDropdownOptionT>,
        placeholder: string | undefined,
    ): React.ReactNode => {
        if (!options?.length) {
            return (
                <DropdownBaseButtonTrigger {...props} isShowClearControl={false}>
                    {placeholder || ''}
                </DropdownBaseButtonTrigger>
            );
        }

        if (options.length === 1) {
            const firstOption = options?.[0];

            return <DropdownBaseButtonTrigger {...props}>{firstOption?.label}</DropdownBaseButtonTrigger>;
        }

        return (
            <DropdownBaseButtonTrigger {...props}>
                <DropdownMultipleInputCountLabel count={options?.length} />
            </DropdownBaseButtonTrigger>
        );
    };

    const renderOption = (option: TruckStatusDropdownOptionT | null | undefined): React.ReactNode => {
        if (!option) {
            return null;
        }

        return (
            <DropdownControlOptionLabel
                withoutPaddings
                label={<TruckStatusPill status={option.value} isSymmetrical />}
            />
        );
    };

    const handleSelect = (value: Array<OptionValueT>): void => {
        onChange(value);
    };

    const getOptionValue = (option: TruckStatusDropdownOptionT): OptionValueT => option.value;

    return (
        <DropdownMultipleInput<TruckStatusDropdownOptionT, OptionValueT>
            selectedValues={value}
            placeholder={placeholder}
            options={options}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            testSelector="truck-statuses"
            onReset={
                hasClearControl
                    ? () => {
                          onChange([]);
                      }
                    : undefined
            }
        />
    );
});

export default TruckStatusMultipleDropdown;
