import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestDetails.scss';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import { useSelector } from 'react-redux';
import { selectSpotRequestDetails } from 'carrier/store/spot-request-details/selectors';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import LayoverIcon from 'common/icons/LayoverIcon';
import RouteIcon from 'common/icons/RouteIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import { formatDate } from 'common/utils/time';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import EmissionClassesFormatter from 'design-system/components/InfoTable/formatters/EmissionClassesFormatter/EmissionClassesFormatter';
import { convertToKm } from 'common/utils/distance';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { urlFactory } from 'carrier/utils/urls';
import { BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';
import Bids from 'carrier/components/BaseSpotRequestDetailsSidebarContent/Bids/Bids';
import { getActiveBid } from 'carrier/store/spot-request-details/utils/get-active-bid';
import AsyncAdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AsyncAdditionalServiceFormatter/AsyncAdditionalServiceFormatter';
import NumberIcon from 'common/icons/NumberIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import { SpotStopT } from 'carrier/store/spot-request-details/models';
import { StopTypeEnum } from 'common/utils/api/models';
import { isNonNil } from 'common/utils';
import isNumber from 'lodash/isNumber';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import Card from 'design-system/components/Card/Card';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';

type PropsT = {
    id: SpotRequestIdT;
    onRejectBid: (bidId: BidIdT) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
};

const cx = classNames.bind(styles);

const PLACED_DATE_FORMAT = 'DD MMM YYYY, HH:mm';
const EXPIRES_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

const SpotRequestDetails: React.FC<PropsT> = React.memo((props) => {
    const { id, onRejectBid, onOpenUserDetails } = props;

    const { details } = useSelector(selectSpotRequestDetails(id));

    const { t } = useTranslation();

    const bids = details?.bids || [];
    const activeBid = getActiveBid(bids);

    const referencesInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        const tableRows: Array<InfoTableRowT> = [];

        const shouldShowTransportOrder = activeBid?.status === BidStatusEnum.won;
        const hasTransportOrder = details?.transportationOrderId;
        if (shouldShowTransportOrder && hasTransportOrder && details?.transportationOrderId) {
            const transportOrderLink = urlFactory.transportOrdersDetails(details?.transportationOrderId || '');

            tableRows.push({
                icon: null,
                name: t('spot-request-details.details.transport-order'),
                value: (
                    <Link
                        key={details.transportationOrderId}
                        className={cx('link')}
                        to={transportOrderLink}
                        theme={LinkThemeEnum.boldBrandDark}
                    >
                        {details.transportationOrderNumber}
                    </Link>
                ),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'transport-order',
            });
        }

        return tableRows;
    }, [t, details]);

    const spotInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            {
                icon: (
                    <CalendarIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                name: t('spot-request-details.details.created'),
                value: formatDate(PLACED_DATE_FORMAT, details?.placedOn),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'created',
            },
            {
                icon: <TimeWindowIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.expiration'),
                value: formatDate(EXPIRES_DATE_FORMAT, details?.expiresAt),
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: 'expiration',
            },
        ];
    }, [t, details]);

    const additionalServicesDetails = React.useMemo(
        (): Array<InfoTableRowT> => [
            {
                icon: null,
                name: t('common:order-details.columns.additional-services-cost'),
                testSelector: 'additional-services-cost',
                rows: (details?.additionalServices || []).map((additionalService): InfoTableRowT => {
                    return {
                        icon: null,
                        name: '',
                        value: <AsyncAdditionalServiceFormatter type={additionalService} />,
                        testSelector: additionalService,
                    };
                }),
            },
        ],
        [details],
    );

    const shipmentInfoTableRows = React.useMemo((): Array<InfoTableRowT> => {
        return [
            details?.layoverHours
                ? {
                      icon: (
                          <LayoverIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                      ),
                      name: t('spot-request-details.details.layover'),
                      value: <UnitTypeCount type={UnitTypeEnum.hoursAbbreviation} count={details?.layoverHours} />,
                      isBoldValue: true,
                      testSelector: 'layover',
                      hasBottomBorder: true,
                  }
                : null,
            {
                icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.distance'),
                value: (
                    <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(details?.distance)} />
                ),
                rightNode: details?.teamDrive ? <TeamDrivePill /> : null,
                isBoldValue: true,
                testSelector: 'distance',
                hasBottomBorder: true,
            },
            {
                icon: (
                    <TrailerIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('spot-request-details.details.trailer-type'),
                value: <SimpleTrailerTypeFormatter trailerDictType={details?.trailerInfo} />,
                isBoldValue: true,
                testSelector: 'trailer',
                hasBottomBorder: true,
            },
            {
                icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('spot-request-details.details.emission'),
                value: details?.emissionClass ? (
                    <EmissionClassesFormatter emissionClasses={[details?.emissionClass]} />
                ) : null,
                isBoldValue: true,
                testSelector: 'emission',
                hasBottomBorder: true,
            },
        ].filter(isNonNil);
    }, [t, details]);

    const missingCertificates = details?.missingCertificates || [];

    const renderStop = (stop: SpotStopT, index: number) => {
        const stopNumber = index + 1;

        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <NumberIcon
                        number={stopNumber}
                        fillColor={
                            stop?.type === StopTypeEnum.driveThrough
                                ? StyleGuideColorsEnum.gray
                                : StyleGuideColorsEnum.charcoal
                        }
                    />
                ),
                name: t('spot-request-details.details.waypoint', {
                    number: stopNumber,
                }),
                value: <LocationLabel format="s1_s2_zip_city_country" location={stop?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
        ];

        return (
            <InfoTable
                key={index}
                shouldRenderIcons
                className={cx('table')}
                rows={rows}
                testSelector={`waypoint-${index}`}
            />
        );
    };

    const stops = details?.stops || [];

    const firstStop = stops[0];
    const lastStop = stops[stops.length - 1];

    return (
        <>
            <div className={cx('container')}>
                <OrderRouteLocations
                    className={cx('route')}
                    origin={firstStop?.address}
                    pickupDockingHoursFrom={firstStop?.timeSlotFrom}
                    pickupDockingHoursTo={firstStop?.timeSlotTo}
                    destination={lastStop?.address}
                    dropOffDockingHoursFrom={lastStop?.timeSlotFrom}
                    dropOffDockingHoursTo={lastStop?.timeSlotTo}
                />
                <ExcludedCountries
                    className={cx('excluded-countries')}
                    titleNode={t('spot-request-details.excluded-countries.title')}
                    countryCodes={details?.prohibitedCountries || []}
                    tooltipNode={t('spot-request-details.excluded-countries.tooltip')}
                />
                <InfoTable
                    shouldRenderIcons
                    className={cx('table')}
                    rows={referencesInfoTableRows}
                    testSelector="references"
                />
                <InfoTable
                    shouldRenderIcons
                    className={cx('table')}
                    rows={spotInfoTableRows}
                    testSelector="entries-details"
                />
                <InfoTable shouldRenderIcons rows={shipmentInfoTableRows} testSelector="shipment-details" />
                <InfoTable
                    shouldRenderIcons
                    className={cx('table')}
                    rows={additionalServicesDetails}
                    testSelector="additional-services"
                />
                {!!stops?.length && (
                    <Card
                        titleNode={t('spot-request-details.cards.route')}
                        rightNode={
                            isNumber(stops?.length) ? (
                                <PillLabel theme={PillLabelThemeEnum.slate} isSymmetrical>
                                    {t('spot-request-details.waypoint-counts', {
                                        number: stops?.length,
                                    })}
                                </PillLabel>
                            ) : null
                        }
                        className={cx('route-card')}
                        hasHeaderBottomBorder
                    >
                        <div className={cx('content')}>
                            {stops.map((stop, index) => {
                                return renderStop(stop, index);
                            })}
                        </div>
                    </Card>
                )}
                <div className={cx('bids')}>
                    <Bids
                        activeBid={activeBid}
                        bids={bids}
                        onOpenUserDetails={onOpenUserDetails}
                        onRejectBid={onRejectBid}
                        missingCertificates={missingCertificates}
                    />
                </div>
            </div>
        </>
    );
});

export default SpotRequestDetails;
