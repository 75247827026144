import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonPropsT } from 'common/components/Button/Button';
import { MS_IN_SEC } from 'common/utils/time';
import { copyToClipboard } from 'common/utils/clipboard';

export type PropsT = Omit<ButtonPropsT, 'children' | 'onClick'> & {
    textForClipboard: string;
    timeoutMs?: number;
};

const defaultTimeoutMs = 2 * MS_IN_SEC;

const CopyTextButton: React.FC<PropsT> = React.memo((props) => {
    const { textForClipboard, timeoutMs, ...restProps } = props;

    const copyTimeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);

    const [isCopied, setIsCopied] = React.useState<boolean>(false);

    const { t } = useTranslation();

    return (
        <Button
            {...restProps}
            onClick={() => {
                if (copyTimeoutIdRef.current) {
                    clearTimeout(copyTimeoutIdRef.current);
                    copyTimeoutIdRef.current = null;
                }

                copyTimeoutIdRef.current = setTimeout(() => {
                    setIsCopied(false);
                }, timeoutMs || defaultTimeoutMs);

                setIsCopied(true);

                copyToClipboard(textForClipboard);
            }}
        >
            {isCopied ? t('common:actions.copied') : t('common:actions.copy')}
        </Button>
    );
});

export default CopyTextButton;
