import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SpotRequestRoute.scss';
import { useSelector } from 'react-redux';
import { selectSpotRequestDetails } from 'carrier/store/spot-request-details/selectors';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { isNonNil } from 'common/utils';
import { useMemo } from 'react';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import MultiPointOrderRouteMap, {
    MultiPointOrderRouteMapPropsT,
} from 'common/components/MultiPointOrderRouteMap/MultiPointOrderRouteMap';
import { StopTypeEnum } from 'common/utils/api/models';

type PropsT = {
    id: SpotRequestIdT;
};

const cx = classNames.bind(styles);

const SpotRequestRoute: React.FC<PropsT> = React.memo((props) => {
    const { id } = props;

    const { details } = useSelector(selectSpotRequestDetails(id));

    const polylineIds = useMemo(() => {
        return [details?.polylineId].filter(isNonNil);
    }, [details?.polylineId]);

    useRouteGeometry(polylineIds);

    const routingGeometryState = useSelector(selectRoutingGeometryState(details?.polylineId));

    const mapPoints = useMemo((): MultiPointOrderRouteMapPropsT['points'] => {
        const stops = details?.stops || [];

        return stops?.map((stop, index) => {
            return {
                longitude: stop.address?.longitude,
                latitude: stop.address?.latitude,
                driveThru: stop.type === StopTypeEnum.driveThrough,
                index,
            };
        });
    }, [details]);

    return (
        <MultiPointOrderRouteMap
            key={details?.id}
            points={mapPoints}
            route={routingGeometryState.data}
            routeRequest={routingGeometryState.requestStatus}
        />
    );
});

export default SpotRequestRoute;
