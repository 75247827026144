import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TelematicUpdateDateCell.scss';

import isNil from 'lodash/isNil';
import HumanReadableFullDateCell from '../HumanReadableFullDateCell/HumanReadableFullDateCell';

type PropsT = {
    telematicUpdatedDate: string | null | undefined;
    placeholder: string;
};

const cx = classNames.bind(styles);

const TelematicUpdateDateCell: React.FC<PropsT> = React.memo((props) => {
    const { telematicUpdatedDate, placeholder } = props;

    if (isNil(telematicUpdatedDate)) {
        return <div className={cx('placeholder')}>{placeholder}</div>;
    }

    return <HumanReadableFullDateCell isBold date={telematicUpdatedDate} />;
});

export default TelematicUpdateDateCell;
