import { RequestStatusT } from 'common/utils/request-status';
import { TrackPointT } from 'common/store/asset-track/models';

export const FETCH_TRACK_BY_ORDER = 'common/asset-track/FETCH_TRACK_BY_ORDER';
export const FETCH_TRACK_BY_TRANSPORT_ORDERS = 'common/asset-track/FETCH_TRACK_BY_TRANSPORT_ORDERS';
export const FETCH_TRACK_REQUEST_BEGIN = 'common/asset-track/FETCH_TRACK_REQUEST_BEGIN';
export const FETCH_TRACK_REQUEST_ERROR = 'common/asset-track/FETCH_TRACK_REQUEST_ERROR';
export const FETCH_TRACK_REQUEST_SUCCESS = 'common/asset-track/FETCH_TRACK_REQUEST_SUCCESS';

export type FetchTrackByOrderActionT = {
    type: typeof FETCH_TRACK_BY_ORDER;
    orderId: OrderIdT;
};

export type FetchTrackByTransportOrderActionT = {
    type: typeof FETCH_TRACK_BY_TRANSPORT_ORDERS;
    transportOrderIds: Array<TransportOrderIdT>;
};

export type FetchTrackRequestBeginActionT = {
    type: typeof FETCH_TRACK_REQUEST_BEGIN;
    hash: HashT;
};

export type FetchTrackRequestErrorActionT = {
    type: typeof FETCH_TRACK_REQUEST_ERROR;
    error: Error;
    hash: HashT;
};

export type FetchTrackRequestSuccessActionT = {
    type: typeof FETCH_TRACK_REQUEST_SUCCESS;
    points: Array<TrackPointT> | null;
    hash: HashT;
};

export type AssetTrackActionT =
    | FetchTrackRequestBeginActionT
    | FetchTrackRequestErrorActionT
    | FetchTrackRequestSuccessActionT;

export type AssetTrackItemStateT = {
    points: Array<TrackPointT>;
    requestStatus: RequestStatusT;
};

export type AssetTrackStateT = Record<HashT, AssetTrackItemStateT>;
