import React, { memo } from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './DatePicker.scss';

import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';

import Calendar, {
    CalendarDateRangeValuePropsT,
    CalendarDateValuePropsT,
} from 'design-system/components/Calendar/Calendar';
import DateInputTrigger, {
    InputDateValuePropsT,
    InputRangeValuePropsT,
} from 'design-system/components/date-pickers/internals/DateInputTrigger/DateInputTrigger';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import DatePickerOverlay, { DatePickerOverlayPositionEnum } from '../internals/DatePickerOverlay/DatePickerOverlay';
import useTestSelector from 'common/utils/hooks/useTestSelector';
import { getISOFutureDate, getISOPastDate } from 'common/utils/time';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type BasePropsT = {
    name?: string;
    placeholder?: string;
    isDisabled?: boolean;
    minDate?: string;
    maxDate?: string;
    overlayPosition?: DatePickerOverlayPositionEnum;
    hasError?: boolean;
    hasWarning?: boolean;
    hasYearMonthForm?: boolean;
    autoComplete?: string;
    testSelectorPrefix?: string;
    hasChanges?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    inputClassName?: string;
    hasClearControl?: boolean;
    className?: string;
};

export type DatePickerPropsT = (BasePropsT & CalendarDateValuePropsT) | (BasePropsT & CalendarDateRangeValuePropsT);

const ENTER_KEY = 'Enter';

type RangePresetT = {
    minDate?: BasePropsT['minDate'];
    maxDate?: BasePropsT['maxDate'];
};

export const datePicketRangePresets = {
    get documentValidTill(): RangePresetT {
        return {
            maxDate: getISOFutureDate(10, 'year'),
            minDate: getISOPastDate(0, 'year'),
        };
    },

    get anyFutureDate(): RangePresetT {
        return {
            minDate: getISOPastDate(0, 'year'),
        };
    },

    get birthday(): RangePresetT {
        return {
            maxDate: getISOPastDate(18, 'year'),
            minDate: getISOPastDate(90, 'year'),
        };
    },
};

/* eslint-disable react/destructuring-assignment */
const DatePicker: React.FC<DatePickerPropsT> = memo((props) => {
    const {
        name,
        onFocus,
        onBlur,
        isDisabled,
        minDate,
        maxDate,
        overlayPosition,
        hasError,
        hasWarning,
        hasYearMonthForm,
        hasChanges,
        autoComplete,
        testSelectorPrefix,
        inputClassName,
        hasClearControl,
        className,
    } = props;

    const { t } = useTranslation();

    const defaultPlaceholder = props.isRange
        ? t('common:common-components.date-range-picker.placeholder')
        : t('common:common-components.date-picker.placeholder');
    const placeholder = props.placeholder || defaultPlaceholder;

    const [isOpen, toggleOpen] = React.useState(false);

    const handleCalendarRangeChange: CalendarDateRangeValuePropsT['onChange'] = (value, selectedDate) => {
        if (props.isRange) {
            props.onChange(value, selectedDate);
        }
    };

    const handleCalendarDateChange: CalendarDateValuePropsT['onChange'] = (value, selectedDate) => {
        toggleOpen(false);

        if (!props.isRange) {
            props.onChange(value, selectedDate);
        }

        if (onBlur) {
            onBlur();
        }
    };

    const handleOpen = () => {
        if (isDisabled) {
            return;
        }

        toggleOpen(true);
    };

    const handleOuterEvent = () => {
        toggleOpen(false);

        if (onBlur) {
            onBlur();
        }
    };

    const handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER_KEY) {
            toggleOpen(false);

            if (onBlur) {
                onBlur();
            }
        }
    };

    const testSelector = useTestSelector(testSelectorPrefix, 'date-picker');

    let calendarValueProps: CalendarDateValuePropsT | CalendarDateRangeValuePropsT;
    if (props.isRange) {
        calendarValueProps = {
            isRange: true,
            value: props.value,
            initialValue: props.initialValue,
            onChange: handleCalendarRangeChange,
            focusInitialDate: props.value?.from,
        };
    } else {
        calendarValueProps = {
            isRange: false,
            value: props.value,
            initialValue: props.initialValue,
            onChange: handleCalendarDateChange,
            focusInitialDate: props.value,
        };
    }

    let inputDateValueProps: InputDateValuePropsT | InputRangeValuePropsT;
    if (props.isRange) {
        inputDateValueProps = {
            isRange: true,
            value: props.value,
            onChange: handleCalendarRangeChange,
        };
    } else {
        inputDateValueProps = {
            isRange: false,
            value: props.value,
            onChange: handleCalendarDateChange,
        };
    }

    return (
        <div className={cs(cx('dropdown'), className)} data-test-selector={testSelector}>
            <OutsideClickHandler isDisabled={!isOpen} onOutsideClick={handleOuterEvent}>
                <div className={cx('input')}>
                    <div className={cx('trigger')} onClick={handleOpen}>
                        <DateInputTrigger
                            {...inputDateValueProps}
                            className={inputClassName}
                            name={name || 'datepicker'}
                            placeholder={placeholder}
                            hasError={hasError}
                            onKeyUp={handleInputKeyUp}
                            onFocus={onFocus}
                            hasWarning={hasWarning}
                            isDisabled={isDisabled}
                            isForcedFocused={isOpen}
                            hasChanges={hasChanges}
                            autoComplete={autoComplete}
                            hasClearControl={hasClearControl}
                            renderLeftIcon={(iconMeta) => (
                                <CalendarIcon
                                    size={DEFAULT_ICON_SIZE}
                                    fillColor={
                                        iconMeta?.hasValue || iconMeta?.isFocused
                                            ? StyleGuideColorsEnum.brandAccent
                                            : StyleGuideColorsEnum.white
                                    }
                                    strokeColor={
                                        iconMeta?.hasValue || iconMeta?.isFocused
                                            ? StyleGuideColorsEnum.brandDark
                                            : StyleGuideColorsEnum.light
                                    }
                                />
                            )}
                        />
                    </div>
                    {isOpen && (
                        <DatePickerOverlay position={overlayPosition}>
                            <Calendar
                                minDate={minDate}
                                maxDate={maxDate}
                                hasYearMonthForm={hasYearMonthForm}
                                {...calendarValueProps}
                            />
                        </DatePickerOverlay>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
});

export default DatePicker;
export { DatePickerOverlayPositionEnum };
