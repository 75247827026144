import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';
import isNumber from 'lodash/isNumber';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    fillOpacity?: number;
    strokeColor: StyleGuideColorsEnum;
};

const TimeWindowIcon: React.FC<PropsT> = (props) => {
    const { fillColor, fillOpacity, strokeColor, ...restProps } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                    fill={fillColor}
                    d="M10 3.325a6.665 6.665 0 00-6.667 6.667c0 3.683 2.984 6.666 6.667 6.666s6.667-2.983 6.667-6.666A6.665 6.665 0 0010 3.325zm3.542 10.2a5.01 5.01 0 01-7.075 0L10 9.992v-5c1.283 0 2.558.491 3.533 1.466a4.992 4.992 0 01.009 7.067z"
                    opacity={isNumber(fillOpacity) ? fillOpacity : '0.3'}
                />
                <path
                    fill={strokeColor}
                    d="M13.533 6.458A4.978 4.978 0 0010 4.992v5l-3.533 3.533a5.01 5.01 0 007.075 0 4.992 4.992 0 00-.009-7.067zM10 1.658c-4.6 0-8.333 3.734-8.333 8.334S5.4 18.325 10 18.325s8.333-3.733 8.333-8.333S14.6 1.658 10 1.658zm0 15a6.665 6.665 0 01-6.667-6.666c0-3.684 2.984-6.667 6.667-6.667s6.667 2.983 6.667 6.667A6.665 6.665 0 0110 16.658z"
                />
            </g>
        </svg>
    );
};

TimeWindowIcon.displayName = 'TimeWindowIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default TimeWindowIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getTimeWindowIconProps = (preset: 'default' | 'selected'): Pick<PropsT, 'fillColor' | 'strokeColor'> => {
    switch (preset) {
        case 'selected': {
            return {
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        default: {
            return {
                fillColor: StyleGuideColorsEnum.light,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
    }
};
