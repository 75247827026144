import React from 'react';
import classNames from 'classnames/bind';

import styles from './ChangeRateOrContractAssetForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, UpdateWayEnum } from './constants';
import validateForm from './validate-form';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import FormikField from 'common/components/forms/FormikField/FormikField';
import ModalDialogActions, { ModalDialogActionT } from 'common/components/Modal/ModalDialogActions/ModalDialogActions';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { UnitTypeEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import { prepareChangeContractRequest, prepareChangeRateRequest } from './prepare-change-rate-request';
import { AssetDataT } from '../models';
import { checkIsDefaultCompanyId, isNonNil } from 'common/utils';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';

import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import { logWarning } from 'common/utils/logger';
import { ApiChangeContractVehiclesRequestT, ApiChangeRateVehiclesRequestT } from 'common/utils/api/models';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';

const cx = classNames.bind(styles);

type PropsT = {
    assets: Array<AssetDataT>;
    companyId: CompanyIdT;
    onCancel: () => void;
    onChangeRate: (changeRateRequest: ApiChangeRateVehiclesRequestT) => void;
    onChangeContract: (changeContractRequest: ApiChangeContractVehiclesRequestT, contractName: string) => void;
    requestStatus: RequestStatusT;
};

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.updateWay]: null,
    [FieldsEnum.rate]: '',
    [FieldsEnum.contractId]: null,
};

const TEST_SELECTOR = 'change-rate-or-contract-asset-form';

type UpdateWayOptionT = {
    label: React.ReactNode;
    value: UpdateWayEnum | null;
};

type ContractOptionT = {
    text: string;
    label: React.ReactNode;
    value: CarrierContractIdT | null;
};

const ChangeRateOrContractAssetForm: React.FC<PropsT> = (props) => {
    const { onCancel, onChangeContract, onChangeRate, requestStatus, assets, companyId } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [isLoadingContract, setIsLoadingContract] = React.useState(false);
    const [contractOptions, setContractOptions] = React.useState<Array<ContractOptionT>>([]);

    React.useEffect(() => {
        (async () => {
            if (!companyId) {
                return;
            }

            setIsLoadingContract(true);
            const isPartner = !checkIsDefaultCompanyId(companyId);
            if (isPartner) {
                const brokerResponse = await brokerTranziitApi.fetchCarrierApprovedContracts(companyId);

                setContractOptions(
                    brokerResponse?.[1]?.map((apiContract) => {
                        return {
                            label: apiContract.name,
                            labelFallback: apiContract.id,
                            value: apiContract.id,
                            text: apiContract.name || apiContract.id,
                        };
                    }) || [],
                );
            } else {
                const carrierResponse = await carrierTranziitApi.fetchCarrierApprovedContracts();

                setContractOptions(
                    carrierResponse?.[1]?.map((apiContract) => {
                        return {
                            label: apiContract.name,
                            labelFallback: apiContract.id,
                            value: apiContract.id,
                            text: apiContract.name || apiContract.id,
                        };
                    }) || [],
                );
            }

            setIsLoadingContract(false);
        })();
    }, [companyId]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            ...INITIAL_VALUES,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [assets]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const updateWay = values[FieldsEnum.updateWay];
            if (updateWay === UpdateWayEnum.newRate) {
                const changeRateRequest = prepareChangeRateRequest(assets, values);

                if (changeRateRequest) {
                    onChangeRate(changeRateRequest);
                } else {
                    logWarning('Empty activateVehiclesRequest!');
                }
            }

            if (updateWay === UpdateWayEnum.unassignContract || updateWay === UpdateWayEnum.assignContract) {
                const changeContractRequest = prepareChangeContractRequest(assets, values);

                if (changeContractRequest) {
                    const selectedContractOption = contractOptions.find((contractOption) => {
                        return contractOption.value === values[FieldsEnum.contractId];
                    });
                    const contractName = selectedContractOption?.text || '';
                    onChangeContract(changeContractRequest, contractName);
                } else {
                    logWarning('Empty activateVehiclesRequest!');
                }
            }

            formikHelpers.setTouched({});
        },
    });

    const updateWayOptions = React.useMemo((): Array<UpdateWayOptionT> => {
        return [
            {
                label: t('common:assets.change-rate-or-contract-asset.fields.way-type.options.new-rate'),
                value: UpdateWayEnum.newRate,
            },
            {
                label: t('common:assets.change-rate-or-contract-asset.fields.way-type.options.assign-contract'),
                value: UpdateWayEnum.assignContract,
            },
            {
                label: t('common:assets.change-rate-or-contract-asset.fields.way-type.options.unassign-contract'),
                value: UpdateWayEnum.unassignContract,
            },
        ];
    }, [assets, t]);

    const selectedUpdateWay = formik.values[FieldsEnum.updateWay];

    const [query, setQuery] = React.useState<string>('');

    const renderOption = (
        option: ContractOptionT | UpdateWayOptionT | undefined,
        placeholder?: string,
    ): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return option.label;
    };

    const filteredContractOptions = React.useMemo(() => {
        const preparedQuery = query.toLowerCase().trim();
        if (!preparedQuery) {
            return contractOptions;
        }

        return contractOptions.filter((option) => {
            return option.text.toLowerCase().includes(preparedQuery);
        });
    }, [contractOptions, query]);

    const actions = React.useMemo(() => {
        return [
            {
                children: t('common:error-modal.actions.cancel'),
                theme: ButtonThemeEnum.secondary,
                isDisabled: requestStatus.loading,
                testSelector: `${TEST_SELECTOR}_cancel`,
                onClick: () => {
                    onCancel();
                },
            },
            !selectedUpdateWay || selectedUpdateWay === UpdateWayEnum.newRate
                ? ({
                      children: t('common:assets.change-rate-or-contract-asset.submit.set'),
                      theme: ButtonThemeEnum.danger,
                      isDisabled: requestStatus.loading,
                      isLoading: requestStatus.loading,
                      testSelector: `${TEST_SELECTOR}_set`,
                      type: 'submit',
                  } as ModalDialogActionT)
                : null,
            selectedUpdateWay === UpdateWayEnum.assignContract
                ? ({
                      children: t('common:assets.change-rate-or-contract-asset.submit.assign'),
                      theme: ButtonThemeEnum.danger,
                      isDisabled: requestStatus.loading,
                      isLoading: requestStatus.loading,
                      testSelector: `${TEST_SELECTOR}_assign`,
                      type: 'submit',
                  } as ModalDialogActionT)
                : null,
            selectedUpdateWay === UpdateWayEnum.unassignContract
                ? ({
                      children: t('common:assets.change-rate-or-contract-asset.submit.unassign'),
                      theme: ButtonThemeEnum.danger,
                      isDisabled: requestStatus.loading,
                      isLoading: requestStatus.loading,
                      testSelector: `${TEST_SELECTOR}_unassign`,
                      type: 'submit',
                  } as ModalDialogActionT)
                : null,
        ].filter(isNonNil);
    }, [requestStatus, selectedUpdateWay, t]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FormikField
                    name={FieldsEnum.updateWay}
                    className={cx('field', 'field--source')}
                    label={t('common:assets.change-rate-or-contract-asset.fields.way-type.label')}
                    error={formik.errors[FieldsEnum.updateWay]}
                    meta={formik.getFieldMeta(FieldsEnum.updateWay)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DropdownInput<UpdateWayOptionT, UpdateWayOptionT['value']>
                            selectedValue={formik.values[FieldsEnum.updateWay]}
                            options={updateWayOptions}
                            onSelect={props.onChange}
                            renderOption={renderOption}
                            placeholder={t('common:assets.change-rate-or-contract-asset.fields.way-type.placeholder')}
                            getOptionValue={(option) => option.value}
                            overlayClassName={cx('overlay')}
                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            isDisabled={isLoadingContract}
                        />
                    )}
                </FormikField>
                {selectedUpdateWay === UpdateWayEnum.assignContract && (
                    <FormikField
                        name={FieldsEnum.contractId}
                        className={cx('field', 'field--contract')}
                        label={t('common:assets.change-rate-or-contract-asset.fields.contract.label')}
                        error={formik.errors[FieldsEnum.contractId]}
                        meta={formik.getFieldMeta(FieldsEnum.contractId)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownSearchInput<ContractOptionT, ContractOptionT['value']>
                                selectedValue={formik.values[FieldsEnum.contractId]}
                                options={filteredContractOptions}
                                onSelect={props.onChange}
                                renderOption={renderOption}
                                renderTrigger={renderOption}
                                placeholder={t(
                                    'common:assets.change-rate-or-contract-asset.fields.contract.placeholder',
                                )}
                                getOptionValue={(option) => option.value}
                                overlayClassName={cx('overlay')}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                onChangeQuery={setQuery}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                isDisabled={isLoadingContract}
                                isLoading={isLoadingContract}
                                renderRightIcon={({ isLoading }) => {
                                    return isLoading ? (
                                        <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} />
                                    ) : null;
                                }}
                            />
                        )}
                    </FormikField>
                )}
                {selectedUpdateWay === UpdateWayEnum.newRate && (
                    <FormikField
                        className={cx('field', 'field--rate')}
                        name={FieldsEnum.rate}
                        error={formik.errors[FieldsEnum.rate]}
                        meta={formik.getFieldMeta(FieldsEnum.rate)}
                        label={t('common:assets.change-rate-or-contract-asset.fields.rate.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <NumberInput
                                name={FieldsEnum.rate}
                                unitType={UnitTypeEnum.euroAbbreviation}
                                value={formik.values[FieldsEnum.rate]}
                                placeholder={t('common:assets.change-rate-or-contract-asset.fields.rate.placeholder')}
                                step={1}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                )}
            </div>
            <ModalDialogActions actions={actions} />
        </form>
    );
};

export default ChangeRateOrContractAssetForm;
