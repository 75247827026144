import React from 'react';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { EmissionClassEnum } from 'common/constants';
import EmissionIcon, { EmissionIconProps } from 'common/icons/EmissionIcon';
import { EMISSION_CLASS_I18N_MAP } from 'common/components/EmissionClassLabel/EmissionClassLabel';
import DropdownMultipleInput, {
    RenderTriggerPropsT,
} from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import { useTranslation } from 'react-i18next';
import DropdownBaseButtonTrigger from 'design-system/components/dropdowns/base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import DropdownMultipleInputCountLabel from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInputCountLabel/DropdownMultipleInputCountLabel';

type ValueT = EmissionClassEnum | null;

type PropsT = {
    placeholder?: string;
    values: Array<ValueT>;
    onChange: (values: Array<ValueT>) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
};

type EmissionStandardOptionT = {
    label: string;
    value: ValueT;
};

const ALL_EMISSION_STANDARDS: EmissionClassEnum[] = [EmissionClassEnum.euro5, EmissionClassEnum.euro6];

const EmissionClassMultipleDropdown: React.FC<PropsT> = React.memo((props) => {
    const { values, placeholder, onChange, hasWarning, hasError, hasChanges, onBlur, onFocus, hasClearControl } = props;

    const { t } = useTranslation();

    const options: Array<EmissionStandardOptionT> = React.useMemo(
        () =>
            ALL_EMISSION_STANDARDS.map((value) => ({
                label: t(EMISSION_CLASS_I18N_MAP[value]),
                value,
            })),
        [t],
    );

    const renderOption = (option: EmissionStandardOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return <div key={option.label}>{option.label}</div>;
    };

    const renderTrigger = (
        props: RenderTriggerPropsT,
        options: Array<EmissionStandardOptionT>,
        placeholder: string | undefined,
    ): React.ReactNode => {
        if (!options?.length) {
            return (
                <DropdownBaseButtonTrigger
                    {...props}
                    isShowClearControl={false}
                    renderLeftIcon={(iconMeta) => {
                        return <EmissionIcon {...EmissionIconProps.getControlProps(iconMeta)} />;
                    }}
                >
                    {placeholder || ''}
                </DropdownBaseButtonTrigger>
            );
        }

        if (options.length === 1) {
            const firstOption = options?.[0];

            return (
                <DropdownBaseButtonTrigger
                    {...props}
                    renderLeftIcon={(iconMeta) => {
                        return <EmissionIcon {...EmissionIconProps.getControlProps(iconMeta)} />;
                    }}
                >
                    {firstOption?.label}
                </DropdownBaseButtonTrigger>
            );
        }

        return (
            <DropdownBaseButtonTrigger
                {...props}
                renderLeftIcon={(iconMeta) => {
                    return <EmissionIcon {...EmissionIconProps.getControlProps(iconMeta)} />;
                }}
            >
                <DropdownMultipleInputCountLabel count={options?.length} />
            </DropdownBaseButtonTrigger>
        );
    };

    const handleReset = () => {
        onChange([]);
    };

    return (
        <DropdownMultipleInput<EmissionStandardOptionT, EmissionClassEnum | null>
            selectedValues={values}
            placeholder={placeholder}
            options={options}
            onSelect={onChange}
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            getOptionValue={(option: EmissionStandardOptionT) => {
                return option.value;
            }}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            onReset={hasClearControl ? handleReset : undefined}
        />
    );
});

export default EmissionClassMultipleDropdown;
