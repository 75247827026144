import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import ChangeRateOrContractAssetForm from './ChangeRateOrContractAssetForm/ChangeRateOrContractAssetForm';
import { AssetDataT } from './models';
import { ApiChangeContractVehiclesRequestT, ApiChangeRateVehiclesRequestT } from 'common/utils/api/models';
import BigWarningIcon from 'common/icons/BigWarningIcon';

export type ChangeRateOrContractAssetDialogModalDataT = {
    companyId: CompanyIdT;
    assets: Array<AssetDataT>;
};

type PropsT = {
    companyId: CompanyIdT;
    assetType: AssetTypeEnum;
    data: ChangeRateOrContractAssetDialogModalDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onChangeRate: (changeRateRequest: ApiChangeRateVehiclesRequestT) => void;
    onChangeContract: (changeContractRequest: ApiChangeContractVehiclesRequestT, contractName: string) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'change-rate-or-contract-assets';

const T_MAP: Record<
    AssetTypeEnum,
    {
        title: string;
        message: string;
    }
> = {
    [AssetTypeEnum.truck]: {
        title: 'common:trucks-page.change-rate-or-contract-assets-confirmation.title',
        message: 'common:trucks-page.change-rate-or-contract-assets-confirmation.message',
    },
    [AssetTypeEnum.trailer]: {
        title: 'common:trailers-page.change-rate-or-contract-assets-confirmation.title',
        message: 'common:trailers-page.change-rate-or-contract-assets-confirmation.message',
    },
};

const ChangeRateOrContractAssetDialogModal: React.FC<PropsT> = React.memo((props) => {
    const { companyId, data, onClose, onCancel, requestStatus, onChangeRate, onChangeContract, assetType } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const { assets } = data;

    const tSet = T_MAP[assetType];
    const message = t(tSet.message);
    const title = t(tSet.title);

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={<BigWarningIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={title}
                    message={message}
                >
                    <ChangeRateOrContractAssetForm
                        companyId={companyId}
                        assets={assets}
                        onCancel={onCancel}
                        requestStatus={requestStatus}
                        onChangeRate={onChangeRate}
                        onChangeContract={onChangeContract}
                    />
                </ModalDialog>
            </ModalContent>
        </Modal>
    );
});

export default ChangeRateOrContractAssetDialogModal;
