import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { FetchCompanyDriversPageQueryT } from 'common/store/drivers/models';
import { TabEnum } from './constants';
import { DriversSortEnum, UserStatusEnum } from 'common/utils/api/models';
import difference from 'lodash/difference';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';

const allDriverStatuses = Object.values(UserStatusEnum);

const formatSort = (sort: PageSortT<DriversSortEnum> | null): FetchCompanyDriversPageQueryT['sortBy'] | undefined => {
    switch (sort?.value) {
        case DriversSortEnum.name: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'NAME_ASC';
            }
            return 'NAME_DESC';
        }
        case DriversSortEnum.surname: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'SURNAME_ASC';
            }
            return 'SURNAME_DESC';
        }
        case DriversSortEnum.createdDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'CREATED_DATE_ASC';
            }
            return 'CREATED_DATE_DESC';
        }
        default: {
            return undefined;
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    activeTab: TabEnum | null | undefined,
    sort: PageSortT<DriversSortEnum> | null,
): FetchCompanyDriversPageQueryT => {
    let statuses: UserStatusEnum[] = [];

    if (activeTab === TabEnum.active) {
        statuses = difference(allDriverStatuses, [UserStatusEnum.archived]);
    } else {
        statuses = [UserStatusEnum.archived];
    }

    return {
        name: queryFilters[QueryFiltersKeysEnum.searchText] || queryFilters[QueryFiltersKeysEnum.name] || undefined,
        statuses,
        sortBy: formatSort(sort),
    };
};

export { prepareFetchPageQuery };
