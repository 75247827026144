import React, { useCallback } from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import keyBy from 'lodash/keyBy';
import { AsyncRequestFactoryT } from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import { checkIsDefaultCompanyId } from 'common/utils';
import isNil from 'lodash/isNil';
import UserDropdownOptionLabel from 'common/components/dropdowns/options/UserDropdownOptionLabel/UserDropdownOptionLabel';
import {
    ALWAYS_PASS_SPECIAL_VALUE,
    useAsyncFilterOptions,
} from 'design-system/components/dropdowns/DropdownSearchInput/hooks/use-async-filter-options';
import DropdownSearchMultipleInput from 'design-system/components/dropdowns/DropdownSearchMultipleInput/DropdownSearchMultipleInput';
import { useTranslation } from 'react-i18next';
import {
    checkIsSpecialOption,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { ALL_BROKER_USERS_VALUE } from 'common/components/dropdowns/UserMultipleDropdown/constants';
import classNames from 'classnames/bind';
import styles from './UserMultipleDropdown.scss';
import DropdownMultipleInputCountLabel from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInputCountLabel/DropdownMultipleInputCountLabel';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type OptionT = {
    label?: string;
    value: UserIdT | typeof ALL_BROKER_USERS_VALUE;
};

type ValueT = OptionT['value'];

export type PropsT = {
    className?: string;
    companyId: CompanyIdT;
    onSelect: (values: Array<ValueT>, labels?: Array<string>) => void;
    values: Array<ValueT>;
    initialValues: Array<ValueT>;
    initialLabels: Array<string>;
    overlayPosition?: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder?: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasChanges?: boolean;
    hasClearControl?: boolean;
    isShowAllBrokerUsersOption?: boolean;
};

const UserMultipleDropdown: React.FC<PropsT> = (props) => {
    const {
        className,
        companyId,
        values,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isDisabled,
        onBlur,
        onFocus,
        hasChanges,
        hasClearControl,
        initialLabels,
        initialValues,
        isShowAllBrokerUsersOption,
    } = props;

    const { t } = useTranslation();

    const requestFactory: AsyncRequestFactoryT<OptionT | SpecialOptionEnum> = useCallback(
        (query) => {
            let promise: ReturnType<
                typeof brokerTranziitApi.findPartnerUsersByName | typeof commonTranziitApi.findCompanyUsersByName
            >;

            const isPartner = !checkIsDefaultCompanyId(companyId);
            if (isPartner) {
                promise = brokerTranziitApi.findPartnerUsersByName(companyId, query);
            } else {
                promise = commonTranziitApi.findCompanyUsersByName(query);
            }

            return promise.then((results) => {
                const users = results?.[1] || [];

                const userOptions = users.reduce<Array<OptionT>>((acc, user) => {
                    if (user.userId) {
                        acc.push({
                            label: user.fullName,
                            value: user.userId,
                        });
                    }

                    return acc;
                }, []);

                if (isShowAllBrokerUsersOption) {
                    const userOptionsWithBroker: Array<OptionT | SpecialOptionEnum> = [
                        {
                            label: t('common:broker-name'),
                            value: ALL_BROKER_USERS_VALUE,
                        },
                        SpecialOptionEnum.separator,
                        ...userOptions,
                    ];

                    return [null, userOptionsWithBroker];
                }

                return [null, userOptions];
            });
        },
        [companyId, isShowAllBrokerUsersOption],
    );

    const getOptionText = useCallback((option: OptionT | SpecialOptionEnum) => {
        if (checkIsSpecialOption(option) || option?.value === ALL_BROKER_USERS_VALUE) {
            return ALWAYS_PASS_SPECIAL_VALUE;
        }

        return option.label || '';
    }, []);

    const initialOptions = React.useMemo(() => {
        return initialValues.map((initialValue, index): OptionT => {
            return {
                value: initialValue,
                label: initialLabels[index],
            };
        });
    }, [initialValues, initialLabels]);

    const asyncFilterOptionsRequest = useAsyncFilterOptions<OptionT | SpecialOptionEnum>({
        initialOptions,
        requestFactory,
        getOptionText,
    });

    const optionByValue = React.useMemo(() => {
        return keyBy(asyncFilterOptionsRequest.options, 'value');
    }, [asyncFilterOptionsRequest.options]);

    const renderOption = (option?: OptionT, placeholder?: string): React.ReactNode => {
        if (isNil(option)) {
            return <UserDropdownOptionLabel className={cx('option')} label={placeholder || ''} isPlaceholder />;
        }

        return (
            <UserDropdownOptionLabel
                className={cx('option')}
                isAllBrokerUsers={option?.value === ALL_BROKER_USERS_VALUE}
                label={option?.label || ''}
            />
        );
    };

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }

        asyncFilterOptionsRequest.onRefresh();
    }, [onFocus, asyncFilterOptionsRequest.onRefresh]);

    const renderTrigger = (options: Array<OptionT>, placeholder: string | undefined) => {
        if (!options?.length) {
            return <UserDropdownOptionLabel label={placeholder || ''} isPlaceholder />;
        }

        if (options.length === 1) {
            const firstOption = options?.[0];
            return (
                <UserDropdownOptionLabel
                    isAllBrokerUsers={firstOption?.value === ALL_BROKER_USERS_VALUE}
                    label={firstOption?.label || ''}
                />
            );
        }

        return <DropdownMultipleInputCountLabel count={options?.length} />;
    };

    const renderLeftIcon = () => {
        if (values?.length < 2) {
            return null;
        }

        return (
            <TeamDriveIcon
                size={DEFAULT_ICON_SIZE}
                fillColor={StyleGuideColorsEnum.brandAccent}
                strokeColor={StyleGuideColorsEnum.brandDark}
            />
        );
    };

    const handleReset = () => {
        onSelect([]);
    };

    const placeholder = props.placeholder || t('common:common-components.users-select.placeholder');

    return (
        <DropdownSearchMultipleInput<OptionT, ValueT>
            className={className}
            selectedValues={values}
            placeholder={placeholder}
            options={asyncFilterOptionsRequest.options}
            isDisabled={isDisabled}
            onSelect={(values) => {
                const labels = values.map((value) => {
                    const option = optionByValue[value as string] || null;
                    if (!option || checkIsSpecialOption(option)) {
                        return '';
                    }
                    return option?.label || '';
                });

                onSelect(values, labels);
            }}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={asyncFilterOptionsRequest.onChangeQuery}
            isLoading={asyncFilterOptionsRequest.requestStatus.loading}
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            renderLeftIcon={renderLeftIcon}
            getOptionValue={(option) => option?.value}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={handleFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onReset={hasClearControl ? handleReset : undefined}
            inputPlaceholder={t('common:common-components.dropdown-multiple-input.input-placeholder')}
        />
    );
};

export default UserMultipleDropdown;
