import React from 'react';

import classNames from 'classnames/bind';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import styles from './TruckModelMultipleDropdown.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrucksDict, selectTrucksDictRequest } from 'common/store/trucks-dict/selectors';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import { getTruckDictTypeLabel } from 'common/store/trucks-dict/utils';
import TruckIcon, { TruckIconProps } from 'common/icons/TruckIcon';
import DropdownMultipleInput, {
    RenderTriggerPropsT,
} from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import DropdownBaseButtonTrigger from 'design-system/components/dropdowns/base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import { useTranslation } from 'react-i18next';
import DropdownMultipleInputCountLabel from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInputCountLabel/DropdownMultipleInputCountLabel';

export enum TruckIconTypesEnum {
    default = 'default',
}

const cx = classNames.bind(styles);

type ValueT = TruckModelIdT | null | undefined;

type PropsT = {
    placeholder?: string;
    values: Array<ValueT>;
    isDisabled?: boolean;
    onChange: (values: Array<ValueT>) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
};

export type OptionT = {
    value: ValueT;
    label: string;
    iconType: TruckIconTypesEnum | null;
};

const TruckModelMultipleDropdown: React.FC<PropsT> = React.memo((props) => {
    const { values, onChange, isDisabled, hasWarning, hasError, hasChanges, onBlur, onFocus, hasClearControl } = props;

    const { t } = useTranslation();

    const trucksDict = useSelector(selectTrucksDict);
    const trucksDictList = Object.values(trucksDict || {});
    const fetchRequest = useSelector(selectTrucksDictRequest);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTrucksDict());
    }, []);

    const trucksOptions = React.useMemo(
        () =>
            trucksDictList.map((truck) => {
                return {
                    value: truck.id,
                    iconType: null,
                    label: getTruckDictTypeLabel(truck),
                };
            }),
        [trucksDictList],
    );

    const renderOption = (option: OptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return (
            <div className={cx('option')} key={option.label}>
                {option.label}
            </div>
        );
    };

    const handleSelect = (values: Array<ValueT>): void => {
        onChange(values);
    };

    const handleReset = (): void => {
        onChange([]);
    };

    const getOptionValue = (option: OptionT): ValueT => option.value;

    const renderTrigger = (
        props: RenderTriggerPropsT,
        options: Array<OptionT>,
        placeholder: string | undefined,
    ): React.ReactNode => {
        if (!options?.length) {
            return (
                <DropdownBaseButtonTrigger
                    {...props}
                    isShowClearControl={false}
                    renderLeftIcon={(iconMeta) => {
                        return <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />;
                    }}
                >
                    {placeholder || ''}
                </DropdownBaseButtonTrigger>
            );
        }

        if (options.length === 1) {
            const firstOption = options?.[0];

            return (
                <DropdownBaseButtonTrigger
                    {...props}
                    renderLeftIcon={(iconMeta) => {
                        return <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />;
                    }}
                >
                    {firstOption?.label}
                </DropdownBaseButtonTrigger>
            );
        }

        return (
            <DropdownBaseButtonTrigger
                {...props}
                renderLeftIcon={(iconMeta) => {
                    return <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />;
                }}
            >
                <DropdownMultipleInputCountLabel count={options?.length} />
            </DropdownBaseButtonTrigger>
        );
    };

    const placeholder = props.placeholder || t('common:common-components.any-select-input.placeholder');

    return (
        <DropdownMultipleInput<OptionT, ValueT>
            selectedValues={values}
            placeholder={placeholder}
            options={trucksOptions}
            onSelect={handleSelect}
            isDisabled={isDisabled || fetchRequest.loading}
            isLoading={fetchRequest.loading}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            renderTrigger={renderTrigger}
            onReset={hasClearControl ? handleReset : undefined}
        />
    );
});

export default TruckModelMultipleDropdown;
