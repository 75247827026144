import copy from 'copy-to-clipboard';
import { logWarning } from 'common/utils/logger';

export const copyToClipboard = (text: string) => {
    try {
        copy(text);
    } catch (error) {
        console.error(error);
        logWarning('Failed to update clipboard');
    }
};
