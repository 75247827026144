import * as React from 'react';

import { AssetTypeEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import InfoIcon from 'common/icons/InfoIcon';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectGPSCheckData, selectGPSIsChecking, selectGPSStatus } from 'common/store/gps-tracking-status/selectors';
import { checkGPSStatusRequest, reset } from 'common/store/gps-tracking-status/actions';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';
import { MS_IN_DAY } from 'common/utils/time';

export type PropsT = {
    className?: string;
    plateNumber: string;
    assetType: AssetTypeEnum;
    companyId: CompanyIdT;
    isDedicated: boolean;
};

const GPS_EXPIRATION_MS = 7 * MS_IN_DAY;

const LastGPSPositionAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, plateNumber, assetType, companyId, isDedicated } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    React.useEffect(() => {
        dispatch(checkGPSStatusRequest({ companyId, assetType, plateNumber }));
    }, [companyId, assetType, plateNumber]);

    const infoIconNode = <InfoIcon fillColor={StyleGuideColorsEnum.white} />;
    const loaderIconNode = <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;

    let theme: AlertThemeEnum | null = null;
    let text: React.ReactNode = null;

    const isChecking = useSelector(selectGPSIsChecking);
    const gpsStatus = useSelector(selectGPSStatus);
    const gpsData = useSelector(selectGPSCheckData);

    const renderTime = (time: string | null): [AlertThemeEnum, React.ReactNode] => {
        if (!time) {
            return [AlertThemeEnum.orange, t('common:last-gps-position.no-latest-gps-position')];
        }

        const momentTime = moment(time);
        const formattedDate = momentTime.format('Do');
        const formattedMonth = momentTime.format('MMM');
        const formattedTime = momentTime.format('H:mm');

        const shouldShowOldWarning = momentTime.valueOf() + GPS_EXPIRATION_MS < Date.now();

        if (shouldShowOldWarning) {
            return [
                AlertThemeEnum.orange,
                t('common:last-gps-position.old-latest', {
                    date: formattedDate,
                    month: formattedMonth,
                    time: formattedTime,
                }),
            ];
        }

        if (assetType === AssetTypeEnum.truck) {
            return [
                AlertThemeEnum.gray,
                t('common:last-gps-position.truck.latest', {
                    date: formattedDate,
                    month: formattedMonth,
                    time: formattedTime,
                }),
            ];
        }

        if (assetType === AssetTypeEnum.trailer) {
            return [
                AlertThemeEnum.gray,
                t('common:last-gps-position.trailer.latest', {
                    date: formattedDate,
                    month: formattedMonth,
                    time: formattedTime,
                }),
            ];
        }

        return [AlertThemeEnum.gray, null];
    };

    if (gpsStatus === GPStatusEnum.unable) {
        if (isDedicated) {
            theme = AlertThemeEnum.tomatoRed;
            text = t('common:last-gps-position.error');
        } else {
            theme = AlertThemeEnum.orange;
            text = t('common:last-gps-position.no-latest-gps-position');
        }
    }

    if (gpsStatus === GPStatusEnum.enabled) {
        const lastUpdateDate = gpsData?.lastUpdateDate || null;
        [theme, text] = renderTime(lastUpdateDate);
    }

    if (isChecking && !gpsStatus) {
        theme = AlertThemeEnum.gray;
        text = t('common:last-gps-position.loading');
    }

    return (
        <Alert
            className={className}
            icon={isChecking ? loaderIconNode : infoIconNode}
            size={AlertSizeEnum.small}
            theme={theme || AlertThemeEnum.gray}
        >
            {text || ''}
        </Alert>
    );
});

export default LastGPSPositionAlert;
