import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { RequestStatusT } from 'common/utils/request-status';
import ActivateAssetForm from './ActivateAssetForm/ActivateAssetForm';
import { AssetDataT } from './models';
import { ApiActivateVehiclesRequestT } from 'common/utils/api/models';

export type ActivateAssetConfirmationDataT = {
    companyId: CompanyIdT;
    assets: Array<AssetDataT>;
};

type PropsT = {
    companyId: CompanyIdT;
    assetType: AssetTypeEnum;
    data: ActivateAssetConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: ActivateAssetConfirmationDataT, activateVehiclesRequest: ApiActivateVehiclesRequestT) => void;
    requestStatus: RequestStatusT;
};

const TEST_SELECTOR = 'activate-assets';

const T_MAP: Record<
    AssetTypeEnum,
    {
        oneAssetTitle: string;
        manyAssetsTitle: string;
        oneAssetMessage: string;
        manyAssetsMessage: string;
    }
> = {
    [AssetTypeEnum.truck]: {
        oneAssetTitle: 'common:trucks-page.active-confirmation.one-asset-title',
        manyAssetsTitle: 'common:trucks-page.active-confirmation.many-assets-title',
        oneAssetMessage: 'common:trucks-page.active-confirmation.one-asset-message',
        manyAssetsMessage: 'common:trucks-page.active-confirmation.many-assets-message',
    },
    [AssetTypeEnum.trailer]: {
        oneAssetTitle: 'common:trailers-page.active-confirmation.one-asset-title',
        manyAssetsTitle: 'common:trailers-page.active-confirmation.many-assets-title',
        oneAssetMessage: 'common:trailers-page.active-confirmation.one-asset-message',
        manyAssetsMessage: 'common:trailers-page.active-confirmation.many-assets-message',
    },
};

const ActivateAssetDialogModal: React.FC<PropsT> = React.memo((props) => {
    const { companyId, data, onClose, onCancel, requestStatus, onConfirm, assetType } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const { assets } = data;

    const tSet = T_MAP[assetType];

    let message = null;
    let title = null;
    if (assets.length === 1) {
        message = t(tSet.oneAssetMessage, {
            plateNumber: assets[0]?.plateNumber,
        });
        title = t(tSet.oneAssetTitle);
    } else {
        message = t(tSet.manyAssetsMessage, {
            count: assets.length,
        });
        title = t(tSet.manyAssetsTitle);
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.brandAccent} />}
                    title={title}
                    message={message}
                >
                    <ActivateAssetForm
                        companyId={companyId}
                        assets={assets}
                        onCancel={onCancel}
                        requestStatus={requestStatus}
                        onSubmit={(activateVehiclesRequest) => {
                            onConfirm(data, activateVehiclesRequest);
                        }}
                    />
                </ModalDialog>
            </ModalContent>
        </Modal>
    );
});

export default ActivateAssetDialogModal;
