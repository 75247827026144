import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './PillLabel.scss';

const cx = classNames.bind(styles);

export type PillLabelPropsT = {
    theme: PillLabelThemeEnum;
    isSymmetrical?: boolean;
    isRounded?: boolean;
    isNoWrap?: boolean;
    className?: string;
    testSelector?: string;
};

export enum PillLabelThemeEnum {
    charcoal = 'charcoal',
    slate = 'slate',
    grey = 'grey',
    light = 'light',
    brandAccent = 'brand-accent',
    brandDark = 'brand-dark',
    orange = 'orange',
    azul = 'azul',
    blazeOrange = 'blaze-orange',
    tomatoRed = 'tomato-red',
}

// confluence entity
export const colorCodingPillLabelTheme = {
    normal: PillLabelThemeEnum.slate,
    attention: PillLabelThemeEnum.orange,
    warning: PillLabelThemeEnum.tomatoRed,
    notImportant: PillLabelThemeEnum.grey,
    success: PillLabelThemeEnum.brandAccent,
    positive: PillLabelThemeEnum.brandAccent,
    focus: PillLabelThemeEnum.charcoal,
} as const;

const PillLabel: React.FC<PillLabelPropsT> = React.memo((props) => {
    const { children, theme, testSelector, className, isRounded, isSymmetrical, isNoWrap } = props;

    return (
        <div
            className={cs(
                cx('pill', {
                    [`pill--theme-${theme}`]: true,
                    [`pill--is-symmetrical`]: isSymmetrical,
                    [`pill--is-rounded`]: isRounded,
                    [`pill--is-no-wrap`]: isNoWrap,
                }),
                className,
            )}
            data-test-selector={`${testSelector}_pill`}
        >
            {children}
        </div>
    );
});

export default PillLabel;
