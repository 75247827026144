export const collectDebugInfo = (changes: Partial<typeof window.debugInfo>) => {
    if (!window.debugInfo) {
        window.debugInfo = {};
    }

    window.debugInfo = {
        ...window.debugInfo,
        ...changes,
    };
};
