import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_TRACK_BY_ORDER,
    FETCH_TRACK_BY_TRANSPORT_ORDERS,
    FetchTrackByOrderActionT,
    FetchTrackByTransportOrderActionT,
} from './types';
import { fetchTrackBegin, fetchTrackError, fetchTrackSuccess } from './actions';
import { selectAssetTrackRequest } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { CompanyTypeEnum } from 'common/constants';
import {
    getAssetsTrackHashByOrderId,
    getAssetsTrackHashByTransportOrderId,
    getAssetTrackHashByOrderId,
    getAssetTrackHashByTransportOrderId,
} from './utils';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import shipperTranziitApi from 'shipper/utils/api/shipper-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { TrackPointT } from 'common/store/asset-track/models';
import { parseDateString } from 'common/utils/time';
import isNumber from 'lodash/isNumber';

function getFetchTrackByOrderSaga(companyType: CompanyTypeEnum) {
    return function* fetchTrackByTourSaga(action: FetchTrackByOrderActionT): WrapGeneratorT<void> {
        const { orderId } = action;

        const orderHash = getAssetsTrackHashByOrderId(orderId);

        const requestStatus: ReReturnT<typeof selectAssetTrackRequest> = yield select(
            selectAssetTrackRequest(orderHash),
        );
        if (!checkNeedRequest(requestStatus)) {
            return;
        }

        yield put(fetchTrackBegin(orderHash));

        let result: ReturnApiT<
            | typeof shipperTranziitApi.fetchAssetTracksByOrder
            | typeof brokerTranziitApi.fetchAssetTracksByTransportOrder
        > | null = null;

        if (companyType === CompanyTypeEnum.shipper) {
            result = yield shipperTranziitApi.fetchAssetTracksByOrder(orderId);
        }

        if (!result) {
            return;
        }

        const [error, apiAssetsCoordinates] = result;

        if (apiAssetsCoordinates) {
            for (const apiAssetCoordinates of apiAssetsCoordinates) {
                const assetId = apiAssetCoordinates?.assetId || null;
                if (assetId) {
                    const hash = getAssetTrackHashByOrderId(orderId, assetId);

                    const tracks = apiAssetCoordinates?.coordinates?.reduce<Array<TrackPointT>>((acc, coordinate) => {
                        const timestamp = parseDateString(coordinate.receivedDate);
                        if (!isNumber(timestamp)) {
                            return acc;
                        }

                        acc.push({
                            lat: coordinate.latitude,
                            lng: coordinate.longitude,
                            timestamp,
                        });

                        return acc;
                    }, []);

                    yield put(fetchTrackSuccess(hash, tracks));
                }
            }
        }

        if (error) {
            yield put(fetchTrackError(orderHash, error));
        } else {
            yield put(fetchTrackSuccess(orderHash, null));
        }
    };
}

function getFetchTrackByTransportOrdersSaga(companyType: CompanyTypeEnum) {
    return function* fetchTrackByTourSaga(action: FetchTrackByTransportOrderActionT): WrapGeneratorT<void> {
        const { transportOrderIds } = action;

        for (const transportOrderId of transportOrderIds) {
            const transportOrderHash = getAssetsTrackHashByTransportOrderId(transportOrderId);

            const requestStatus: ReReturnT<typeof selectAssetTrackRequest> = yield select(
                selectAssetTrackRequest(transportOrderHash),
            );
            if (!checkNeedRequest(requestStatus)) {
                return;
            }

            yield put(fetchTrackBegin(transportOrderHash));

            let result: ReturnApiT<typeof carrierTranziitApi.fetchAssetTracksByTransportOrder> | null = null;

            if (companyType === CompanyTypeEnum.carrier) {
                result = yield carrierTranziitApi.fetchAssetTracksByTransportOrder(transportOrderId);
            }

            if (companyType === CompanyTypeEnum.broker) {
                result = yield brokerTranziitApi.fetchAssetTracksByTransportOrder(transportOrderId);
            }

            if (!result) {
                return;
            }

            const [error, apiAssetsCoordinates] = result;

            if (apiAssetsCoordinates) {
                for (const apiAssetCoordinates of apiAssetsCoordinates) {
                    const assetId = apiAssetCoordinates?.assetId || null;
                    if (assetId) {
                        const hash = getAssetTrackHashByTransportOrderId(transportOrderId, assetId);

                        const tracks = apiAssetCoordinates?.coordinates?.reduce<Array<TrackPointT>>(
                            (acc, coordinate) => {
                                const timestamp = parseDateString(coordinate.receivedDate);
                                if (!isNumber(timestamp)) {
                                    return acc;
                                }

                                acc.push({
                                    lat: coordinate.latitude,
                                    lng: coordinate.longitude,
                                    timestamp,
                                });

                                return acc;
                            },
                            [],
                        );

                        yield put(fetchTrackSuccess(hash, tracks));
                    }
                }
            }

            if (error) {
                yield put(fetchTrackError(transportOrderHash, error));
            } else {
                yield put(fetchTrackSuccess(transportOrderHash, null));
            }
        }
    };
}

function* assetTrackSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(FETCH_TRACK_BY_ORDER, getFetchTrackByOrderSaga(companyType));
    yield takeEvery(FETCH_TRACK_BY_TRANSPORT_ORDERS, getFetchTrackByTransportOrdersSaga(companyType));
}

export default assetTrackSaga;
