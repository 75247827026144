import {
    FETCH_TRACK_REQUEST_BEGIN,
    FETCH_TRACK_REQUEST_ERROR,
    FETCH_TRACK_REQUEST_SUCCESS,
    FetchTrackByOrderActionT,
    FetchTrackRequestBeginActionT,
    FetchTrackRequestErrorActionT,
    FetchTrackRequestSuccessActionT,
    FETCH_TRACK_BY_ORDER,
    FetchTrackByTransportOrderActionT,
    FETCH_TRACK_BY_TRANSPORT_ORDERS,
} from './types';
import { TrackPointT } from 'common/store/asset-track/models';

export const fetchTracksByOrder = (orderId: OrderIdT): FetchTrackByOrderActionT => ({
    type: FETCH_TRACK_BY_ORDER,
    orderId,
});

export const fetchTracksByTransportOrders = (
    transportOrderIds: Array<TransportOrderIdT>,
): FetchTrackByTransportOrderActionT => ({
    type: FETCH_TRACK_BY_TRANSPORT_ORDERS,
    transportOrderIds,
});

export const fetchTrackBegin = (hash: HashT): FetchTrackRequestBeginActionT => ({
    type: FETCH_TRACK_REQUEST_BEGIN,
    hash,
});

export const fetchTrackSuccess = (hash: HashT, points: Array<TrackPointT> | null): FetchTrackRequestSuccessActionT => ({
    type: FETCH_TRACK_REQUEST_SUCCESS,
    hash,
    points,
});

export const fetchTrackError = (hash: HashT, error: Error): FetchTrackRequestErrorActionT => ({
    type: FETCH_TRACK_REQUEST_ERROR,
    error,
    hash,
});
