import { TranziitApiRequestErrorSubTypeEnum } from './tranziit-api-errors';
import { ErrorParserT } from '../../errors/models';
import { ResponseDataMapT } from 'common/utils/api/tranziit/errors/error-response';

const parseErrors: Record<TranziitApiRequestErrorSubTypeEnum, ErrorParserT> = {
    [TranziitApiRequestErrorSubTypeEnum.accessDeniedToApi]: (error) => {
        return error?.response?.code === 'ACCESS_DENIED_TO_API';
    },
    [TranziitApiRequestErrorSubTypeEnum.accessDeniedToEntity]: (error) => {
        return error?.response?.code === 'ACCESS_DENIED_TO_ENTITY';
    },
    [TranziitApiRequestErrorSubTypeEnum.couldNotUnLockCompany]: (error) => {
        return /unlock company/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.canNotDowngradeLastAdminInTeam]: (error) => {
        return /Can not change role for last company admin/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.notFoundCompany]: (error) => {
        if (/\/company$/.test(error?.requestConfig.path || '') && error?.httpStatusCode === 404) {
            return true;
        }

        return false;
    },
    [TranziitApiRequestErrorSubTypeEnum.alreadyAssignedDrivers]: (error) => {
        if (
            /\/assign-drivers-to-tour/.test(error?.requestConfig.path || '') &&
            /^Tour with id (.*?) already has maximum drivers/.test(error.response?.message || '')
        ) {
            return true;
        }

        return false;
    },
    [TranziitApiRequestErrorSubTypeEnum.notFoundUser]: (error) => {
        if (/\/(contact|restore)/.test(error?.requestConfig.path || '')) {
            return false;
        }

        return /^User not found/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.canNotDeleteHimself]: (error) => {
        return /User (.*?) delete himself/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist]: (error) => {
        return error?.response?.code === 'PHONE_ALREADY_EXIST';
    },
    [TranziitApiRequestErrorSubTypeEnum.emailAlreadyExist]: (error) => {
        return /^Email (.*?) already exists$/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.notAuthorizedUser]: (error) => {
        // check 401
        return /^Email (.*?) already exists$/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.successorHasNotActiveStatus]: (error) => {
        return /^New user (.*?) is not in Active status$/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreationExpiredRFQ]: (error) => {
        return /^No order (.*?) in status DRAFT$/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_ExpiredPriceOffer]: (error) => {
        return (
            /^Price offer (.*?) has expired$/.test(error.response?.message || '') && error?.response?.code === 'EXPIRED'
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_AlreadyCompletedRFQ]: (error) => {
        return (
            /^Unable to complete RFQ (.*?) from COMPLETED$/.test(error.response?.message || '') &&
            error?.response?.code === 'WRONG_STATUS'
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.firebaseTooManyResendInviteRequests]: (error) => {
        return (
            (/user\/invite/.test(error?.requestConfig.path || '') ||
                /registration\/user\/invite/.test(error?.requestConfig.path || '')) &&
            error?.response?.code === 'FIREBASE_TOO_MANY_ATTEMPTS_TRY_LATER'
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.expiredContextPreviewReserve]: (error) => {
        return (
            /preview/.test(error?.requestConfig.path || '') &&
            /^Context has expired or never exists/.test(error.response?.message || '')
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.failedArchiveInTransitDriver]: (error) => {
        return (
            /archive/.test(error?.requestConfig.path || '') &&
            /deactivate driver who is in transit now or has future tours assigned/.test(error.response?.message || '')
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.assignmentSelfCostPrediction]: (error) => {
        return (
            /calculate-tour-costs-for-assets/.test(error?.requestConfig.path || '') &&
            error?.httpStatusCode === 400 &&
            !!error?.response?.reason
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.noRouteFound]: (error) => {
        return /^No route found for the given vehicle(.*?)$/.test(error.response?.message || '');
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_CapacityExcess]: (error) => {
        return (
            /api\/v2\/rfqs\/(.*?)\/status\/completed$/.test(error?.requestConfig.path || '') &&
            error?.requestConfig?.method === 'POST' &&
            error?.httpStatusCode === 400 &&
            (
                error?.response as ResponseDataMapT[TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_CapacityExcess]
            )?.faultCode === 'CAPACITY_EXCESS'
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_Overweight]: (error) => {
        return (
            /api\/v2\/rfqs\/(.*?)\/status\/completed$/.test(error?.requestConfig.path || '') &&
            error?.requestConfig?.method === 'POST' &&
            error?.httpStatusCode === 400 &&
            (
                error?.response as ResponseDataMapT[TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedCompleteRFQ_Overweight]
            )?.faultCode === 'OVERWEIGHT'
        );
    },

    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_ProhibitedCountry]: (error) => {
        return (
            /api\/v2\/order-reserves$/.test(error?.requestConfig.path || '') &&
            error?.requestConfig?.method === 'POST' &&
            (
                error?.response as ResponseDataMapT[TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_ProhibitedCountry]
            )?.code === 'OTHER' &&
            /is prohibited/.test(
                (
                    error?.response as ResponseDataMapT[TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_ProhibitedCountry]
                )?.message || '',
            )
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteNotFound]: (error) => {
        return (
            /api\/v2\/order-reserves$/.test(error?.requestConfig.path || '') &&
            error?.requestConfig?.method === 'POST' &&
            (
                error?.response as ResponseDataMapT[TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteNotFound]
            )?.code === 'ROUTE_NOT_FOUND'
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable]: (error) => {
        return (
            /api\/v2\/order-reserves$/.test(error?.requestConfig.path || '') &&
            error?.requestConfig?.method === 'POST' &&
            (
                error?.response as ResponseDataMapT[TranziitApiRequestErrorSubTypeEnum.orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable]
            )?.code === 'WAYPOINT_NOT_LINKABLE'
        );
    },
    [TranziitApiRequestErrorSubTypeEnum.dispatchOrderEdit_ExpirationContext]: (error) => {
        return (
            /order-edit/.test(error?.requestConfig?.path || '') &&
            /OrderEditContext with id .+ not found/.test(error?.response?.message || '')
        );
    },
} as const;

export default parseErrors;
