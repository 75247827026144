import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './FieldValue.scss';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import { UnitTypeEnum } from 'common/constants';
import UnitType from 'common/components/units/UnitType/UnitType';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    label?: React.ReactNode;
    value: React.ReactNode;
    unitType?: UnitTypeEnum;
    icon?: React.ReactNode;
    placeholder?: React.ReactNode;
    tooltipNode?: React.ReactNode;
    tooltipPosition?: TooltipPositionEnum;
    rightNode?: React.ReactNode;
    withInputLayout?: boolean;
    withoutErrorMessage?: boolean;
};

const SHOW_TOOLTIP_DELAY = 300;

export const EMPTY_VALUE_PLACEHOLDER = '-';

const checkIsEmptyValue = (value: PropsT['value']): boolean => {
    if (value || value === 0) {
        return false;
    }

    return true;
};

const FieldValue: React.FC<PropsT> = React.memo((props) => {
    const {
        withInputLayout,
        withoutErrorMessage,
        label,
        value,
        unitType,
        icon,
        placeholder,
        tooltipNode,
        tooltipPosition,
        rightNode,
        className,
    } = props;

    return (
        <div
            className={cs(
                cx('wrap', {
                    'wrap--withoutErrorMessage': !!withoutErrorMessage,
                }),
                className,
            )}
        >
            {label && (
                <div className={cx('field-label')}>
                    <span className={cx('text')}>{label}</span>
                    {tooltipNode && (
                        <Tooltip
                            className={cx('tooltip')}
                            position={tooltipPosition || TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={tooltipNode}
                            delay={SHOW_TOOLTIP_DELAY}
                        >
                            {(isShow) => <TooltipIconTrigger className={cx('tooltip__icon')} isShow={isShow} />}
                        </Tooltip>
                    )}
                </div>
            )}
            <div
                className={cx('field-value', {
                    'field-value--withInputLayout': !!withInputLayout,
                })}
            >
                {icon && <div className={cx('field-value__icon')}>{icon}</div>}
                <div
                    className={cx('field-value__label', {
                        'field-value__label--hasValue': !checkIsEmptyValue(value),
                    })}
                >
                    {checkIsEmptyValue(value) ? placeholder : value}
                    {unitType ? <UnitType className={cx('unit')} type={unitType} /> : null}
                </div>
                {rightNode && <div className={cx('field-value__right-node')}>{rightNode}</div>}
            </div>
        </div>
    );
});

export default FieldValue;
