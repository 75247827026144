import { SharableStateT } from 'common/store/models';
import { initialAssetTrackState } from './reducer';
import { AssetTrackItemStateT, AssetTrackStateT } from './types';

export const selectAssetTrackRequest =
    (hash?: HashT) =>
    (state: SharableStateT): AssetTrackItemStateT['requestStatus'] => {
        if (!hash) {
            return initialAssetTrackState.requestStatus;
        }

        return state.assetTrack[hash]?.requestStatus || initialAssetTrackState.requestStatus;
    };

export const selectAssetTrackPoints =
    (hash?: HashT) =>
    (state: SharableStateT): AssetTrackItemStateT['points'] => {
        if (!hash) {
            return initialAssetTrackState.points;
        }

        return state.assetTrack[hash]?.points || initialAssetTrackState.points;
    };

export const selectAssetTrackSliceState = (state: SharableStateT): AssetTrackStateT => {
    return state.assetTrack;
};
