import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TrailersTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import { ApiTrailerT } from 'common/store/trailers/models';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import TypeCell from './cell-renderers/TypeCell/TypeCell';
import PlateNumberCell from 'common/components/Table/cell-renderers/PlateNumberCell/PlateNumberCell';
import CheckboxCell from 'common/components/Table/cell-renderers/CheckboxCell/CheckboxCell';
import CheckboxHeader from 'common/components/Table/header-renderers/CheckboxHeader/CheckboxHeader';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import FlagCell from 'common/components/Table/cell-renderers/FlagCell/FlagCell';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import { TabEnum } from '../constants';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TelematicUpdateDateCell from 'common/components/Table/cell-renderers/TelematicUpdateDateCell/TelematicUpdateDateCell';

const cx = classNames.bind(styles);

type PropsT = {
    trailers: Array<ApiTrailerT>;
    className?: string;
    selectedTrailersSet: Set<ApiTrailerT>;
    onSelectTrailers: (selectedTrailerIds: Set<ApiTrailerT>) => void;
    onSelectRow: (event: React.MouseEvent, trailer: ApiTrailerT) => void;
    isLoading: boolean;
    allowedCheckboxes: boolean;
    onOpenUserDetails: (userId: UserIdT | null) => void;
    activeTab: TabEnum;
};

const TrailersTable: React.FC<PropsT> = React.memo((props) => {
    const {
        onOpenUserDetails,
        trailers,
        allowedCheckboxes,
        className,
        selectedTrailersSet,
        onSelectTrailers,
        isLoading,
        onSelectRow,
        activeTab,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<ApiTrailerT, void> | null> = [
        allowedCheckboxes
            ? {
                  renderHeader: () => (
                      <CheckboxHeader<ApiTrailerT>
                          selectedRowsSet={selectedTrailersSet}
                          rows={trailers}
                          onChange={onSelectTrailers}
                      />
                  ),
                  headerClassName: cx('table__header', 'table__header--checkbox'),
                  render: (trailer: ApiTrailerT) => (
                      <CheckboxCell<ApiTrailerT>
                          row={trailer}
                          selectedRowsSet={selectedTrailersSet}
                          onChange={onSelectTrailers}
                      />
                  ),
                  className: cx('table__column--checkbox'),
              }
            : null,
        {
            renderHeader: () => t('common:trailers-page.table.columns.plate-number'),
            headerClassName: cx('table__header'),
            render: (trailer: ApiTrailerT) => <PlateNumberCell plateNumber={trailer?.plateNumber} />,
            className: cx('table__column', 'table__column--plate-number'),
        },
        {
            renderHeader: () => t('common:trailers-page.table.columns.type'),
            headerClassName: cx('table__header', 'table__header--type'),
            render: (trailer: ApiTrailerT) => <TypeCell trailer={trailer} />,
            className: cx('table__column', 'table__column--type'),
        },
        {
            renderHeader: () => t('common:trailers-page.table.columns.added-date'),
            headerClassName: cx('table__header'),
            render: (trailer: ApiTrailerT) => (
                <EventCell
                    openUserDetails={onOpenUserDetails}
                    userFullName={trailer.createdBy?.fullName}
                    userId={trailer.createdBy?.id}
                    byBroker={trailer.addedByBroker}
                    date={trailer.createdDate}
                />
            ),
            className: cx('table__column', 'table__column--created'),
        },
    ];

    if (activeTab === TabEnum.active) {
        columns.push({
            renderHeader: () => t('common:trailers-page.table.columns.linked'),
            headerClassName: cx('table__header', 'table__header--linked'),
            render: (trailer: ApiTrailerT) => <FlagCell isShowFlag={!!trailer.linked} />,
            className: cx('table__column', 'table__column--any'),
        });

        columns.push({
            renderHeader: () => t('common:trailers-page.table.columns.dedicated'),
            headerClassName: cx('table__header', 'table__header--dedicated'),
            render: (trailer: ApiTrailerT) => <FlagCell isShowFlag={!!trailer.dedicated} />,
            className: cx('table__column', 'table__column--any'),
        });

        columns.push({
            renderHeader: () => (
                <span>
                    {t('common:trailers-page.table.columns.GPS')}
                    <Tooltip
                        position={TooltipPositionEnum.topCenter}
                        theme={TooltipThemeEnum.black}
                        isInline
                        tooltipNode={
                            <TooltipContent isCenter theme={TooltipContentThemeEnum.black} isNoWrap>
                                {t('common:trailers-page.table.tooltips.GPS')}
                            </TooltipContent>
                        }
                    >
                        {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                    </Tooltip>
                </span>
            ),
            headerClassName: cx('table__header', 'table__header--gps'),
            render: (trailer: ApiTrailerT) => (
                <TelematicUpdateDateCell
                    placeholder={t('common:trailers-page.GPS.empty-update-date')}
                    telematicUpdatedDate={trailer.telematicUpdatedDate}
                />
            ),
            className: cx('table__column', 'table__column--any'),
        });

        columns.push({
            renderHeader: () => t('common:trailers-page.table.columns.status'),
            headerClassName: cx('table__header'),
            render: (trailer: ApiTrailerT) => <StatusCell trailer={trailer} />,
            className: cx('table__column', 'table__column--status'),
        });
    }

    if (activeTab === TabEnum.archive) {
        columns.push({
            renderHeader: () => t('common:trailers-page.table.columns.archived'),
            headerClassName: cx('table__header'),
            render: (trailer: ApiTrailerT) => (
                <EventCell
                    openUserDetails={onOpenUserDetails}
                    userFullName={trailer.archivedBy?.fullName}
                    userId={trailer.archivedBy?.id}
                    byBroker={trailer.archivedByBroker}
                    date={trailer.archivedDate}
                />
            ),
            className: cx('table__column', 'table__column--any'),
        });
    }

    const getRowMods = (meta: TableRowMetaT) => {
        return {
            [TableRowModsEnum.isSelected]: meta.isSelected,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<ApiTrailerT> tableName="trailers" isLoading={isLoading} rows={trailers}>
                {(rows, isUsedPrevRows) => (
                    <Table<ApiTrailerT, void>
                        className={className}
                        columns={columns}
                        rows={rows}
                        selectedRowsSet={selectedTrailersSet}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default TrailersTable;
