import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Toast.scss';
import { MS_IN_SEC } from 'common/utils/time';

const cx = classNames.bind(styles);

type CloseCallbackT = () => void;

export type PropsT = {
    onClose: CloseCallbackT;
    testSelector?: string;
    className?: string;
    ttlMs?: number;
    children: (onClose: CloseCallbackT) => React.ReactNode;
};

const TTL_MS = 10 * MS_IN_SEC;

const ANIMATION_DURATION = 300;

type TimerT = {
    startTimer: number | null;
    timeoutId: NodeJS.Timeout | null;
    lost: number | null;
};

const Toast: React.FC<PropsT> = React.memo((props) => {
    const { testSelector, className, onClose, children } = props;

    // NB: static ttl, not change on render
    const ttlMs = props.ttlMs || TTL_MS;

    const [isShow, setShow] = React.useState<boolean>(false);
    const handleClose = (): void => {
        setShow(false);

        setTimeout(() => {
            onClose();
        }, ANIMATION_DURATION);
    };

    const closeTimerRef = React.useRef<TimerT>({
        startTimer: null,
        timeoutId: null,
        lost: null,
    });

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleClose();
        }, ttlMs);

        closeTimerRef.current = {
            startTimer: Date.now(),
            timeoutId,
            lost: ttlMs,
        };

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            closeTimerRef.current = {
                startTimer: null,
                timeoutId: null,
                lost: null,
            };
        };
    }, []);

    const handleMouseLeave = () => {
        const lost = closeTimerRef.current.lost || ttlMs;

        const timeoutId = setTimeout(() => {
            handleClose();
        }, lost);

        closeTimerRef.current = {
            timeoutId,
            startTimer: Date.now(),
            lost,
        };
    };

    const handleMouseMove = (): void => {
        const closeTimer = closeTimerRef.current;
        if (!closeTimer.timeoutId || !closeTimer.startTimer || !closeTimer.lost) {
            return;
        }

        clearTimeout(closeTimer.timeoutId);

        const lost = closeTimer.lost - (Date.now() - closeTimer.startTimer);
        const lostLimit = ttlMs / 2;

        closeTimerRef.current = {
            timeoutId: null,
            startTimer: null,
            lost: Math.max(lost, lostLimit),
        };
    };

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 0);
    }, []);

    return (
        <div
            className={cs(
                cx('toast', {
                    'toast--is-show': isShow,
                }),
                className,
            )}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            data-test-selector={testSelector}
        >
            {children(handleClose)}
        </div>
    );
});

export default Toast;
