import React from 'react';
import classNames from 'classnames/bind';

import styles from './TrailerFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { createJsonParams } from 'common/utils/query';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { TrailerFiltersSidebarDataT } from './models';
import { useQueryParam } from 'use-query-params';
import { QueryFiltersT, QueryKeysEnum } from 'common/layouts/TrailersPageLayout/query-models';
import { CarrierContractStatusEnum, TrailerStatusEnum } from 'common/utils/api/models';
import TrailerStatusMultipleDropdown from 'common/components/dropdowns/TrailerStatusMultipleDropdown/TrailerStatusMultipleDropdown';
import CountryMultipleDropdown from 'common/components/dropdowns/CountryMultipleDropdown/CountryMultipleDropdown';
import DictTrailerMultipleDropdown from 'common/components/dropdowns/DictTrailerMultipleDropdown/DictTrailerMultipleDropdown';
import UserMultipleDropdown from 'common/components/dropdowns/UserMultipleDropdown/UserMultipleDropdown';
import ContractMultipleDropdown from 'common/components/dropdowns/ContractMultipleDropdown/ContractMultipleDropdown';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<TrailerFiltersSidebarDataT, CommonSidebarDataT>;

const TRAILER_EXCLUDE_STATUSES: Array<TrailerStatusEnum> = [TrailerStatusEnum.archived];

const EXCLUDED_STATUSES_SET = new Set([
    CarrierContractStatusEnum.waitingForApprove,
    CarrierContractStatusEnum.rejected,
]);

const TrailerFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.trailersFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);
    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);
            setQueryFilters(queryFilters);

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:trailers-page.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--type')}
                        name={FieldsEnum.dictTrailerTypeId}
                        error={formik.errors[FieldsEnum.dictTrailerTypeId]}
                        meta={formik.getFieldMeta(FieldsEnum.dictTrailerTypeId)}
                        label={t('common:trailers-page.filters.fields.trailer-type.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DictTrailerMultipleDropdown
                                values={formik.values[FieldsEnum.dictTrailerTypeId]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                                isShortLabel
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--status')}
                        name={FieldsEnum.status}
                        error={formik.errors[FieldsEnum.status]}
                        meta={formik.getFieldMeta(FieldsEnum.status)}
                        label={t('common:trailers-page.filters.fields.status.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <TrailerStatusMultipleDropdown
                                excludeStatuses={TRAILER_EXCLUDE_STATUSES}
                                value={formik.values[FieldsEnum.status]}
                                onChange={props.onChange}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--plate-number')}
                        name={FieldsEnum.plateNumber}
                        label={t('common:trailers-page.filters.fields.plate-number.label')}
                        error={formik.errors[FieldsEnum.plateNumber]}
                        meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.plateNumber}
                                value={formik.values[FieldsEnum.plateNumber]}
                                placeholder={t('common:trailers-page.filters.fields.plate-number.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field--country')}
                        name={FieldsEnum.country}
                        error={formik.errors[FieldsEnum.country]}
                        meta={formik.getFieldMeta(FieldsEnum.country)}
                        label={t('common:trailers-page.filters.fields.country.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountryMultipleDropdown
                                values={formik.values[FieldsEnum.country]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.lastTelematicUpdateDates}
                        error={formik.errors[FieldsEnum.lastTelematicUpdateDates]}
                        meta={formik.getFieldMeta(FieldsEnum.lastTelematicUpdateDates)}
                        label={t('common:trailers-page.filters.fields.telematic-update-dates.label')}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                {t('common:trailers-page.filters.fields.telematic-update-dates.tooltip')}
                            </TooltipContent>
                        }
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.lastTelematicUpdateDates]}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--contract')}
                        name={FieldsEnum.contractId}
                        error={formik.errors[FieldsEnum.contractId]}
                        meta={formik.getFieldMeta(FieldsEnum.contractId)}
                        label={t('common:trailers-page.filters.fields.contract.label')}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                {t('common:trailers-page.filters.fields.contract.tooltip')}
                            </TooltipContent>
                        }
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <ContractMultipleDropdown
                                hasNoneOption
                                values={formik.values[FieldsEnum.contractId]}
                                overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                onChange={(values, labels) => {
                                    props.onChange(values);
                                    formik.setFieldValue(FieldsEnum.contractName, labels);
                                }}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                companyId={partnerId}
                                hasClearControl
                                excludedStatusesSet={EXCLUDED_STATUSES_SET}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('common:trailers-page.filters.fields.creation-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--user')}
                        name={FieldsEnum.createdByUserId}
                        error={formik.errors[FieldsEnum.createdByUserId]}
                        meta={formik.getFieldMeta(FieldsEnum.createdByUserId)}
                        label={t('common:trailers-page.filters.fields.creation-user.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <UserMultipleDropdown
                                companyId={partnerId}
                                initialValues={formik.values[FieldsEnum.createdByUserId]}
                                initialLabels={formik.values[FieldsEnum.createdByUserName]}
                                values={formik.values[FieldsEnum.createdByUserId]}
                                onSelect={(userId, userName) => {
                                    props.onChange(userId);
                                    formik.setFieldValue(FieldsEnum.createdByUserName, userName);
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                                isShowAllBrokerUsersOption
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.lastModifyDates}
                        error={formik.errors[FieldsEnum.lastModifyDates]}
                        meta={formik.getFieldMeta(FieldsEnum.lastModifyDates)}
                        label={t('common:trailers-page.filters.fields.last-modify-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.lastModifyDates]}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--user')}
                        name={FieldsEnum.lastModifyByUserId}
                        error={formik.errors[FieldsEnum.lastModifyByUserId]}
                        meta={formik.getFieldMeta(FieldsEnum.lastModifyByUserId)}
                        label={t('common:trailers-page.filters.fields.last-modify-user.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <UserMultipleDropdown
                                companyId={partnerId}
                                initialValues={formik.values[FieldsEnum.lastModifyByUserId]}
                                initialLabels={formik.values[FieldsEnum.lastModifyByUserName]}
                                values={formik.values[FieldsEnum.lastModifyByUserId]}
                                onSelect={(userId, userName) => {
                                    props.onChange(userId);
                                    formik.setFieldValue(FieldsEnum.lastModifyByUserName, userName);
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                                isShowAllBrokerUsersOption
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:trailers-page.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default TrailerFiltersSidebarContent;
