import * as React from 'react';
import { ApiTrailerStatusT, TrailerStatusEnum } from 'common/utils/api/models';
import { PropsT as ColoredStatusLabelPropsT } from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import TrailerStatus from 'common/components/status/TrailerStatus/TrailerStatus';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

type PropsT = Omit<ColoredStatusLabelPropsT, 'label' | 'color'> & {
    status: ApiTrailerStatusT | null | undefined;
    isSymmetrical?: boolean;
};

export const PILL_THEME_BY_TRAILER_STATUS: Record<ApiTrailerStatusT, PillLabelThemeEnum> = {
    [TrailerStatusEnum.free]: PillLabelThemeEnum.brandAccent,
    [TrailerStatusEnum.inTransit]: PillLabelThemeEnum.slate,
    [TrailerStatusEnum.unavailable]: PillLabelThemeEnum.orange,
    [TrailerStatusEnum.archived]: PillLabelThemeEnum.tomatoRed,
};

const TrailerStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, ...restProps } = props;

    if (!status) {
        return null;
    }

    return (
        <PillLabel isNoWrap {...restProps} theme={PILL_THEME_BY_TRAILER_STATUS[status]}>
            <TrailerStatus status={status} />
        </PillLabel>
    );
});

export default TrailerStatusPill;
