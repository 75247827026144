import React from 'react';
import classNames from 'classnames/bind';

import styles from './TableActions.scss';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ArrowsIcon from 'common/icons/ArrowsIcon';

const cx = classNames.bind(styles);

export type TableActionOptionT = DropdownControlOptionT;

type PropsT = {
    className?: string;
    options: Array<TableActionOptionT | SpecialOptionEnum | null>;
    trigger: string;
    isDisabled: boolean;
    overlayClassName?: string;
    testSelector?: string;
};

const TableActions: React.FC<PropsT> = (props) => {
    const { className, options, trigger, isDisabled, testSelector, overlayClassName } = props;

    const fullTestSelector = `${testSelector}_table-actions`;
    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    label={trigger}
                    rightIcon={<ArrowsIcon />}
                    testSelector={fullTestSelector}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            isDisabled={isDisabled}
            overlayClassName={overlayClassName}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
};

export default TableActions;
