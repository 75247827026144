import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TrailerDetailsPage.scss';
import { useTranslation } from 'react-i18next';
import DetailsLayout from 'common/layouts/DetailsLayout/DetailsLayout';
import AssetDetailsMap from 'common/components/AssetDetailsMap/AssetDetailsMap';
import { AssetTypeEnum, QueryKeysEnum } from 'common/constants';
import useQuery from 'common/utils/hooks/useQuery';
import { fetchVehicleSchedule } from 'common/store/vehicle-schedules/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchScheduleRequest, selectVehicleSchedule } from 'common/store/vehicle-schedules/selectors';
import { useParams } from 'react-router-dom';
import { formatQuery } from 'common/utils/query';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { selectTrailersDetailsByIds } from 'common/store/trailers/selectors';
import isNumber from 'lodash/isNumber';
import AssetScheduleSidebar from 'common/components/AssetScheduleSidebar/AssetScheduleSidebar';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import CommonEditableTrailerDetailsLayout from 'common/layouts/CommonEditableTrailerDetailsLayout/CommonEditableTrailerDetailsLayout';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { useStateSafeHistory } from 'common/utils/hooks/useStateSafeHistory';
import AssetServiceLocationContext from 'common/components/AssetSidebarContent/contexts/asset-service-location-context';
import { useAssetServiceLocationContextValue } from 'common/components/AssetSidebarContent/hooks/useAssetServiceLocationContextValue';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import GoogleMapContext from 'common/contexts/google-map-context';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { SyncSetUnavailableFormMapStateContext } from 'common/components/AssetSidebarContent/SetUnavailableForm/contexts/sync-map-state';
import { usySyncMapState } from 'common/components/AssetSidebarContent/SetUnavailableForm/hooks/usySyncMapState';

const cx = classNames.bind(styles);

type PropsT = {
    onCloseMap: () => void;
    onClose: () => void;
};

type QueryT = {
    [QueryKeysEnum.trailerOrderId]?: string;
};

const TrailerDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const { onCloseMap, onClose } = props;

    const assetServiceLocationContextValue = useAssetServiceLocationContextValue();

    const openLeftSidebar = useOpenLeftSidebar();

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();

    const params = useParams<{ trailerId: string; partnerId: string }>();
    const { trailerId } = params;

    const detailsById = useSelector(selectTrailersDetailsByIds(partnerId));
    const trailerDetails = detailsById[trailerId as string];

    const fetchScheduleRequestStatus = useSelector(selectFetchScheduleRequest(trailerId));
    const schedule = useSelector(selectVehicleSchedule(trailerId));

    const dispatch = useDispatch();

    const query = useQuery<QueryT>();

    const { stateSafeHistory } = useStateSafeHistory();

    const handleOrderClick = (orderId: string) => {
        stateSafeHistory.replace({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.trailerOrderId]: orderId,
            }),
        });
    };

    React.useEffect(() => {
        dispatch(fetchVehicleSchedule(trailerId));
    }, [trailerId, partnerId]);

    const currentPosition = React.useMemo((): GeoPointT | null => {
        if (!trailerDetails) {
            return null;
        }

        const { latitude, longitude } = trailerDetails;
        if (!isNumber(latitude) || !isNumber(longitude)) {
            return null;
        }

        return {
            lat: latitude,
            lng: longitude,
        };
    }, [trailerDetails]);

    const handleCloseMap = () => {
        onCloseMap();
    };

    const handleOpenTruckDetails = (truckId: TruckIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.truckDetails,
            partnerId,
            truckId,
        });
    };

    const handleOpenContactDetails = (contractId: CarrierContractIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.carrierContract,
            contractId,
            initIsEditing: false,
        });
    };

    const handleOpenTrailerDetails = (trailerId: TrailerIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.trailerDetails,
            partnerId,
            trailerId,
        });
    };

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const handleOpenUserDetails = (userId: UserIdT, isBrokerUser?: boolean) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType: isBrokerUser ? PartnerTypeEnum.broker : PartnerTypeEnum.carrier,
            userId,
        });
    };

    const syncSetUnavailableFormMapStateContextValue = usySyncMapState();

    return (
        <GoogleMapContext.Provider value={googleMapsContextValue}>
            <SyncSetUnavailableFormMapStateContext.Provider value={syncSetUnavailableFormMapStateContextValue}>
                <AssetServiceLocationContext.Provider value={assetServiceLocationContextValue}>
                    <PageTitle
                        title={t('page-titles.trailer-details', {
                            plateNumber: trailerDetails?.plateNumber,
                        })}
                    />
                    <DetailsLayout
                        className={cx('wrap')}
                        sideBarTitle={t('common:order-details.main-tabs.details')}
                        sideBarNode={
                            <CommonEditableTrailerDetailsLayout
                                partnerId={partnerId}
                                trailerId={trailerId}
                                onClose={onClose}
                                isShowMap
                                onToggleShowMap={handleCloseMap}
                                onOpenTrailerDetails={handleOpenTrailerDetails}
                                onOpenTruckDetails={handleOpenTruckDetails}
                                onOpenContactDetails={handleOpenContactDetails}
                                onOpenUserDetails={handleOpenUserDetails}
                            />
                        }
                        mapTitle={t('common:trailer-page.tabs.map')}
                        mapNode={
                            <AssetDetailsMap
                                type={AssetTypeEnum.trailer}
                                currentPosition={currentPosition}
                                telematicUpdatedDate={trailerDetails?.telematicUpdatedDate}
                                isLoading={fetchScheduleRequestStatus.loading || !trailerDetails}
                            />
                        }
                        historyTitle={t('common:trailer-page.tabs.activity')}
                        historyNode={null}
                        timelineTitle={t('common:timeline')}
                        timelineNode={(close) => (
                            <AssetScheduleSidebar
                                type="trailer"
                                isLoading={fetchScheduleRequestStatus.loading}
                                onClose={close}
                                onEventClick={handleOrderClick}
                                selectedOrderId={query[QueryKeysEnum.trailerOrderId]}
                                schedule={schedule}
                            />
                        )}
                    />
                </AssetServiceLocationContext.Provider>
            </SyncSetUnavailableFormMapStateContext.Provider>
        </GoogleMapContext.Provider>
    );
});

export default TrailerDetailsPage;
