import React from 'react';
import classNames from 'classnames/bind';

import styles from './DriversAssignmentForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { assign, resetDriverAssignment } from 'common/store/driver-assigment/actions';
import { logWarning } from 'common/utils/logger';
import { selectAssignmentRequestStatus } from 'common/store/driver-assigment/selectors';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { isNonNil } from 'common/utils';
import DriverSuggestInput from 'common/layouts/TransportOrderDetailsPageLayout/DriversAssignmentForm/DriverSuggestInput/DriverSuggestInput';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { RequestStatusT } from 'common/utils/request-status';
import { getDriverLabel } from 'common/layouts/TransportOrderDetailsPageLayout/DriversAssignmentForm/DriverSuggestInput/utils';

const cx = classNames.bind(styles);

type PropsT = {
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onCancel: () => void;
    isAllowUnassignDriver: boolean;
    unnassignDriversRequestStatus: RequestStatusT;
    onUnnasignDrivers: () => void;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.driverId]: null,
    [FieldsEnum.teamDriverId]: null,
};

const DriversAssignmentForm: React.FC<PropsT> = (props) => {
    const {
        onCancel,
        transportOrderId,
        transportOrderDetails,
        isAllowUnassignDriver,
        onUnnasignDrivers,
        unnassignDriversRequestStatus,
    } = props;

    const driver = transportOrderDetails?.drivers?.[0] || null;
    const driverId = driver?.id || null;
    const teamDriver = transportOrderDetails?.drivers?.[1] || null;
    const teamDriverId = teamDriver?.id || null;
    const isTeamDrive = !!transportOrderDetails?.tourInfo?.teamDrive;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(resetDriverAssignment());
        };
    }, []);

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, isTeamDrive);
    }, [t, isTeamDrive]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
            [FieldsEnum.driverId]: driverId || null,
            [FieldsEnum.teamDriverId]: teamDriverId || null,
        };

        const errors = validateForm(t, values, isTeamDrive);

        return [values, errors];
    }, [driverId, teamDriverId]);

    const hasInitialDrivers = driverId || teamDriverId;

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const driverIds: DriverIdT[] = [
                values[FieldsEnum.driverId],
                isTeamDrive ? values[FieldsEnum.teamDriverId] : null,
            ].filter(isNonNil);

            if (!driverIds.length) {
                logWarning('empty driverIds!');
                return;
            }

            dispatch(assign(transportOrderId, driverIds));

            formikHelpers.setTouched({});
        },
    });

    const handleClearDrivers = () => {
        onUnnasignDrivers();
    };

    const isLoading = unnassignDriversRequestStatus.loading || assignmentRequestStatus.loading;

    return (
        <div className={cx('wrap')}>
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('title')}>{t('common:driver-assignment.title')}</div>
                <div className={cx('form')}>
                    <FormikField
                        name={FieldsEnum.driverId}
                        error={formik.errors[FieldsEnum.driverId]}
                        meta={formik.getFieldMeta(FieldsEnum.driverId)}
                        label={t('common:driver-assignment.fields.driver.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DriverSuggestInput
                                value={formik.values[FieldsEnum.driverId]}
                                initialLabel={getDriverLabel(driver)}
                                initialValue={driver?.id}
                                transportOrderId={transportOrderId}
                                onSelect={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                inputPlaceholder={t('common:driver-assignment.fields.driver.input-placeholder')}
                                placeholder={t('common:driver-assignment.fields.driver.placeholder')}
                            />
                        )}
                    </FormikField>
                    {isTeamDrive && (
                        <FormikField
                            name={FieldsEnum.teamDriverId}
                            error={formik.errors[FieldsEnum.teamDriverId]}
                            meta={formik.getFieldMeta(FieldsEnum.teamDriverId)}
                            label={t('common:driver-assignment.fields.team-driver.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DriverSuggestInput
                                    value={formik.values[FieldsEnum.teamDriverId]}
                                    initialLabel={getDriverLabel(teamDriver)}
                                    initialValue={teamDriver?.id}
                                    transportOrderId={transportOrderId}
                                    onSelect={props.onChange}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                    inputPlaceholder={t(
                                        'common:driver-assignment.fields.team-driver.input-placeholder',
                                    )}
                                    placeholder={t('common:driver-assignment.fields.team-driver.placeholder')}
                                />
                            )}
                        </FormikField>
                    )}
                </div>
                {hasInitialDrivers ? (
                    <div className={cx('actions')}>
                        {isAllowUnassignDriver && (
                            <Button
                                theme={ButtonThemeEnum.secondaryDanger}
                                className={cx('action', 'action--clear-assets')}
                                onClick={handleClearDrivers}
                                isLoading={assignmentRequestStatus.loading}
                                isDisabled={assignmentRequestStatus.loading}
                            >
                                {t('common:driver-assignment.actions.clear-drivers', {
                                    postProcess: 'interval',
                                    count: transportOrderDetails?.drivers?.length || 0,
                                })}
                            </Button>
                        )}
                        <div className={cx('sub-actions')}>
                            <Button
                                theme={ButtonThemeEnum.secondary}
                                className={cx('action', 'action--reassign-cancel')}
                                onClick={onCancel}
                            >
                                {t('common:driver-assignment.actions.cancel')}
                            </Button>
                            <Button
                                theme={ButtonThemeEnum.primary}
                                className={cx('action', 'action--reassign')}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                                type="submit"
                            >
                                {t('common:driver-assignment.actions.reassign')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('action', 'action--assign-cancel')}
                            onClick={onCancel}
                        >
                            {t('common:driver-assignment.actions.cancel')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            className={cx('action', 'action--assign')}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                            type="submit"
                        >
                            {t('common:driver-assignment.actions.assign')}
                        </Button>
                    </div>
                )}
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            </form>
        </div>
    );
};

export default DriversAssignmentForm;
