import {
    ACTIVATION_TRAILERS_REQUEST,
    ActivationTrailersRequestActionT,
    ADD_TRAILER_REQUEST,
    ADD_TRAILER_REQUEST_BEGIN,
    ADD_TRAILER_REQUEST_ERROR,
    ADD_TRAILER_REQUEST_SUCCESS,
    AddTrailerRequestActionT,
    AddTrailerRequestBeginActionT,
    AddTrailerRequestErrorActionT,
    AddTrailerRequestSuccessActionT,
    ARCHIVE_TRAILERS_REQUEST,
    ArchiveTrailersRequestActionT,
    ASSIGN_TO_CONTRACT_TRAILERS_REQUEST,
    AssignToContractRequestActionT,
    CHANGE_RATE_TRAILERS_REQUEST,
    DELETE_TRAILERS_REQUEST,
    DELETE_TRAILERS_REQUEST_BEGIN,
    DELETE_TRAILERS_REQUEST_ERROR,
    DELETE_TRAILERS_REQUEST_SUCCESS,
    DeleteTrailersRequestActionT,
    DeleteTrailersRequestBeginActionT,
    DeleteTrailersRequestErrorActionT,
    DeleteTrailersRequestSuccessActionT,
    EXPORT_XLS_TRAILERS_REQUEST,
    EXPORT_XLS_TRAILERS_REQUEST_BEGIN,
    EXPORT_XLS_TRAILERS_REQUEST_ERROR,
    EXPORT_XLS_TRAILERS_REQUEST_SUCCESS,
    ExportXLSTrailersActionT,
    ExportXLSTrailersBeginActionT,
    ExportXLSTrailersErrorActionT,
    ExportXLSTrailersSuccessActionT,
    FETCH_TRAILER_DETAILS_REQUEST,
    FETCH_TRAILER_DETAILS_REQUEST_BEGIN,
    FETCH_TRAILER_DETAILS_REQUEST_ERROR,
    FETCH_TRAILER_DETAILS_REQUEST_SUCCESS,
    FETCH_TRAILERS_PAGE_REQUEST,
    FETCH_TRAILERS_PAGE_REQUEST_BEGIN,
    FETCH_TRAILERS_PAGE_REQUEST_ERROR,
    FETCH_TRAILERS_PAGE_REQUEST_SUCCESS,
    FETCH_TRAILERS_STATS_REQUEST,
    FETCH_TRAILERS_STATS_REQUEST_BEGIN,
    FETCH_TRAILERS_STATS_REQUEST_ERROR,
    FETCH_TRAILERS_STATS_REQUEST_SUCCESS,
    FetchTrailerDetailsActionT,
    FetchTrailerDetailsBeginActionT,
    FetchTrailerDetailsErrorActionT,
    FetchTrailerDetailsSuccessActionT,
    FetchTrailersPageActionT,
    FetchTrailersPageBeginActionT,
    FetchTrailersPageErrorActionT,
    FetchTrailersPageSuccessActionT,
    FetchTrailersStatsActionT,
    FetchTrailersStatsBeginActionT,
    FetchTrailersStatsErrorActionT,
    FetchTrailersStatsSuccessActionT,
    IMPORT_XLS_TRAILERS_REQUEST,
    IMPORT_XLS_TRAILERS_REQUEST_BEGIN,
    IMPORT_XLS_TRAILERS_REQUEST_ERROR,
    IMPORT_XLS_TRAILERS_REQUEST_SUCCESS,
    ImportXLSTrailersActionT,
    ImportXLSTrailersBeginActionT,
    ImportXLSTrailersErrorActionT,
    ImportXLSTrailersSuccessActionT,
    RESET_TRAILERS,
    ResetTrailersActionT,
    UPDATE_TRAILERS_REQUEST,
    UPDATE_TRAILERS_REQUEST_BEGIN,
    UPDATE_TRAILERS_REQUEST_ERROR,
    UPDATE_TRAILERS_REQUEST_SUCCESS,
    UpdateTrailerActionT,
    UpdateTrailersRequestBeginActionT,
    UpdateTrailersRequestErrorActionT,
    UpdateTrailersRequestSuccessActionT,
    ChangeRateTrailersRequestActionT,
} from './types';
import {
    ActivateTrailersRequestT,
    ApiTrailerDetailsT,
    ApiTrailerT,
    CompanyTrailersStatsT,
    FetchTrailersPageQueryT,
} from './models';
import {
    ApiChangeContractVehiclesRequestT,
    ApiChangeRateVehiclesRequestT,
    ApiTrailerAddRequestT,
    ApiTrailerUpdateRequestT,
} from 'common/utils/api/models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchTrailersPage = (
    pageNumber: PageNumberT,
    query: FetchTrailersPageQueryT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTrailersPageActionT => {
    return {
        type: FETCH_TRAILERS_PAGE_REQUEST,
        query,
        pageNumber,
        companyId,
        options,
    };
};

export const fetchTrailersPageBegin = (
    query: FetchTrailersPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
): FetchTrailersPageBeginActionT => ({
    type: FETCH_TRAILERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
    companyId,
});

export const fetchTrailersPageSuccess = (
    query: FetchTrailersPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
    pageResponse: PageResponseT<ApiTrailerT>,
): FetchTrailersPageSuccessActionT => ({
    type: FETCH_TRAILERS_PAGE_REQUEST_SUCCESS,
    query,
    pageNumber,
    companyId,
    pageResponse,
});

export const fetchTrailersPageError = (
    query: FetchTrailersPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
    companyId: CompanyIdT,
): FetchTrailersPageErrorActionT => ({
    type: FETCH_TRAILERS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
    companyId,
});

export const resetTrailers = (
    companyId: CompanyIdT,
    mutationListOptions: MutationListOptionsT,
): ResetTrailersActionT => ({
    type: RESET_TRAILERS,
    companyId,
    mutationListOptions,
});

export const addTrailerRequest = (data: ApiTrailerAddRequestT, companyId: CompanyIdT): AddTrailerRequestActionT => ({
    type: ADD_TRAILER_REQUEST,
    data,
    companyId,
});

export const addTrailerRequestBegin = (companyId: CompanyIdT): AddTrailerRequestBeginActionT => ({
    type: ADD_TRAILER_REQUEST_BEGIN,
    companyId,
});

export const addTrailerRequestError = (error: Error, companyId: CompanyIdT): AddTrailerRequestErrorActionT => ({
    type: ADD_TRAILER_REQUEST_ERROR,
    error,
    companyId,
});

export const addTrailerRequestSuccess = (companyId: CompanyIdT): AddTrailerRequestSuccessActionT => ({
    type: ADD_TRAILER_REQUEST_SUCCESS,
    companyId,
});

export const deleteTrailersRequest = (
    trailerIds: TrailerIdT[],
    companyId: CompanyIdT,
): DeleteTrailersRequestActionT => ({
    type: DELETE_TRAILERS_REQUEST,
    trailerIds,
    companyId,
});

export const deleteTrailersRequestBegin = (companyId: CompanyIdT): DeleteTrailersRequestBeginActionT => ({
    type: DELETE_TRAILERS_REQUEST_BEGIN,
    companyId,
});

export const deleteTrailersRequestError = (error: Error, companyId: CompanyIdT): DeleteTrailersRequestErrorActionT => ({
    type: DELETE_TRAILERS_REQUEST_ERROR,
    error,
    companyId,
});

export const deleteTrailersRequestSuccess = (companyId: CompanyIdT): DeleteTrailersRequestSuccessActionT => ({
    type: DELETE_TRAILERS_REQUEST_SUCCESS,
    companyId,
});

export const updateTrailer = (
    trailerId: TrailerIdT,
    updateTrailer: ApiTrailerUpdateRequestT,
    companyId: CompanyIdT,
): UpdateTrailerActionT => ({
    type: UPDATE_TRAILERS_REQUEST,
    trailerId,
    updateTrailer,
    companyId,
});

export const assignToContractRequest = ({
    contractName,
    changeContractRequest,
    companyId,
}: {
    contractName: string | null;
    changeContractRequest: ApiChangeContractVehiclesRequestT;
    companyId: CompanyIdT;
}): AssignToContractRequestActionT => ({
    type: ASSIGN_TO_CONTRACT_TRAILERS_REQUEST,
    contractName,
    changeContractRequest,
    companyId,
});

export const changeRateTrailersRequest = ({
    companyId,
    changeRateVehiclesRequest,
}: {
    companyId: CompanyIdT;
    changeRateVehiclesRequest: ApiChangeRateVehiclesRequestT;
}): ChangeRateTrailersRequestActionT => ({
    type: CHANGE_RATE_TRAILERS_REQUEST,
    changeRateVehiclesRequest,
    companyId,
});

export const activationTrailersRequest = (
    companyId: CompanyIdT,
    activateTrailersRequest: ActivateTrailersRequestT,
): ActivationTrailersRequestActionT => ({
    type: ACTIVATION_TRAILERS_REQUEST,
    activateTrailersRequest,
    companyId,
});

export const archiveTrailersRequest = (
    trailerIds: TrailerIdT[],
    companyId: CompanyIdT,
    reason: string,
): ArchiveTrailersRequestActionT => ({
    type: ARCHIVE_TRAILERS_REQUEST,
    trailerIds,
    companyId,
    reason,
});

export const updateTrailersRequestBegin = (companyId: CompanyIdT): UpdateTrailersRequestBeginActionT => ({
    type: UPDATE_TRAILERS_REQUEST_BEGIN,
    companyId,
});

export const updateTrailersRequestError = (error: Error, companyId: CompanyIdT): UpdateTrailersRequestErrorActionT => ({
    type: UPDATE_TRAILERS_REQUEST_ERROR,
    error,
    companyId,
});

export const updateTrailersRequestSuccess = (companyId: CompanyIdT): UpdateTrailersRequestSuccessActionT => ({
    type: UPDATE_TRAILERS_REQUEST_SUCCESS,
    companyId,
});

export const fetchTrailerDetails = (
    trailerId: TrailerIdT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTrailerDetailsActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST,
    trailerId,
    companyId,
    options,
});

export const fetchTrailerDetailsRequestBegin = (companyId: CompanyIdT): FetchTrailerDetailsBeginActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const fetchTrailerDetailsRequestError = (
    error: Error,
    companyId: CompanyIdT,
): FetchTrailerDetailsErrorActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchTrailerDetailsRequestSuccess = (
    trailerId: TrailerIdT,
    details: ApiTrailerDetailsT | null,
    companyId: CompanyIdT,
): FetchTrailerDetailsSuccessActionT => ({
    type: FETCH_TRAILER_DETAILS_REQUEST_SUCCESS,
    trailerId,
    details,
    companyId,
});

export const fetchTrailersStats = (companyId: CompanyIdT, options?: FetchOptionsT): FetchTrailersStatsActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST,
    companyId,
    options,
});

export const fetchTrailersStatsRequestBegin = (companyId: CompanyIdT): FetchTrailersStatsBeginActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST_BEGIN,
    companyId,
});

export const fetchTrailersStatsRequestError = (
    error: Error,
    companyId: CompanyIdT,
): FetchTrailersStatsErrorActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchTrailersStatsRequestSuccess = (
    stats: CompanyTrailersStatsT | null,
    companyId: CompanyIdT,
): FetchTrailersStatsSuccessActionT => ({
    type: FETCH_TRAILERS_STATS_REQUEST_SUCCESS,
    stats,
    companyId,
});

export const importXLSTrailers = (companyId: CompanyIdT, file: File): ImportXLSTrailersActionT => ({
    type: IMPORT_XLS_TRAILERS_REQUEST,
    companyId,
    file,
});

export const importXLSTrailersRequestBegin = (companyId: CompanyIdT): ImportXLSTrailersBeginActionT => ({
    type: IMPORT_XLS_TRAILERS_REQUEST_BEGIN,
    companyId,
});

export const importXLSTrailersRequestError = (error: Error, companyId: CompanyIdT): ImportXLSTrailersErrorActionT => ({
    type: IMPORT_XLS_TRAILERS_REQUEST_ERROR,
    error,
    companyId,
});

export const importXLSTrailersRequestSuccess = (companyId: CompanyIdT): ImportXLSTrailersSuccessActionT => ({
    type: IMPORT_XLS_TRAILERS_REQUEST_SUCCESS,
    companyId,
});

export const exportXLSTrailers = (companyId: CompanyIdT, query: FetchTrailersPageQueryT): ExportXLSTrailersActionT => ({
    type: EXPORT_XLS_TRAILERS_REQUEST,
    companyId,
    query,
});

export const exportXLSTrailersRequestBegin = (companyId: CompanyIdT): ExportXLSTrailersBeginActionT => ({
    type: EXPORT_XLS_TRAILERS_REQUEST_BEGIN,
    companyId,
});

export const exportXLSTrailersRequestError = (error: Error, companyId: CompanyIdT): ExportXLSTrailersErrorActionT => ({
    type: EXPORT_XLS_TRAILERS_REQUEST_ERROR,
    error,
    companyId,
});

export const exportXLSTrailersRequestSuccess = (companyId: CompanyIdT): ExportXLSTrailersSuccessActionT => ({
    type: EXPORT_XLS_TRAILERS_REQUEST_SUCCESS,
    companyId,
});
