export enum RateSourceEnum {
    current = '__current__',
    new = '__new__',
}

export const rateSourceSet = new Set<string>(Object.values(RateSourceEnum));

export enum FieldsEnum {
    source = 'source',
    rate = 'rate',
}

export type FormValuesT = {
    [FieldsEnum.source]: RateSourceEnum | CarrierContractIdT;
    [FieldsEnum.rate]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
